import React, {
    // useEffect, 
    useMemo, useState} from 'react';
import { Col, Form, Row } from 'antd';
import {Center, 
    // SubTitle, 
    Title
} from "src/components/CommonStyles";
import {debounce, isNull} from 'lodash'
import styled from "styled-components";
import {InputText} from "src/components/reusables/TextInputs";
import Button from "src/components/reusables/Button";
import Select from "src/components/reusables/Select";
import Prompt from "src/components/reusables/Prompt";
import { SelectDays } from "src/components/reusables/SelectDays";

import {ScheduleFormProps} from 'src/containers/on-boarding/constants'
import { timeSelections } from "src/constants/datetime"
import APIService from 'src/services/APIService'

import  {useOrg} from 'src/hooks/useOrgContext'



const AvailabilityForm = ({data, onCancel, onSave, uid}: {data?: any, onCancel: () => void, onSave: () => void, uid: number}) => {

    const [form] = Form.useForm();
    const { put, post, get } = APIService()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {availability, setAvailability} = useOrg()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showScheduleForm, setShowScheduleForm] = useState<boolean>(true);
    const [scheduleData, setScheduleData] = useState<any[]>([])
    const [successVisible, setSuccessVisible] = useState<boolean>(false)

    const initialSchedule = {
        id: null,
        name: '',
        start: '',
        end: '',
        days: [],
    }

    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')

    const [editSchedule, setEditSchedule] = useState<ScheduleFormProps>(initialSchedule)
    const compileSelectedDays = (schedules:any, edit:any) => {
        let selected_days: any = []
        schedules.map((sched:any) => {
            console.log('compileSelectedDays, edit: ', edit)
            console.log('compileSelectedDays, sched: ', sched)
            console.log('compileSelectedDays',edit.id, sched.id, edit.id !== sched.id)
            if (!isNull(sched.id) && edit.id !== sched.id) // don't include currently editing schedule days
                selected_days = [...selected_days, ...sched.days]

            return sched
        })

        console.log('already selected days: ', selected_days)
        return selected_days
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getUnavailableDays = useMemo(() => compileSelectedDays(scheduleData, editSchedule), [scheduleData, editSchedule])
    const onDaysChange = (value: any) => {
        setEditSchedule((editSchedule: any) => ({
            ...editSchedule,
            days: [...value]
        }))
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const checkTimeChange = (startTime:string, endTime:string, time:string) => {
        const start:any = startTime.split(":")
        const end:any = endTime.split(":")

        function getDateTime(time:any) {
            let dateTime = new Date()
            dateTime.setHours(time[0])
            dateTime.setMinutes(time[1])
            dateTime.setSeconds(time[2])

            return dateTime
        }

        let startDateTime = getDateTime(start).getTime()
        let endDateTime = getDateTime(end).getTime()


        switch(time) {
            case 'start':
                if (startDateTime > endDateTime) {
                    // TODO: add notification invalid time
                    return false
                }
                break

            case 'end':
                if (endDateTime < startDateTime) {
                    // TODO: add notification invalid time
                    return false
                }
                break

            default:
                if (startDateTime === endDateTime) {
                    // TODO: add notification invalid time
                    return false
                }
                break
        }

        return true
    }


    let props = { ...editSchedule }

    const emptyScheduleForm = () => {
        form.setFieldsValue(initialSchedule)
        setEditSchedule(initialSchedule)
    }

    const fetchSchedules = async () => {
        console.log('fetching...')
        //await get('schedules/my-schedules', {})
        await get('users/' + uid + '/schedules', [])
            .then((response: any) => {
                setShowScheduleForm(response?.length ? false : true)
                setAvailability(response)
                const sortedSchedule = response.map((item: any) => {
                    // sort item.workingDays by Su M T W Th F Sa
                    const sortedDays = item.workingDays.split(' ').sort((a: any, b: any) => {
                        const days = ['Su', 'M', 'T', 'W', 'Th', 'F', 'Sa']
                        return days.indexOf(a) - days.indexOf(b)
                    })
                    return {
                        ...item,
                        workingDays: sortedDays.join(' ')
                    }
                })
                console.log('my schedule', sortedSchedule)
                setScheduleData(sortedSchedule)
            })
    }



    const submitAvailability = debounce(async (values: any) => {
        console.log('submitAvailability: ',values, editSchedule)

        if (editSchedule.id) {
            await put('schedules/'+editSchedule.id, {
                ...values, days: [...editSchedule.days]
            }).then(resp => {
                emptyScheduleForm()
                fetchSchedules() // FIX: temporary will use splice to remove or update
                setSuccessVisible(true)
            }).catch(err => {
                // BUG: to remove this when value.toFormat is resolve in BE
                emptyScheduleForm()
                fetchSchedules()

                console.error(err)
            })
        }
        else {
            //await post('schedules', {
            await post('users/' + uid + '/add-schedule', {
                ...values, days: [...editSchedule.days]
            }).then(resp => {
                emptyScheduleForm()
                setScheduleData(scheduleData.concat(resp))
                setSuccessVisible(true)
                fetchSchedules()
                onSave()
            }).catch(err => console.error(err))
        }
        form.resetFields()
    }, 500)
    const [disableSave, setDisableSave] = useState(false)
    const ScheduleForm = () => {

        return (
            <Form
                form={form}
                name="create-schedule-form"
                style={{width: '90%', marginTop: 15}}
                layout={'vertical'}
                onFinish={(values) => {
                    setDisableSave(true)
                    submitAvailability(values)
                }}
            >
                <Row>
                    <InputLabel>Schedule Names</InputLabel>
                </Row>
                <Form.Item
                    name={'name'}
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                const existingNames = data.map((schedule: any) => schedule?.name?.toLowerCase().trim())
                                if(value){
                                    if(existingNames.length && existingNames.includes(value.toLowerCase().trim())){
                                        return Promise.reject(new Error('Schedule name already exist'));
                                    }else{
                                        return Promise.resolve();
                                    }
                                } else{
                                    return Promise.reject(new Error('Schedule Name is required'));
                                }
                            },
                        }),
                    ]}
                    style={{marginBottom: '20px', textAlign: 'left'}}
                >
                    <InputText value={props.name} name={'name'} autoComplete='off' placeholder="Schedule name"
                               style={{width: '50%'}} maxLength={24}/>
                </Form.Item>
                <Form.Item
                    rules={[
                        {required: true, message: 'Working days is required'},
                    ]}
                    style={{marginBottom: '30px'}}
                >
                    <Row>
                        <InputLabel>Select Working Days</InputLabel>
                    </Row>
                    <Form.Item noStyle>
                        <SelectDays
                            initialDays={props.days}
                            disabledDays={[]}
                            onChange={onDaysChange} />
                    </Form.Item>
                </Form.Item>
                <Row gutter={16}>
                    <Col xs={{span: 12}}>
                        <Form.Item
                            rules={[
                                {required: true, message: 'Start is required'},
                            ]}
                            style={{marginBottom: '10px'}}
                        >
                            <InputLabel>Start Time</InputLabel>
                            <Form.Item
                                rules={[
                                    {required: true, message: 'Start time is required'},
                                ]}
                                name="start"
                                noStyle
                                >
                                <StyledSelect
                                    placeholder={'8:00 am'}
                                    options={timeSelections.map((timeSelection: any) => ({...timeSelection, disabled: endTime ? timeSelection.value > endTime || timeSelection.value === endTime : false}))}
                                    onChange={(value: any) => {
                                        setStartTime(value)
                                    }}
                                />

                            </Form.Item>
                        </Form.Item>
                    </Col>
                    <Col xs={{span: 12}}>
                        <Form.Item
                            rules={[
                                {required: true, message: 'End Time is required'},
                            ]}
                            style={{marginBottom: '10px'}}
                        >
                            <InputLabel>End Time</InputLabel>
                            <Form.Item
                                name="end"
                                noStyle
                                >
                                <StyledSelect
                                    placeholder={'5:00 pm'}
                                    options={timeSelections.map((timeSelection: any) => ({...timeSelection, disabled: startTime ? timeSelection.value < startTime || timeSelection.value === startTime : false}))}
                                    onChange={(value: any) => {
                                        setEndTime(value)
                                    }}
                                />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    style={{margin: '10px 0'}}
                >
                    <div style={{display: 'flex',maxWidth: '80%', margin: '0 auto'}}>
                        <Button size={'large'} onClick={() => {
                            onCancel()
                            form.resetFields()
                        }} style={{flex: 1}}>Cancel</Button>
                        <Button
                            {...(!editSchedule?.days?.length) ? { disabled: true } : {}}
                            htmlType={'submit'}
                            type={'primary'} size={'large'} style={{flex: 1}}
                            disabled={disableSave}
                        >Save</Button>
                    </div>
                </Form.Item>
            </Form>
        )
    }

    return (
        <>
            <Center>
                <Title fontSize={'22px'} marginBottom="10px">Add Schedule</Title>
            </Center>
            <div style={{ overflowY: 'auto', height: "calc(100% - 60px)" }} key="set-availability-setup">
                <Center style={{ height: '100%', justifyContent: 'flex-start' }}>
                    <Prompt
                        success
                        title="Success!"
                        message="You have successfully created a schedule."
                        visible={successVisible}
                        okButton="OK"
                        okFunction={() => {
                            setShowScheduleForm(false)
                            setSuccessVisible(false)
                            setDisableSave(false)
                        }}
                    />

                    {/* <SubTitle fontSize={'12px'}>Select the day and time when you would like to receive booking
                        appointments.</SubTitle> */}
                    <ScheduleForm />
                </Center>
            </div>
        </>
    )
}

export default AvailabilityForm


const InputLabel = styled.h6`
  float: left;
  margin-left: 5px;
`

const StyledSelect = styled(Select)<{ isrounded?: boolean}>`
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
      height: 50px;
    }
  }

  .ant-select-selection-item {
    text-align: left;
    align-self: center;
  }

  .ant-select-selection-placeholder {
    text-align: left;
    align-self: center;
  }

  .ant-select-selection-search {
    input {
        height: 100%;
    }
  }

  &.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
        .ant-select-selection-search-input {
            height: 100%;
        }
    }
  }
`
