import React, {ReactElement, ReactNode} from 'react'
import { Select as AntDSelect } from 'antd';
import { FiChevronDown } from 'react-icons/fi';
import styled from 'styled-components';

const { Option } = AntDSelect;

interface ISelectProps {
    options: IOption[]
    defaultValue?: any,
    isrounded?: boolean,
    color?: string,
    width?: string | number
    optionLabelProp?: string
    onSelect?: any
    onClear?: any
    onChange?: any
    placeholder?: string
    style? : Object
    showSearch?: boolean
    showArrow?: boolean
    mode? : 'multiple' | 'tags',
    value?: string | number
    disabled?: boolean
    dropdownMatchSelectWidth? : boolean | number
    filterOption?: any
    optionFilterProp?: string
    maxTagCount?: number | 'responsive'
    maxTagPlaceholder?: string
    onBlur?: () => void
    allowClear?: boolean
    clearIcon?: ReactNode
}

export interface IOption {
    value: any
    label: ReactElement | string
    disabled?: boolean
    title?: ReactNode
}

function Select({ options, ...rest }: ISelectProps) {
    return (
        <StyledSelect
            {...rest}
            suffixIcon={<FiChevronDown style={{color: rest.color ? 'white' : ''}} size={15}/>}
        >
            {
                (options || [])?.map((option: IOption, idx: number) => (
                    <Option key={`${option?.label}-${idx}`} value={option.value} disabled={option?.disabled || false} title={option.title} style={{opacity: option?.disabled ? 0.3 : 1}}>{option?.label}</Option>
                ))
            }
        </StyledSelect>
    )
}

const StyledSelect = styled(AntDSelect)<{isrounded?: boolean}>`
    margin: 5px 0px;

    &:not(.ant-select-customize-input) {
        .ant-select-selector {
          border-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
        }

        .ant-select-arrow {
            color: #18B0CF;
        }
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
            border-color: #18B0CF;
            box-shadow: 0 0 0 2px rgba(24, 176, 207, 0.2);
        }
    }

    &.ant-select:not(.ant-select-disabled) {
        &:hover {
            .ant-select-selector {
                border-color: #18B0CF;
            }
        } 
    }
`

export default Select
