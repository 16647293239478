// import { useAuth } from 'src/hooks/useAuth'
import APIService from 'src/services/APIService'

const ServiceService = () => {
    // const { currentUser, refreshUser } = useAuth()
    const { 
        // get, 
        put, 
        // remove 
    } = APIService()

    const removeUserFromService = async (uid: number, gid: number) => {
        await put('services/' + gid + '/remove-user', {uid:uid})
            .then((resp: any) => {
            })
    }

    const addUserToService = async (uid: number, gid: number) => {

    }

    return {
        removeUserFromService,
        addUserToService
    }
}


export default ServiceService 