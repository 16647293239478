import React, {useState, useEffect} from "react"
import {Col, Form, Row, Space} from "antd"
import {Title} from "../../components/CommonStyles"
import {InputPassword, InputText} from "src/components/reusables/TextInputs"
import Prompt from "src/components/reusables/Prompt"
import Button from "src/components/reusables/Button"
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import APIService from 'src/services/APIService'
import { useAuth } from 'src/hooks/useAuth'

function Integrations({uid, isViewing = false}: {uid: number, isViewing: boolean}) {
    const [confirmUpdateVisible, setConfirmUpdateVisible] = useState<boolean>(false)
    const [savedCred, setSavedCred] = useState<boolean>(false)

    const { currentUser } = useAuth()
    const { put } = APIService()

    const [crd_form] = Form.useForm();

    useEffect(() => {
        if (currentUser) {
            console.log('currentUser:', currentUser)
            const grapl = currentUser?.api_integrations?.find((_api: any) => _api.application === 'grapl')
            crd_form.setFieldsValue({
                username: grapl?.username,
                password: grapl?.password,
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser])

    const ConfirmUpdatePrompt = () => {
        return (
            <Prompt
                title="Update Credentials"
                message={<>Are you sure you want to update your Grapl API Credentials? </>}
                visible={confirmUpdateVisible}
                okButton="Update"
                okFunction={() => {
                    const values = crd_form.getFieldsValue()
                    SubmitCredentials(values)
                }}
                cancelButton="Cancel"
                cancelFunction={() => {
                    setConfirmUpdateVisible(false)
                }}
            />
        )
    }

    const SavedPrompt = () => {
        return (
            <Prompt
                title="Great!"
                message={<>Grapl credential updated.</>}
                visible={savedCred}
                okButton="Ok"
                okFunction={() => setSavedCred(false)}
            />
        )
    }

    const SubmitCredentials = (values: any) => {
        put('integration/grapl', values)
            .then(resp => {
                setSavedCred(true)
                setConfirmUpdateVisible(false)
            })
            .catch(err => console.error(err))
    }

    return (
    <>
        {confirmUpdateVisible && <ConfirmUpdatePrompt />}
        {savedCred && <SavedPrompt />}
        <Space direction={'horizontal'} align={'center'}>
            <Title fontWeight={'400'} fontSize={'12'} style={{textAlign: 'left', margin: 0, width: 150}}>Grapl Integration</Title>
            {/* <Button type={'primary'} style={{padding: '0px 5px', height: 'auto'}}><PlusOutlined /></Button> */}
        </Space>
        <Row>
            <Col>
            <Form
                form={crd_form}
                name="user-form"
                style={{width: '100%', marginBottom: 15}}
                layout={'vertical'}
                onFinish={(values: any) => setConfirmUpdateVisible(true)}
            >
                <Form.Item
                    name="username"
                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                >
                    <InputText autoComplete='off' placeholder="Email" style={{width: '100%'}} maxLength={50}/>
                </Form.Item>
                <Form.Item
                    name="password"
                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                >
                    <InputPassword autoComplete='off' placeholder="Password" style={{width: '100%'}} maxLength={50} />
                </Form.Item>

                {
                    !isViewing &&
                    <Button type={'primary'} size={'large'} style={{width: '100%', height: 50}}
                        onClick={() => crd_form.submit()}
                    >Save Credential</Button>
                }
            </Form>
            </Col>
        </Row>
    </>
    )
}

export default Integrations

