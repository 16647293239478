import React, { 
    createContext, 
    useState, 
    // useMemo 
} from 'react'

interface orgDataStruct {
    availability: any[],
    setAvailability: React.Dispatch<React.SetStateAction<any[]>>,
    customAvailability: any[],
    setCustomAvailability: React.Dispatch<React.SetStateAction<any[]>>,
    calendarInfoData: any,
    setCalendarInfoData: React.Dispatch<React.SetStateAction<any>>
    appointmentInfoData: any,
    setAppointmentInfoData: React.Dispatch<React.SetStateAction<any>>
    myAppointments: any[],
    setMyAppointments: React.Dispatch<React.SetStateAction<any>>,
    organizationGroups: any[],
    setOrganizationGroups: React.Dispatch<React.SetStateAction<any[]>>,
    organizationServices: any[],
    setOrganizationServices: React.Dispatch<React.SetStateAction<any[]>>,
    saveEvent: any,
    setSaveEvent: React.Dispatch<React.SetStateAction<any>>,
    selectedGroups: any[],
    setSelectedGroups: React.Dispatch<React.SetStateAction<any[]>>,
    syncEvent: any,
    setSyncEvent: React.Dispatch<React.SetStateAction<any>>,
    orgContextData: any,
    setOrgContextData: React.Dispatch<React.SetStateAction<any>>,
    openProfile: any,
    setOpenProfile: React.Dispatch<React.SetStateAction<any>>,
    currentSelectedUser: any,
    setCurrentSelectedUser: React.Dispatch<React.SetStateAction<any>>,
}
const orgData = {
    availability: [],
    setAvailability: (d: any[]) => {},
    customAvailability: [],
    setCustomAvailability: (d: any[]) => {},
    calendarInfoData: {},
    setCalendarInfoData: (d: any) => {},
    myAppointments: [],
    setMyAppointments: (d: any) => {},
    appointmentInfoData: [],
    setAppointmentInfoData: (d: any) => {},
    organizationGroups: [],
    setOrganizationGroups: (d: any[]) => {},
    organizationServices: [],
    setOrganizationServices: (d: any[]) => {},
    saveEvent: {},
    setSaveEvent: (d: any) => {},
    selectedGroups: [],
    setSelectedGroups: (d: any[]) => {},
    syncEvent: {},
    setSyncEvent: (d: any) => {},
    orgContextData: {},
    setOrgContextData: (d: any) => {},
    openProfile: {visible: false, tab: 'profile'},
    setOpenProfile: (d: any) => {},
    currentSelectedUser: {},
    setCurrentSelectedUser: (d: any) => {},
} as orgDataStruct

const OrgContext = createContext(orgData);

const OrgProvider = (props: any) => {

    const [availability, setAvailability] = useState<any[]>([])
    const [customAvailability, setCustomAvailability] = useState<any[]>([])
    const [calendarInfoData, setCalendarInfoData] = useState<any>()
    const [openProfile, setOpenProfile] = useState({visible: false, tab: 'profile'})
    const [myAppointments, setMyAppointments] = useState<any[]>([])
    const [appointmentInfoData, setAppointmentInfoData] = useState<any>()
    const [organizationGroups, setOrganizationGroups] = useState<any[]>([])
    const [organizationServices, setOrganizationServices] = useState<any[]>([])
    const [saveEvent, setSaveEvent] = useState<any>(Date.now())
    const [selectedGroups, setSelectedGroups] = useState<any[]>([])
    const [syncEvent, setSyncEvent] = useState<any>(Date.now())
    const [orgContextData, setOrgContextData] = useState<any>(Date.now())
    const [currentSelectedUser, setCurrentSelectedUser]= useState<any>()


    return <OrgContext.Provider value={
        {
            availability,
            setAvailability,
            customAvailability,
            setCustomAvailability,
            calendarInfoData,
            setCalendarInfoData,
            myAppointments,
            setMyAppointments,
            appointmentInfoData,
            setAppointmentInfoData,
            organizationGroups,
            setOrganizationGroups,
            organizationServices,
            setOrganizationServices,
            selectedGroups,
            setSelectedGroups,
            saveEvent,
            setSaveEvent,
            syncEvent,
            setSyncEvent,
            orgContextData,
            setOrgContextData,
            openProfile,
            setOpenProfile,
            currentSelectedUser,
            setCurrentSelectedUser
        }
    }>{props.children}</OrgContext.Provider>;
};

const Consumer = OrgContext.Consumer
export { OrgContext, OrgProvider, Consumer };

