import { Input } from "antd";
import styled from "styled-components";

export const InputText = styled(Input)<{ isrounded?: boolean, width?: string, height?: string}>`
    width: ${({width}) => width || '200px'};
    margin: 5px 0px;
    height: ${({height}) => height || '50px'};

    &.ant-input, &.ant-input-affix-wrapper {
        border-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
    }
`

export const InputTextArea = styled(Input.TextArea)<{ isrounded?: boolean, width?: string, height?: string}>`
    width: ${({width}) => width || '200px'};
    margin: 5px 0px;
    height: ${({height}) => height || '50px'};

    &.ant-input, &.ant-input-affix-wrapper {
        border-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
    }
`

export const InputPassword = styled(Input.Password)<{ isrounded?: boolean, width?: string, height?: string}>`
    width: ${({width}) => width || '200px'};
    margin: 5px 0px;
    height: ${({height}) => height || '50px'};

    &.ant-input-affix-wrapper {
        border-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
    }
`

export const InputSearch = styled(Input.Search)<{ isrounded?: boolean , width?: string, height?: string}>`
  width: ${({width}) => width || '200px'};
  padding: 5px;
  height: ${({height}) => height || '50px'};
  .ant-input-group > .ant-input:first-child {
    border-top-left-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
    border-bottom-left-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
    //border-color: ${props => props.theme.colorBorderOverPrimaryBackground};
  }

  &.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    border-left: none;
    border-top-right-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
    border-bottom-right-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
  }

  &.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child{
    background: transparent;
  }

  &.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:hover {
    //border-color: ${props => props.theme.colorPrimary};
    // border-left: 1px solid ${props => props.theme.colorPrimary};
  }

  &.ant-input-search .ant-input:focus, &.ant-input-search .ant-input:hover {
    //border-color: ${props => props.theme.colorPrimary};
    // box-shadow: 0px 0px 0px 2px ${props => props.theme.colorPrimaryShadow};
  }
`
