import React, {useEffect, useMemo, useState} from 'react'
import {Col, Row, Divider, Dropdown, Space, Form, Tooltip as AntDTooltip} from 'antd'
import Button from 'src/components/reusables/Button'
import {Center, Title} from 'src/components/CommonStyles'
import {FaChevronDown} from "react-icons/fa";
import Checkbox from "../reusables/Checkbox";
import styled from 'styled-components'
import Select from "../reusables/Select";
import {
    AiOutlineClose,
    AiFillStar as DefaultIcon,
    AiOutlineStar as UnDefaultIcon,
} from "react-icons/ai";
// import { useAuth } from 'src/hooks/useAuth'
import APIService from 'src/services/APIService'
import Table from "../reusables/Table";
import {InputText} from "../reusables/TextInputs";
import EditIcon from 'src/assets/icons/EditIcon.svg';
import AddIcon from 'src/assets/icons/AddIcon.svg';
import DeleteIcon from 'src/assets/icons/DeleteIcon.svg';
import Prompt from "src/components/reusables/Prompt";
import {useOrg} from 'src/hooks/useOrgContext'
import {HHmm, DDHHmm, ndHHmmaNoTZ, HHMMss, ndHHMMss} from 'src/helpers/datetime';
import Options from 'src/services/APIOptions'
import TimeAvailability from 'src/components/Forms/TimeAvailability';
import EditSchedNameForm from 'src/components/Forms/EditSchedNameForm';
import moment from 'moment'
import {sortBy} from 'lodash'
import Modal from "src/components/reusables/Modal";
import {useAuth} from 'src/hooks/useAuth'
import RoleService from 'src/services/RoleService'

import CustomAvailabilityForm from 'src/components/Forms/CustomAvailabilityForm'
import truncate from "src/helpers/truncate";
import InputNumber from "../reusables/InputNumber";

function Availability({uid, user}: { uid: number, user: any}) {
    const [form] = Form.useForm();
    const { checkModuleRoles } = RoleService()
    const {currentUser, refreshUser} = useAuth()

    const {get, remove, put} = APIService()
    const [availabiltyData, setavailabiltyData] = useState<any[]>([])
    const [customAvailabilityData, setCustomAvailabilityData] = useState<any[]>([])
    const [weekData, setWeekData] = useState<any[]>([])
    const [schedName, setSchedName] = useState('')
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState<boolean>(false)
    const [selectedSched, setSelectedSched] = useState<any>()
    const [selectedCustomSched, setSelectedCustomSched] = useState<any>()
    // const [schedBuffer, setSchedBuffer] = useState<object>()
    const [schedBufferState, setSchedBufferState] = useState<{ bm: boolean, am: boolean }>({bm: false, am: false})
    const [confirmDeleteCustomVisible, setConfirmDeleteCustomVisible] = useState(false)
    const [confirmDeleteSlotVisible, setConfirmDeleteSlotVisible] = useState(false)
    const [selectedSlot, setSelectedSlot] = useState<any>()
    const [selectedTZ, setSelectedTZ] = useState<any>()
    const [timezoneList, setTimezoneList] = useState<{ label: string, value: string }[]>([])
    const [showtimeAvailabilityForm, setShowtimeAvailabilityForm] = useState(false)
    const [showEditSchedNameForm, setShowEditSchedNameForm] = useState(false)
    const [selectedDay, setSelectedDay] = useState<any>()
    const [showCustomAvailabilityForm, setShowCustomAvailabilityForm] = useState(false)


    const {availability, setAvailability, customAvailability, setCustomAvailability} = useOrg()

    useEffect(() => {
        Options.getTimezoneList().then((resp) => setTimezoneList(resp))
    }, [])

    useEffect(() => {
        setSelectedTZ(user?.timezone)
        console.log('user TZ', user?.timezone)
    }, [user])

    useEffect(() => {
        if (selectedSched) {
            setSchedName(selectedSched?.name)
            setWeekData(convertFromMeta(selectedSched?.meta))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSched, selectedTZ])

    useEffect(() => {
        getMyAvailability()
        getCustomAvailability()
        setSchedBufferState({bm: user.availability_buffer?.before_meeting, am: user.availability_buffer?.after_meeting})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid])

    useEffect(() => {
        if (availability.length === 1) {
            setSelectedSched(availability[0])
        }
        setavailabiltyData(availability)
        console.log('availability', availability)
        console.log('customAvailability', customAvailability)
        const tzed = customAvailability.map((ca: any) => {
            // const dat = moment(ca.schedule_date).format('YYYY-MM-DD')
            // const star = HHMMss(ca.end_time)
            // const end = HHMMss(ca.end_time)
            // const withTZ = DDHHmm(dat + 'T' + star, selectedTZ) + ' ' + DDHHmm(dat + 'T' + end, selectedTZ)
            // return {...ca, datetime: ca.datetime }
            return {...ca, datetime: ca.formattedDateTime}
        })
        setCustomAvailabilityData(tzed)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availability, customAvailability, selectedTZ])

    const [updatedSuccessVisible, setUpdatedSuccessVisible] = useState<boolean>(false)

    const UpdatedSuccessPrompt = useMemo(() => {
        return (
            <Prompt
                success
                title={"Availability Updated"}
                message={`You have successfully updated your availability`}
                visible={updatedSuccessVisible}
                okButton="OK"
                okFunction={() => {
                    setUpdatedSuccessVisible(false)
                }}
            />
        )
    }, [updatedSuccessVisible])

    const convertFromMeta = (metaData: any) => {
        const weekOrd = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        // const weekOrd = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su']

        metaData = metaData.filter((m: any) => m.start && m.end)

        // get non-existent days from metaData[].day
        const days = metaData.map((m: any) => m.day)
        const missingDays = weekOrd.filter((w: any) => !days.includes(w))

        const missingDaysData = missingDays.map((w: any) => {
            return {day: w, start: null, end: null}
        })

        metaData = [...metaData, ...missingDaysData]

        metaData = sortBy(metaData, (md) => weekOrd.indexOf(md.day))
        let existDays: any[] = []
        const res = metaData.map((day: any) => {
            console.log('selectedTZ', selectedTZ)
            const fromM = {
                day: day?.day,
                dayT: (existDays.indexOf(day?.day) === -1) && day?.day ? day?.day?.slice(0, 3)?.toUpperCase() : '',
                // available: HHmm(day.start, selectedTZ) + ' - ' + HHmm(day.end, selectedTZ),
                available: day?.start && day?.end ? ndHHmmaNoTZ(day?.start) + ' - ' + ndHHmmaNoTZ(day?.end) :
                    <span style={{opacity: 0.5}}>No Schedule</span>,
                start: day?.start,
                end: day?.end,
            }
            existDays.push(day?.day)
            console.log('fromM', fromM)
            return fromM
        })
        console.log('SelectedMeta:', res)
        return res
    }

    const getMyAvailability = async (preselect?: boolean) => {
        await get('users/' + uid + '/schedules', [])
            .then((resp: any) => {
                // setavailabiltyData(resp)
                setAvailability(resp)

                if (resp.length && !preselect) {
                    setSelectedSched(resp[0])
                    if(!resp.some((schedule: any) => schedule.default)){
                        setScheduleDefault(resp[0].name)
                    }
                    //setSchedName(resp[0].name)
                    //setWeekData(resp[0].dailys)
                    //setWeekData(convertFromMeta(resp[0].meta))
                }
            })
    }

    const getCustomAvailability = async () => {
        await get('users/' + uid + '/custom-schedules', [])
            .then((resp: any) => {
                //setCustomAvailabilityData(resp)
                setCustomAvailability(resp)
            })
    }

    const removeAvailability = async () => {
        if(selectedSched?.hasGroups){
            return
        }
        await remove('schedules/' + selectedSched.id).then(() => {
            getMyAvailability()
        })
    }

    const removeCustomAvailability = async () => {
        await remove('custom-schedules/' + selectedCustomSched.id).then(() => {
            getCustomAvailability()
        })
    }

    const setBuffer = async (values: any) => {
        await put('users/' + uid + '/availability-buffer', {...values, after_meeting: values.after_meeting || 0, before_meeting: values.before_meeting || 0}).then((resp) => {
            if (user.id === currentUser.id) {
                refreshUser({...currentUser, availability_buffer: resp})
            }
            setUpdatedSuccessVisible(true)
        })
    }

    const removeAvailabilitySlot = async () => {
        const newMeta = selectedSched.meta.filter((slot: any) => {
            return slot.day !== selectedSlot.day || slot.start !== selectedSlot.start
        })

        setSelectedSched({...selectedSched, meta: newMeta})
        console.log(newMeta)

        await put('users/' + uid + '/schedules', {...selectedSched, meta: newMeta})
            .then((resp: any) => {
                setavailabiltyData(resp)
            })
    }

    const ConfirmToDeleteSchedPrompt = (
        <>
            <Prompt
                title="Remove Availability"
                message={<>Are you sure you want to remove this slot? </>}
                visible={confirmDeleteSlotVisible}
                okButton="Delete"
                okFunction={() => {
                    removeAvailabilitySlot().then(() => {
                        setConfirmDeleteSlotVisible(false)
                    })
                }}
                danger={true}
                cancelButton="Cancel"
                cancelFunction={() => {
                    setConfirmDeleteSlotVisible(false)
                }}
            />
        </>
    )

    const ConfirmToDeletePrompt = (
        <>
            <Prompt
                title={selectedSched?.default ? "Remove Default Availability" : "Remove Availability"}
                message={selectedSched?.default ? <>Are you sure you want to remove the default schedule?</> : <>Are you sure you want to remove this schedule? </>}
                visible={confirmDeleteVisible}
                okButton="Delete"
                okFunction={() => {
                    removeAvailability().then(() => {
                        setSchedName('')
                        setConfirmDeleteVisible(false)
                    })
                }}
                danger={true}
                cancelButton="Cancel"
                cancelFunction={() => {
                    setConfirmDeleteVisible(false)
                }}
            />
        </>
    )
    const [deleteFailedPrompt, setDeleteFailedPrompt] = useState(false)
    const CantDeletePrompt = (
        <>
            <Prompt
                title="Failed"
                message={<>Schedule is used by group/s</>}
                visible={deleteFailedPrompt}
                okButton="Ok"
                okFunction={() => {
                    setDeleteFailedPrompt(false)
                }}
            />
        </>
    )

    const ConfirmToDeleteCustomPrompt = (
        <>
            <Prompt
                title="Remove Custom Availability"
                message={<>Are you sure you want to remove this custom schedule? </>}
                visible={confirmDeleteCustomVisible}
                okButton="Delete"
                okFunction={() => {
                    removeCustomAvailability().then(() => {
                        setConfirmDeleteCustomVisible(false)
                    })
                }}
                danger={true}
                cancelButton="Cancel"
                cancelFunction={() => {
                    setConfirmDeleteCustomVisible(false)
                }}
            />
        </>
    )

    const columns: any = [
        {
            title: 'Working Days',
            dataIndex: 'dayT',
        },
        {
            title: 'Time',
            dataIndex: 'available',
        },
        {
            title: 'Action',
            key: 'action',
            width: 120,
            render: (row: any) => (
                <Center>
                    <Space>
                    { checkModuleRoles('users', 'update') && (
                    <Button
                        onClick={() => {
                        if (!!!row?.start && !!!row?.end) return
                        setSelectedSlot(row)
                        setSelectedDay(row.day)
                        setShowtimeAvailabilityForm(true)
                    }} type={'text'} style={{
                        margin: 0,
                        padding: '4px 5px',
                        visibility: !!!row?.start && !!!row?.end ? 'hidden' : 'visible'
                    }}><img src={EditIcon} alt="edit"/></Button>
                    )}
                    { checkModuleRoles('users', 'create') && (
                    <Button onClick={() => {
                        setSelectedSlot(false)
                        setSelectedDay(row.day)
                        setShowtimeAvailabilityForm(true)
                    }} type={'text'} style={{margin: 0, padding: '4px 5px'}}><img src={AddIcon} alt="add"/></Button>
                    )}
                    { checkModuleRoles('users', 'delete') && (
                    <Button onClick={() => {
                        if (!!!row?.start && !!!row?.end) return
                        setConfirmDeleteSlotVisible(true)
                        setSelectedSlot(row)
                        console.log(row)
                    }} type={'text'} style={{
                        margin: 0,
                        padding: '4px 5px',
                        visibility: !!!row?.start && !!!row?.end ? 'hidden' : 'visible'
                    }}><img src={DeleteIcon} alt="delete"/></Button>
                    )}
                    </Space>
                </Center>
                ),
        },
    ];

    const customAvailabilityColumns: any = [
        {
            title: 'Date and Time',
            dataIndex: 'datetime',
            sorter: (a: any, b: any) => a.datetime - b.datetime,
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            sorter: (a: any, b: any) => a.notes - b.notes,
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            render: (row: any) => (
                <Center>
                    <Space>
                        <Button
                            disabled={!!!checkModuleRoles('users', ['update'])}
                            onClick={() => {
                            setSelectedCustomSched(row);
                            setShowCustomAvailabilityForm(true)
                        }} type={'text'} style={{margin: 0, padding: '4px 5px'}}><img src={EditIcon} alt="edit"/></Button>
                        <Button
                            disabled={!!!checkModuleRoles('users', ['delete'])}
                            onClick={() => {
                            setSelectedCustomSched(row);
                            setConfirmDeleteCustomVisible(true)
                        }} type={'text'} style={{margin: 0, padding: '4px 5px'}}><img src={DeleteIcon}
                                                                                      alt="delete"/></Button>
                    </Space>
                </Center>
            ),
        },
    ];

    const timeAvailabilityForm = (
        <Modal visible={showtimeAvailabilityForm} width={500} onCancel={() => setShowtimeAvailabilityForm(false)}>
            <TimeAvailability
                onCancel={() => {
                    setShowtimeAvailabilityForm(false)
                    setSelectedSlot(null)
                }}
                onSave={() => {
                    getMyAvailability()
                    setShowtimeAvailabilityForm(false)
                }}
                slot={selectedSlot}
                schedule={selectedSched}
                day={selectedDay}
                user={user}/>
        </Modal>
    )

    const editSchedName = (
        <Modal visible={showEditSchedNameForm} width={500} onCancel={() => setShowEditSchedNameForm(false)}>
            <EditSchedNameForm
                userId={uid}
                onCancel={() => setShowEditSchedNameForm(false)}
                onSave={() => {
                    getMyAvailability()
                    setShowEditSchedNameForm(false)
                }}
                schedule={selectedSched}
            />
        </Modal>
    )

    const customSchedForm = (
        <Modal visible={showCustomAvailabilityForm} width={500} onCancel={() => setShowCustomAvailabilityForm(false)}>
            <CustomAvailabilityForm
                onCancel={() => setShowCustomAvailabilityForm(false)}
                onSave={() => {
                    setShowCustomAvailabilityForm(false)
                }}
                user={currentUser}
                cSched={selectedCustomSched}/>
        </Modal>
    )

    const ScheduleTag = ({sched}: { sched: any }) => (
        <StyledRow
            style={{
                margin: '10px 5px 10px 0px',
                width: 135,
                backgroundColor: schedName === sched.name ? '#18B0CF8C' : '#81818D7A',
                padding: '5px 10px',
                borderRadius: 5,
                display: 'flex',
                alignItems: 'center',
                position: 'relative'
            }}
            onContextMenu={() => alert(`is default set ${sched.default ? 'YES' : 'NO'}`)}
        >
            <AntDTooltip title={sched.name}>
                <Title fontWeight={'bold'} fontSize={'12px'} style={{margin: 0}}>
                    {sched.default ? '*' : ''} {truncate(sched.name, 12)}
                </Title>
            </AntDTooltip>
            {checkModuleRoles('users', ['delete'/*, 'update', 'create'*/]) && (<AiOutlineClose
                onClick={() => {
                if(sched?.hasGroups){
                    setDeleteFailedPrompt(true)
                    return
                }
                setSelectedSched(sched);
                setConfirmDeleteVisible(true);
                return false
            }} style={{position: 'absolute', right: 5, color: '#81818D'}} size={15}/>)
            }
        </StyledRow>
    )

    const setScheduleDefault = async (schedName: string) => {
        let schedule = availabiltyData.find((_sched: any) => _sched.name === schedName)
        let toDefault = schedule.default ? 0 : 1
        await get('/schedules/set-default/' + schedule.id + '/' + toDefault + '/' + uid, null)
            .then((resp: any) => {
                getMyAvailability(true) // fetch all schedules
            })
            .catch((resp: any) => console.error('error:', resp))
    }

    const getSched = () => {
        return availabiltyData.find((_sched: any) => _sched.name === schedName)
    }

    return (
        <div style={{padding: 0, overflowY: 'auto', overflowX: 'hidden'}}>
            { updatedSuccessVisible && UpdatedSuccessPrompt}
            {ConfirmToDeletePrompt}
            {ConfirmToDeleteCustomPrompt}
            {ConfirmToDeleteSchedPrompt}
            {showtimeAvailabilityForm ? timeAvailabilityForm : null}
            {showEditSchedNameForm ? editSchedName : null}
            {showCustomAvailabilityForm ? customSchedForm : null}
            {deleteFailedPrompt ? CantDeletePrompt : null}
            <Row>
                {availabiltyData.map((sched) => (
                    <Col data-id={sched.name} onClick={() => {
                        //setWeekData(convertFromMeta(sched.meta))
                        //setSchedName(sched.name)
                        setSelectedSched(sched)
                    }}>
                        {
                            sched.default
                                ? <AntDTooltip title="Default Schedule">
                                    <ScheduleTag sched={sched}/>
                                </AntDTooltip>
                                : <ScheduleTag sched={sched}/>
                        }
                    </Col>
                ))}
            </Row>
            <Row>
                <Col span={24}>
                    <Divider style={{margin: '5px 0px'}}/>
                    <Row>
                        <Col span={12} style={{padding: '0px 12px 0px 0px'}}>
                            {(availabiltyData.length && schedName) ? <Title fontWeight={'bold'} fontSize={'12px'}
                                                                            style={{
                                                                                margin: '5px 0px 0px',
                                                                                textAlign: 'left'
                                                                            }}>{schedName} <Button
                                                                            disabled={!!!checkModuleRoles('users', ['update'])}
                                                                            onClick={() => {
                                                                                setShowEditSchedNameForm(true)
                                                                            }} type={'text'} style={{margin: 0, padding: '4px 5px'}}><img src={EditIcon}
                                                                                          alt="edit"/></Button></Title> : null}
                            <Title fontWeight={'400'} fontSize={'12px'} style={{margin: '10px 5px', float: 'left'}}>
                                {user?.timezone}
                            </Title>

                            {checkModuleRoles('users', ['create', 'update'/*, 'delete'*/]) && (<AntDTooltip
                                title={getSched()?.default ? 'Remove as default schedule' : 'Set as default schedule'}>
                                <DefaultBtn
                                fontWeight={'400'} fontSize={'18px'} onClick={() => {
                                    setScheduleDefault(schedName).then(r => {})
                                }}
                                            className={getSched()?.default ? 'is-default' : 'not-default'}>
                                    {getSched()?.default ? <DefaultIcon/> : <UnDefaultIcon/>}
                                </DefaultBtn>
                            </AntDTooltip>)}
                            <div style={{display: 'block'}}>
                                <Table
                                    hideOnSinglePage={true}
                                    style={{fontWeight: 'bold'}}
                                    columns={columns}
                                    dataSource={weekData}
                                />
                            </div>
                        </Col>
                        <Col span={12} style={{padding: '0px 0px 0px 12px'}}>
                            <Title fontWeight={'bold'} fontSize={'12px'}
                                   style={{margin: '10px 5px', textAlign: 'left'}}>Custom Availability</Title>
                            <div style={{display: 'block'}}>
                                <Table
                                    style={{fontWeight: 'bold'}}
                                    columns={customAvailabilityColumns}
                                    dataSource={customAvailabilityData}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Divider style={{margin: '5px 0px'}}/>
                    <Form
                        disabled={!!!checkModuleRoles('users', 'update')}
                        initialValues={{
                            ...user?.availability_buffer,
                            before_meeting: user?.availability_buffer?.before_meeting ? user?.availability_buffer?.before_meeting : '',
                            after_meeting: user?.availability_buffer?.after_meeting ? user?.availability_buffer?.after_meeting : '',
                        }}
                        form={form}
                        name="create-schedule-form"
                        style={{width: '100%', marginTop: 15}}
                        layout={'vertical'}
                        onFinish={(values) => {
                            setBuffer(values)
                        }}
                    >
                        <Row>
                            <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                                <Title
                                    fontWeight={'500'}
                                    fontSize={'14px'}
                                    style={{margin: 0, textAlign: 'left'}}>
                                    Set buffer or break time in between your meetings
                                </Title>
                            </Col>
                            <Col span={12}>
                                { checkModuleRoles('users', 'update') &&
                                <Button onClick={() => {
                                    form.submit()
                                }} type={"primary"} size={'large'} style={{margin: 0, float: 'right'}}>
                                    Save
                                </Button>
                                }
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Row style={{width: '100%'}}>
                                    <Col span={10} style={{alignItems: 'center', display: 'flex'}}>
                                        <Checkbox checked={schedBufferState.bm} onChange={(value) => {
                                            setSchedBufferState({
                                                ...schedBufferState,
                                                bm: value.target.checked
                                            })
                                            if (!!!value.target.checked) form.setFieldValue('before_meeting', undefined)
                                        }
                                        }>
                                            <Title fontWeight={'400'} fontSize={'14px'}
                                                   style={{margin: '0px 10px 0px 20px', textAlign: 'left'}}>Before
                                                Meeting</Title>
                                        </Checkbox>
                                    </Col>
                                    <Col span={14}>
                                        <Form.Item
                                            name="before_meeting"
                                            rules={[
                                                {required: schedBufferState.bm, message: 'Please select buffer duration'}
                                            ]}
                                        >
                                            <StyledSelect
                                                disabled={!!!schedBufferState?.bm}
                                                placeholder={''}
                                                defaultValue={undefined}
                                                options={[
                                                    {label: '15 mins', value: 15,},
                                                    {label: '30 mins', value: 30,},
                                                ]}
                                                style={{width: '100%'}}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{width: '100%'}}>
                                    <Col span={10} style={{alignItems: 'center', display: 'flex'}}>
                                        <Checkbox checked={schedBufferState.am} onChange={(value) => {
                                            setSchedBufferState({
                                                ...schedBufferState,
                                                am: value.target.checked
                                            })
                                            if (!!!value.target.checked) form.setFieldValue('after_meeting', undefined)
                                        }}>
                                            <Title fontWeight={'400'} fontSize={'14px'}
                                                   style={{margin: '0px 10px 0px 20px', textAlign: 'left'}}>After
                                                Meeting</Title>
                                        </Checkbox>
                                    </Col>
                                    <Col span={14}>
                                        <Form.Item
                                            name="after_meeting"
                                            rules={[
                                                {required: schedBufferState.am, message: 'Please select buffer duration'}
                                            ]}
                                        >
                                            <StyledSelect
                                                disabled={!!!schedBufferState?.am}
                                                placeholder={''}
                                                defaultValue={undefined}
                                                options={[
                                                    {label: '15 mins', value: 15,},
                                                    {label: '30 mins', value: 30,},
                                                ]}
                                                style={{width: '100%'}}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Title fontWeight={'500'} fontSize={'14px'}
                                       style={{margin: '20px 10px 0px 0px', textAlign: 'left'}}>Scheduling conditions</Title>
                                <Title fontWeight={'400'} fontSize={'14px'}
                                       style={{margin: '0px 10px 0px 0px', textAlign: 'left'}}>Invitees
                                    can't schedule within</Title>
                                <Row style={{width: '100%'}}>
                                    <Col span={4}>
                                        <Form.Item name="before_selected_time" noStyle>
                                            <InputNumber
                                                value={form.getFieldValue('before_selected_time')}
                                                placeholder="1"
                                                style={{width: '100%', textAlign: 'center'}}
                                                wholeNumber={true}
                                                onChange={(value: any) => {
                                                    form.setFieldValue('before_selected_time', value)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={19} offset={1}>
                                        <Space direction={'horizontal'}>
                                            <Form.Item name="before_selected_time_unit" noStyle>
                                                <StyledSelect
                                                    placeholder={'hours or days'}
                                                    options={[
                                                        {label: 'Hours', value: 'hours',},
                                                        {label: 'Days', value: 'days',},
                                                    ]}
                                                    style={{width: '100%'}}
                                                />
                                            </Form.Item>
                                            <Title fontWeight={'400'} fontSize={'14px'}
                                                   style={{margin: '0px 10px 0px 0px', textAlign: 'left'}}>before
                                                the selected time</Title>
                                        </Space>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

const DefaultBtn = styled(Title)`
  cursor: pointer;
  margin: 10px 5px;
  float: right;

  &.is-default {
    color: orange;
  }
`

const StyledSelect = styled(Select)<{ isrounded?: boolean }>`
  min-width: 200px;

  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: ${({isrounded}) => isrounded ? '30px' : '10px'};
      height: 50px;
    }
  }

  .ant-select-selection-item {
    text-align: left;
    align-self: center;
  }

  .ant-select-selection-placeholder {
    text-align: left;
    align-self: center;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }
`

const StyledRow = styled(Row)`
  cursor: pointer
`

export default Availability
