import _ from 'lodash'
import { Country, State, City }  from 'country-state-city';
import ApiService from './APIService'
const { get } = ApiService()

const countryCodes = require('country-codes-list')


const Options = {
    getTimezoneList: async () => {
        return await get('/options/list-of-timezones', {})
            .then((timezones:any) => {
                const options:{
                    label: string,
                    value: string
                }[] = []
                Object.keys(timezones).forEach(value => {
                    options.push({ label: `${timezones[value]}`, value: `${value}` })
                })

                return options
            })
    },
    getPhoneCountryCodes: () => {
        const codes = countryCodes.customList('countryCode', '{countryCallingCode}||{countryNameEn}')
        let list:{
            code: string
            country: string
            phone_code: string|number
        }[] = []
        Object.keys(codes).forEach(code => {
            let content = codes[code].split('||')

            list.push({
                code,
                country: content[1],
                phone_code: content[0]
            })
        })
        list = _.sortBy(list, 'country')

        // sort list moving us to the top via code
        const us = list.find(item => item.code === 'US')
        if(us) {
            list = list.filter(item => item.code !== 'US')
            list.unshift(us)
        }

        const options:{
            label: string,
            value: string,
            title?: string
        }[] = list.map(_code => {
            return {
                label: `(+${_code.phone_code}) ${_code.country}`,
                value: `+${_code.phone_code}`,
                title: `+${_code.phone_code}`,
            }
        })

        return options
    },
    getCountryList: (countryCode:string|undefined) => {
        if (countryCode) {
            return Country.getCountryByCode(countryCode)
        }

        return Country.getAllCountries()
    },
    getStateList: (countryCode:string|undefined) => {
        if (countryCode)
            return State.getStatesOfCountry(countryCode)

        return State.getAllStates()
    },
    getCityList: (countryCode:string|null|undefined, stateCode:string|undefined) => {
        if (countryCode && stateCode)
            return City.getCitiesOfState(countryCode, stateCode)
        else if (countryCode)
            return City.getCitiesOfCountry(countryCode)

        return City.getAllCities()
    },
    getFieldTypes: async () => {
        return await get('/options/field-types', {})
            .then((res:any) => {
                return res.data
            })
    }

}

export default Options
