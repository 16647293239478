import {Table as AntDTable} from "antd";
import styled from "styled-components";
import React, {useState} from "react";
import {useLocation} from "react-router-dom";

function Table({...props}){
    let location = useLocation()
    const isSuperAdmin = location.pathname.includes('super-admin/organization')
    const {scrollY, hideOnSinglePage, paginationProps, ...res} = props
    const [scrollSize, setScrollSize] = useState<any>(window.innerHeight - (isSuperAdmin ? 420 : 300))
    React.useEffect(() => {
        window.addEventListener('resize', () => {
            setScrollSize(window.innerHeight - (isSuperAdmin ? 420 : 300))
        })
    }, []);
    return (
        <StyledTable
            {...res}
            scroll={{y: scrollY || `${scrollSize}px`}}
            pagination={{ position: ['bottomRight'], hideOnSinglePage: hideOnSinglePage, showSizeChanger: true, locale:{items_per_page: ''}, ...paginationProps?.meta}}
        />
    )
}

export const StyledTable = styled(AntDTable)<{ minHeight?: string}>`
  padding: 5px;
  height: auto;
  
  .ant-table-thead > tr > th{
    background-color: #D9D9D9;
    text-align: center;
    color: ${({ theme }) => theme.colorText};
  }

  .ant-table-tbody {
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  .ant-table-tbody > tr > td{
    padding: 5px 16px;
  }
  .ant-spin-nested-loading{
    height: 100%;
    min-height: ${({minHeight}) => minHeight};
  }
  .ant-spin-container{
    height: 100%;
    min-height: ${({minHeight}) => minHeight};
  }
  .ant-table{
    height: 100%;
    min-height: ${({minHeight}) => minHeight};
  }
  
  .ant-table-container{
    border-width: 1px;
    border-color: rgba(217,217,217,0.5);
    border-style: solid;
    border-radius: 5px;
    min-height: ${({minHeight}) => minHeight};
  }
  
  .ant-pagination{
    height: auto;
    align-items: flex-end;
    margin: 0px;
    padding: 10px 0px;
  }
  
  .ant-pagination-item {
    background-color: #D9D9D9;
    border-radius: 5px;
    font-weight: 500;
    color: white;
  }

  .ant-pagination-item-active {
    background-color: #18B0CF;
    border-radius: 5px;
    font-weight: bold;
  }

  .ant-pagination-item-active a {
    font-weight: bold;
    color: white;
  }

  .ant-pagination-item-link {
    border-radius: 5px;
    background-color: #D9D9D9;
  }
  
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-weight: bold;
    width: 50px;
  }

  .ant-pagination-options{
    margin: 0px;
    position: absolute;
    left: 0px
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
  }
`

export default Table
