import React, { ReactNode } from 'react'
import { Switch as AntDSwitch, SwitchProps } from 'antd'
import styled from 'styled-components'

interface ISwitchProps {
    label?: ReactNode
}

function Switch({ label, ...rest }: SwitchProps & ISwitchProps) {
  return (
    <>
        <StyledSwitch {...rest} />
        {label ? <span style={{ marginRight: '5px', margin: 'auto 0px' }}>{label}</span> : ''}

    </>
  )
}

const StyledSwitch = styled(AntDSwitch)`
    margin: 5px;

    &.ant-switch {
       background-color: #4F4F4F;
    }

    &.ant-switch-checked {
        background-color: #18B0CF;
    }

    
`

export default Switch
