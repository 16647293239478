
import React, {useEffect, useState} from 'react'
import {Col, Row, Form, DatePicker} from 'antd'
import {InputLabel, SubTitle, Title} from 'src/components/CommonStyles'
import {InputText} from "../reusables/TextInputs";
import styled from 'styled-components'
import Select from "../reusables/Select";
import Button from 'src/components/reusables/Button'
import CalendarIcon from 'src/assets/icons/CalendarIcon.svg';
import {timeSelections} from "../../constants/datetime";
import APIService from 'src/services/APIService'
import  {useOrg} from 'src/hooks/useOrgContext'
import moment from 'moment'
import {
    // yyyyMMdd, 
    HHMMss, plusTimezoneOffset, ndHHMMss} from 'src/helpers/datetime'
// import dayjs from 'dayjs'
import Prompt from "../reusables/Prompt";


const CustomAvailabilityForm = ({onCancel, onSave, user, cSched}: {onCancel: () => void, onSave: () => void, user: any, cSched?: any}) => {
    const [customAvailabilityForm] = Form.useForm();

    const { put } = APIService()
    const {customAvailability, setCustomAvailability} = useOrg()

    const [selectedDate, setSelectedDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [editStartTime, setEditStartTime] = useState(false)
    const [editEndTime, setEditEndTime] = useState(false)

    useEffect(() => {
        if (cSched) {
            setSelectedDate(moment(cSched?.schedule_date_tz).tz(user?.timezone).format('YYYY-MM-DD'))
            customAvailabilityForm.setFieldsValue({
                schedule_date: moment(cSched?.schedule_date_tz).tz(user?.timezone), // this fields from DB is mutated into user's current timezone set in profile
                start_time: HHMMss(cSched?.start_time_tz, user?.timezone), // this fields from DB is mutated into user's current timezone set in profile
                end_time: HHMMss(cSched?.end_time_tz, user?.timezone), // this fields from DB is mutated into user's current timezone set in profile
                notes: cSched.notes
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cSched])

    const addCustomSched = async (data: any) => {
        // const reformed = {
        //     start_time: moment.tz(`${data.schedule_date}T${data.start_time}Z`, user.timezone).toISOString(),
        //     end_time: moment.tz(`${data.schedule_date}T${data.end_time}Z`, user.timezone).toISOString(),
        //     schedule_date: moment.tz(`${data.schedule_date}T00:00:00Z`, user.timezone).toISOString()
        // }
        const reformed = {
            start_time: plusTimezoneOffset(`${data?.schedule_date}T${data?.start_time}`, user?.timezone),
            end_time: plusTimezoneOffset(`${data?.schedule_date}T${data?.end_time}`, user?.timezone),
            schedule_date: plusTimezoneOffset(`${data?.schedule_date}T00:00:00`, user?.timezone)
        }


        console.log('reformed', reformed)

        if (cSched) {
            data = {...data, id: cSched?.id, ...reformed}
            await put('custom-schedules/' + cSched?.id , data).then((resp: any) => {
                console.log('update', resp)
                setCustomAvailability(resp)
            })
            return
        }
        await put('users/' + user?.id + '/add-custom-sched', {...data, ...reformed}).then((resp: any) => {
            console.log('add', resp)
            setCustomAvailability(resp)
        })
    }
    const [promptVisible, setPromptVisible] = useState(false)
    const CustomAvailabilityExistPrompt = () => {
        return (
            <Prompt
                title="Failed"
                message={<>Time is not available</>}
                visible={promptVisible}
                okButton="Ok"
                okFunction={() => {
                    setPromptVisible(false)
                }}
            />
        )
    }

    const [scheduledDateData, setScheduledDateData] = useState<any []>([])
    useEffect(() => {
        const scheduledDate = customAvailability.filter((availability: any) => {
            return moment(availability?.schedule_date).format('YYYY-MM-DD') === selectedDate
        })
        setScheduledDateData(scheduledDate)
    }, [customAvailability, selectedDate])

    const startTimeValidOptions = (endTime: any) => {
        if(scheduledDateData.length) {
            const validT = timeSelections.filter((timeSelection: any) => {
                let isValid = true
                scheduledDateData.forEach((dayV: any) => {
                    const start_time = moment(dayV.start_time).format('HH:mm:ss')
                    const end_time = moment(dayV.end_time).format('HH:mm:ss')
                    if (moment(ndHHMMss(timeSelection.value)).isBetween(ndHHMMss(start_time), ndHHMMss(end_time), null, '()') ||
                        (moment(ndHHMMss(start_time)).isSame(ndHHMMss(timeSelection.value)) && moment(ndHHMMss(end_time)).isSame(ndHHMMss(endTime))) ||
                        moment(ndHHMMss(start_time)).isBetween(ndHHMMss(timeSelection.value), ndHHMMss(endTime), null, '()') ||
                        moment(ndHHMMss(end_time)).isBetween(ndHHMMss(timeSelection.value), ndHHMMss(endTime), null, '()') ||
                        moment(ndHHMMss(start_time)).isSame(ndHHMMss(timeSelection.value)) ||
                        (timeSelection.value === '23:00:00')) {
                        isValid = false
                    }
                })
                return isValid && (endTime ? timeSelection.value < endTime : true)
            })
            return validT
        } else {
            return timeSelections
        }
    }

    const endTimeValidOptions = (startTime: any) => {
        if(scheduledDateData.length) {
            const validT = timeSelections.filter((timeSelection: any) => {
                let isValid = true
                scheduledDateData.forEach((dayV: any) => {
                    const start_time = moment(dayV.start_time).format('HH:mm:ss')
                    const end_time = moment(dayV.end_time).format('HH:mm:ss')
                    if (moment(ndHHMMss(timeSelection.value)).isBetween(ndHHMMss(start_time), ndHHMMss(end_time), null, '()') ||
                        (moment(ndHHMMss(start_time)).isSame(ndHHMMss(startTime)) && moment(ndHHMMss(end_time)).isSame(ndHHMMss(timeSelection.value))) ||
                        moment(ndHHMMss(start_time)).isBetween(ndHHMMss(startTime), ndHHMMss(timeSelection.value), null, '()') ||
                        moment(ndHHMMss(end_time)).isBetween(ndHHMMss(startTime), ndHHMMss(timeSelection.value), null, '()') ||
                        moment(ndHHMMss(end_time)).isSame(ndHHMMss(timeSelection.value)) ||
                        (timeSelection.value === '00:00:00')) {
                        isValid = false
                    }
                })
                return isValid && (startTime ? timeSelection.value > startTime : true)
            })
            return validT
        } else {
            return timeSelections
        }
    }

    return (
        <Form
            form={customAvailabilityForm}
            name="create-schedule-form"
            style={{width: '100%'}}
            layout={'vertical'}
            onFinish={(values) => {
                    addCustomSched({...values, schedule_date: values.schedule_date.format('YYYY-MM-DD') }).then(() => onSave())
                    customAvailabilityForm.resetFields()
            }}
        >
            <CustomAvailabilityExistPrompt/>
            <Title>{cSched ? 'Edit' : 'Add'} Custom Availability</Title>
            <Row>
                <InputLabel>Select Date</InputLabel>
            </Row>
            <Form.Item
                name={'schedule_date'}
                rules={[
                    {required: true, message: 'Date is required'},
                ]}
                style={{marginBottom: '10px', textAlign: 'left'}}
            >
                <DatePicker
                    allowClear={false}
                    disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                    style={{width: '100%', borderRadius: 10, height: 50}}
                    onChange={(value, str) => {
                        setSelectedDate(moment(value).format('YYYY-MM-DD'))
                    }}
                    format={'MMMM DD, YYYY'}
                    suffixIcon={<img src={CalendarIcon} alt="calendar" />}/>
            </Form.Item>
            <Row>
                <InputLabel>Notes</InputLabel>
            </Row>
            <Form.Item
                name={'notes'}
                style={{marginBottom: '10px', textAlign: 'left'}}
            >
                <InputText name={'notes'} autoComplete='off' placeholder="Notes"
                            style={{width: '100%'}}/>
            </Form.Item>
            <Row gutter={16}>
                <Col xs={{span: 12}}>
                    <Form.Item
                        rules={[
                            {required: true, message: 'Start is required'},
                        ]}
                        style={{marginBottom: '10px'}}
                    >
                        <InputLabel>Start Time</InputLabel>
                        <Form.Item
                            name="start_time"
                            noStyle
                            rules={[
                                {required: true, message: 'Start Time is required'}
                            ]}
                        >
                            <StyledSelect
                                disabled={!selectedDate}
                                placeholder={'8:00 am'}
                                options={scheduledDateData.length ? timeSelections.map((timeSelection) => {
                                    return ({...timeSelection, disabled: !startTimeValidOptions(endTime).some((validTime: any) => validTime.value === timeSelection.value)})
                                }) : timeSelections.map((timeSelection: any) => ({...timeSelection, disabled: endTime ? timeSelection.value >= endTime : false}))}
                                onChange={(value: any) => {
                                    setStartTime(value)
                                    if(HHMMss(cSched?.start_time_tz, user?.timezone) !== value && !editStartTime && !editEndTime){
                                        setEndTime('')
                                        customAvailabilityForm.setFieldValue('end_time', null)
                                        setEditStartTime(true)
                                    }
                                }}
                                style={{margin : '5px 0px'}}
                            />

                        </Form.Item>
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}}>
                    <Form.Item
                        name="end_time"
                        rules={[
                            {required: true, message: 'End Time is required'}
                        ]}
                        style={{marginBottom: '10px'}}
                    >
                        <InputLabel>End Time</InputLabel>
                        <Form.Item
                            name="end_time"
                            noStyle
                        >
                            <StyledSelect
                                disabled={!selectedDate}
                                placeholder={'5:00 pm'}
                                options={scheduledDateData.length ? timeSelections.map((timeSelection) => {
                                    return ({...timeSelection, disabled: !endTimeValidOptions(startTime).some((validTime: any) => validTime.value === timeSelection.value)})
                                }) : timeSelections.map((timeSelection: any) => ({...timeSelection, disabled: startTime ? timeSelection.value <= startTime : false}))}
                                onChange={(value: any) => {
                                    setEndTime(value)
                                    if(HHMMss(cSched?.end_time_tz, user?.timezone) !== value && !editStartTime && !editEndTime){
                                        setStartTime('')
                                        customAvailabilityForm.setFieldValue('start_time', null)
                                        setEditEndTime(true)
                                    }
                                }}
                                style={{margin : '5px 0px'}}
                            />
                        </Form.Item>
                    </Form.Item>
                </Col>
            </Row>
            <SubTitle fontSize={'12px'} style={{textAlign: 'left'}}><span style={{fontWeight: 500}}>Note:</span> The time slots that were selected are grayed out.</SubTitle>
            <Form.Item
                style={{margin: '10px 0'}}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Button
                            size={'large'}
                            style={{width: '100%', margin: 0}}
                            onClick={() => {
                                onCancel()
                                customAvailabilityForm.resetFields()
                            }}
                        >Cancel</Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            htmlType={'submit'}
                            type={'primary'}
                            size={'large'}
                            style={{width: '100%', margin: 0}}
                        >Save</Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    )
}


export default CustomAvailabilityForm

const StyledSelect = styled(Select)<{ isrounded?: boolean}>`
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
      height: 50px;
    }
  }

  .ant-select-selection-item {
    text-align: left;
    align-self: center;
  }

  .ant-select-selection-placeholder {
    text-align: left;
    align-self: center;
  }
`
