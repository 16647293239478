import APIService from 'src/services/APIService'
// import { useAuth } from 'src/hooks/useAuth'

const GroupService = () => {
    // const { currentUser, refreshUser } = useAuth()
    const { 
        // get, 
        put, 
        // remove 
    } = APIService()

    const removeUserFromGroup = async (uid: number, gid: number) => {
        await put('groups/' + gid + '/remove-user', {uid:uid})
            .then((resp: any) => {
            })
    }

    const addUserToGroup = async (uid: number, gid: number) => {

    }

    return {
        removeUserFromGroup,
        addUserToGroup
    }
}


export default GroupService 