import React, { Suspense, useContext, useEffect, useState } from 'react';
import { ThemeProvider } from "styled-components";
import useTheme from "./hooks/useTheme";
import { darkTheme, lightTheme } from './constants/theme-constants';
import { GlobalStyles } from './config/GlobalStyles';
import AppRouter from './services/AppRouter';
import { AuthContext } from 'src/context/AuthContext'
import { publicroutes } from './config/publicroutes';
import LayoutContainer from './containers/layout';
import Loader from './components/reusables/Loader';

function App() {
  const { isAuthenticated, currentUser, userIs} = useContext(AuthContext);
  const [theme] = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme === 'light' ? lightTheme : darkTheme);
  const getFaviconEl = () => {
    return document.getElementsByClassName("favicon");
  }

  useEffect(() => {
    const favicons: any = getFaviconEl();
    console.log({ favicons })
    for (const element of favicons) {
      element.href = userIs('super_admin') ? '/icons/favicon.ico' : (currentUser?.organization?.browser_icon?.url || '/icons/favicon.ico')
    }
    const tabtitle = document.getElementById("tabtitle");
    if(tabtitle) {
      tabtitle.innerHTML = currentUser?.role?.slug !== 'super_admin' ? currentUser?.organization?.browser_meta_name || 'Time Author' : 'Time Author'
    }
  }, [currentUser?.organization?.browser_icon?.url, currentUser?.organization?.browser_meta_name])

  useEffect(() => {
    setSelectedTheme(theme === 'light' ? lightTheme : darkTheme)
  }, [theme])

  return (
    <ThemeProvider theme={selectedTheme}>
      <>
        <GlobalStyles />
          <Suspense fallback={<Loader />}>
            {
              isAuthenticated ?
              <>
                <LayoutContainer />
              </>
               :
              <AppRouter routes={publicroutes} />
            }
          </Suspense>
      </>
    </ThemeProvider>
  );
}

export default App;
