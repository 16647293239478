
import React, { useState, useEffect } from 'react'
import {Col, Row, Form, 
    // DatePicker
} from 'antd'
import {InputLabel, SubTitle, Title} from 'src/components/CommonStyles'
// import {InputText} from "../reusables/TextInputs";
import styled from 'styled-components'
import Select from "../reusables/Select";
import Button from 'src/components/reusables/Button'
import {timeSelections} from "../../constants/datetime";
import {
    // HHMMss, 
    ndHHMMss} from 'src/helpers/datetime';
import APIService from 'src/services/APIService'
// import  {useOrg} from 'src/hooks/useOrgContext'
import moment from 'moment'


const TimeAvailability = ({onCancel, onSave, schedule, day, user, slot}:
    {onCancel: () => void, onSave: () => void, schedule: any, day: string, user: any, slot: any}) => {
    const [timeAvailabilityForm] = Form.useForm();
    const [validTimes, setValidTimes] = useState<any[]>([])

    const { put } = APIService()

    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [editStartTime, setEditStartTime] = useState(false)
    const [editEndTime, setEditEndTime] = useState(false)

    useEffect(() => {
        if(slot){
            const dayValids = schedule.meta.filter((dayM: any) => {
                return day === dayM.day
            })

            if(dayValids.length) {
                const validT = timeSelections.filter((ts: any) => {
                    let isValid = true
                    dayValids.forEach((dayV: any) => {
                        if (moment(ndHHMMss(ts.value)).isBetween(ndHHMMss(dayV.start), ndHHMMss(dayV.end), null, '[]') &&
                            !moment(ndHHMMss(ts.value)).isBetween(ndHHMMss(slot.start), ndHHMMss(slot.end), null, '[]') &&
                            (!moment(ndHHMMss(dayV.start)).isSame(ndHHMMss(ts.value))) &&
                            (!moment(ndHHMMss(dayV.end)).isSame(ndHHMMss(ts.value)))) {
                            isValid = false
                        }
                    })

                    return isValid
                })
                setValidTimes(validT)
            } else {
                setValidTimes(timeSelections)
            }
        }else {

            const dayValids = schedule.meta.filter((dayM: any) => {
                return day === dayM.day
            })

            if(dayValids.length) {
                const validT = timeSelections.filter((ts: any) => {
                    let isValid = true
                    dayValids.forEach((dayV: any) => {
                        if (moment(ndHHMMss(ts.value)).isBetween(ndHHMMss(dayV.start), ndHHMMss(dayV.end), null, '[]') &&
                            (!moment(ndHHMMss(dayV.start)).isSame(ndHHMMss(ts.value))) &&
                            (!moment(ndHHMMss(dayV.end)).isSame(ndHHMMss(ts.value)))) {
                            isValid = false
                        }
                        console.log(isValid)
                    })

                    return isValid
                })
                setValidTimes(validT)
                console.log('validTimes', validT)
            } else {
                setValidTimes(timeSelections)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedule])

    useEffect(() => {
        if (slot) {
            timeAvailabilityForm.setFieldValue('start_time', slot.start)
            timeAvailabilityForm.setFieldValue('end_time', slot.end)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slot])

    const startTimeValidOptions = (endTime: any) => {
        return validTimes.filter((timeSelection: any) => {
            let dayValids = schedule.meta.filter((dayM: any) => {
                return day === dayM.day
            })
            let isValid = true
            dayValids = dayValids.filter((dayV: { start: any; end: any; }) => slot.start !== dayV.start && slot.end !== dayV.end)
            if(dayValids.length){
                dayValids.forEach((dayV: any) => {
                    if ((moment(ndHHMMss(dayV.start)).isBetween(ndHHMMss(timeSelection.value), ndHHMMss(endTime), null, '[]') &&
                        moment(ndHHMMss(dayV.end)).isBetween(ndHHMMss(timeSelection.value), ndHHMMss(endTime), null, '[]')) ||
                        moment(ndHHMMss(dayV.start)).isSame(ndHHMMss(timeSelection.value)) ||
                        (timeSelection.value === '23:00:00')
                    ) {
                        isValid = false
                    }
                })
            }
            return isValid && (endTime ? timeSelection.value < endTime : true)
        })
    }

    const endTimeValidOptions = (startTime: any) => {
        return validTimes.filter((timeSelection: any) => {
            let dayValids = schedule.meta.filter((dayM: any) => {
                return day === dayM.day
            })
            let isValid = true
            dayValids = dayValids.filter((dayV: { start: any; end: any; }) => slot.start !== dayV.start && slot.end !== dayV.end)
            if(dayValids.length){
                dayValids.forEach((dayV: any) => {
                    if ((moment(ndHHMMss(dayV.start)).isBetween(ndHHMMss(startTime), ndHHMMss(timeSelection.value), null, '[]') &&
                        moment(ndHHMMss(dayV.end)).isBetween(ndHHMMss(startTime), ndHHMMss(timeSelection.value), null, '[]')) ||
                        moment(ndHHMMss(dayV.end)).isSame(ndHHMMss(timeSelection.value)) ||
                        (timeSelection.value === '00:00:00')) {
                        isValid = false
                    }
                })
            }
            return isValid && timeSelection.value > startTime
        })
    }

    return (
        <Form
            form={timeAvailabilityForm}
            name="create-schedule-form"
            style={{width: '100%'}}
            layout={'vertical'}
            initialValues={slot ? {
                'start_time': slot.start,
                'end_time': slot.end
            } : {}}
            onFinish={(values) => {
                console.log(values)
                if (slot) { // is for edit
                    const mappedMeta = schedule.meta.map((met: any) => {
                        if(met.day === slot.day && met.start === slot.start && met.end === slot.end) {
                            return {
                                day: slot.day,
                                //start: ndHHMMss(values.start_time),
                                //end: ndHHMMss(values.end_time)
                                start: values.start_time,
                                end: values.end_time
                            }
                        }
                        return met
                    })
                    put('users/' + user.id + '/schedules', {...schedule, meta: mappedMeta})
                        .then(() => onSave())

                } else {
                    const newSchedMeta =
                        [...schedule.meta,
                            { day: day, start: values.start_time || '08:00:00', end: values.end_time || '17:00:00' }]
                    put('users/' + user.id + '/schedules', {...schedule, meta: newSchedMeta})
                        .then(() => onSave())
                }
                timeAvailabilityForm.resetFields()
            }}
        >
            <Title style={{marginBottom: 0}}>{ slot? 'Edit' : 'Add New' } Timeslot</Title>
            <Title style={{marginBottom: 0}}>{day.charAt(0).toUpperCase() + day.slice(1)}</Title>
            <Row justify={'end'}>
                <Button type={'link'} style={{height: 'auto', float: 'right', margin: 0, padding: 0, color: '#18B0CF'}} onClick={() => {
                    setStartTime('')
                    setEndTime('')
                    timeAvailabilityForm.setFieldsValue({start_time: null, end_time: null})
                }}>Clear</Button>
            </Row>
            <Row gutter={16}>
                <Col xs={{span: 12}}>
                    <Form.Item
                        rules={[
                            {required: true, message: 'Start is required'},
                        ]}
                        style={{marginBottom: '10px'}}
                    >
                        <InputLabel>Start Time</InputLabel>
                        <Form.Item
                            name="start_time"
                            noStyle
                            rules={[
                                {required: true, message: 'Start Time is required'}
                            ]}
                        >
                            <StyledSelect
                                placeholder={'8:00 am'}
                                onChange={(value: any) => {
                                    setStartTime(value)
                                    if(slot.start !== value && !editStartTime && !editEndTime){
                                        setEndTime('')
                                        timeAvailabilityForm.setFieldValue('end_time', null)
                                        setEditStartTime(true)
                                    }
                                }}
                                options={timeSelections.map((timeSelection) => {
                                    return ({...timeSelection, disabled: !startTimeValidOptions(endTime).some((startTimeValidOption: any) => startTimeValidOption.value === timeSelection.value)})
                                })}
                                style={{margin : '5px 0px'}}
                            />

                        </Form.Item>
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}}>
                    <Form.Item
                        rules={[
                            {required: true, message: 'End is required'},
                        ]}
                        style={{marginBottom: '10px'}}
                    >
                        <InputLabel>End Time</InputLabel>
                        <Form.Item
                            name="end_time"
                            noStyle
                            rules={[
                                {required: true, message: 'End Time is required'}
                            ]}
                        >
                            <StyledSelect
                                placeholder={'5:00 pm'}
                                onChange={(value: any) => {
                                    setEndTime(value)
                                    if(slot.end !== value && !editStartTime && !editEndTime){
                                        setStartTime('')
                                        timeAvailabilityForm.setFieldValue('start_time', null)
                                        setEditEndTime(true)
                                    }
                                }}
                                options={timeSelections.map((timeSelection) => {
                                    return ({...timeSelection, disabled: !endTimeValidOptions(startTime).some((endTimeValidOption: any) => endTimeValidOption.value === timeSelection.value)})
                                })}
                                style={{margin : '5px 0px'}}
                            />
                        </Form.Item>
                    </Form.Item>
                </Col>
            </Row>
            <SubTitle fontSize={'12px'} style={{textAlign: 'left'}}><span style={{fontWeight: 500}}>Note:</span> The time slots that were selected are grayed out.</SubTitle>
            <Form.Item
                style={{margin: '10px 0'}}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Button
                            size={'large'}
                            style={{width: '100%', margin: 0}}
                            onClick={() => {
                                onCancel()
                                timeAvailabilityForm.resetFields()
                            }}
                        >Cancel</Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            htmlType={'submit'}
                            type={'primary'}
                            size={'large'}
                            style={{width: '100%', margin: 0}}
                        >Save</Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    )
}


export default TimeAvailability

const StyledSelect = styled(Select)<{ isrounded?: boolean}>`
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
      height: 50px;
    }
  }

  .ant-select-selection-item {
    text-align: left;
    align-self: center;
  }

  .ant-select-selection-placeholder {
    text-align: left;
    align-self: center;
  }
`
