import styled from "styled-components"
import Select from "./reusables/Select";
import {Steps} from "antd";
import {InputText} from "./reusables/TextInputs";

export const Title = styled.h1<{textAlign?: string, fontWeight?: string, fontSize?: string, marginBottom?: string}>`
    font-size: ${({ fontSize }) => fontSize || '18px'};
    font-weight: ${({ fontWeight }) => fontWeight || '600'};
    margin-bottom: ${({ marginBottom }) => marginBottom || '15px'};
    text-align: ${({ textAlign }) => textAlign || 'center'};
    color: ${props => props.theme.text || '#000000'};
`

export const SubTitle = styled.h1<{textAlign?: string, fontSize?: string}>`
  font-size: ${({ fontSize }) => fontSize || '14px'};
    font-weight: 400;
    margin-bottom: 15px;
    text-align: ${({ textAlign }) => textAlign || 'center'};
    line-height: 18px;
`

export const Label = styled.h3<{textAlign?: string}>`
    font-size: 12px;
    margin: 0px 5px;
`


export const InputLabel = styled.h6`
  float: left;
  margin-left: 5px;
`
export const Center = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    .select-date-btn {
      font-size: 14px;
    }
  }
`

export const FormContainer = styled.div`
    min-width: 400px;
    padding: 30px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgb(0,0,0,0.5);
  
`

export const FooterWrapper = styled.div`
    min-width: 400px;
    text-align: center;
`

export const FormFooter = styled.div`
    text-align: center;
    margin: 20px 0 0;
`

export const LogoIcon = styled.img`
    height: 105px;
`

export const LogoWrapper = styled.div`
    text-align: center;
    margin-bottom: 20px;
`

export const LineStyled = styled.div`
    background: #e4e4e4;
    height: 1px;
    flex: 1;
`

export const LineStyledWrapper = styled.div`
    display: flex;
    margin: 20px auto;
    align-items: center;
    max-width: 400px;
`

export const StyledSelect = styled(Select)<{ isrounded?: boolean, color?: string, height?: string, minHeight?: string, bordered?: boolean, className?: string}>`
  margin: 5px 0px;

  &.timezones-select {
    width: calc(100% - 20px);
    input.ant-select-selection-search-input {
      width: 100%;
    }
  }
  
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: ${({ isrounded }) => isrounded ? '30px' : '5px'};
      height: ${({height}) => height || '50px'};
      min-height: ${({minHeight}) => minHeight || '50px'};
      ${({color}) => color ? `
      background-color: ${color};
      border-color: ${color};
      color: white;
      ` : ''}
    }
  }

  .ant-select-selection-item {
    text-align: left;
    align-self: center;
  }

  .ant-select-selection-placeholder {
    text-align: left;
    align-self: center;
    color: rgba(0,0,0,0.4);
        
        ${({color}) => color ? `
      color: white;
      ` : ''}
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }
  
  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0,0,0,1);
    background: #ffffff;
    cursor: not-allowed;
  }
`

export const StyledSteps = styled(Steps)`
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: #18B0CF;
    border-color: #18B0CF;
  }

  .ant-steps-item-icon {
    background-color: #D9D9D9;
    border-color: #D9D9D9;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    border-color: #18B0CF;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #18B0CF;
    background-color: #18B0CF;
    border-color: #18B0CF;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
    background-color: white;
  }
`

export const StepsContent = styled.div`
  min-height: 200px;
  margin-top: 16px;
  text-align: center;
  border-radius: 2px;
  height: 100%;
`

export const StepsAction = styled.div`
  margin-top: 10px;
`

export const StyledInputText = styled(InputText)<{ height?: string }>`
  .ant-input {
    height: ${({ height }) => height ? `${height}px` : '50px'};
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .ant-input-group-addon {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    background-color: rgba(24, 176, 207, 0.3);
    border-color: rgba(24, 176, 207, 0.3);
    font-size: 12px;
    height: ${({ height }) => height ? `${height}px` : '50px'};
    padding-top: 3px;
  }
`


export const ColorSwatchWrapper = styled.div`
  width: 60px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
`
export const ColorSwatch = styled.div`
  flex-basis: 30%;
  width: 15px;
  height: 15px;
  margin: 5px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }

  &.red {
    background-color: #D50000;
  }

  &.pink {
    background-color: #E67C73;
  }

  &.orange {
    background-color: #FF9900;
  }

  &.yellow {
    background-color: #F6BF26;
  }

  &.lightGreen {
    background-color: #33B679;
  }

  &.teal {
    background-color: #18B0CF;
  }

  &.green {
    background-color: #0B8043;
  }

  &.lightBlue {
    background-color: #039BE5;
  }

  &.blue {
    background-color: #3F51B5;
  }

  &.purple {
    background-color: #7986CB;
  }

  &.violet {
    background-color: #8E24AA;
  }

  &.grey {
    background-color: #363B45;
  }

  &.black {
    background-color: #000000;
  }
`
export const AdornIconContainer = styled.span`
  > svg {
    &:hover {
      cursor: pointer;
    }
  }
`
