import {Center} from "src/components/CommonStyles";
import SFillIcon from "../../assets/icons/SFillIcon.svg";
import MFillIcon from "../../assets/icons/MFillIcon.svg";
import TFillIcon from "../../assets/icons/TFillIcon.svg";
import WFillIcon from "../../assets/icons/WFillIcon.svg";
import FFillIcon from "../../assets/icons/FFillIcon.svg";
import SOutlineIcon from "../../assets/icons/SOutlineIcon.svg";
import MOutlineIcon from "../../assets/icons/MOutlineIcon.svg";
import TOutlineIcon from "../../assets/icons/TOutlineIcon.svg";
import WOutlineIcon from "../../assets/icons/WOutlineIcon.svg";
import FOutlineIcon from "../../assets/icons/FOutlineIcon.svg";
import {Row} from "antd";
import React, {useState} from "react";
import styled from "styled-components";
import { FaVihara } from "react-icons/fa";

let sDays: any = []

const SelectDays = (props: any) => {
    console.log('init SelectDays', props)
    const { initialDays, onChange } = props
    const [selectedWorkingDays, setSelectedWorkingDays] = useState<any>(initialDays || [])
    
    const days = [
        {
            label: 'Sunday',
            value: 'sunday',
            unselectedIcon: SOutlineIcon,
            selectedIcon: SFillIcon
        },
        {
            label: 'Monday',
            value: 'monday',
            unselectedIcon: MOutlineIcon,
            selectedIcon: MFillIcon
        },
        {
            label: 'Tuesday',
            value: 'tuesday',
            unselectedIcon: TOutlineIcon,
            selectedIcon: TFillIcon
        },
        {
            label: 'Wednesday',
            value: 'wednesday',
            unselectedIcon: WOutlineIcon,
            selectedIcon: WFillIcon
        },
        {
            label: 'Thursday',
            value: 'thursday',
            unselectedIcon: TOutlineIcon,
            selectedIcon: TFillIcon
        },
        {
            label: 'Friday',
            value: 'friday',
            unselectedIcon: FOutlineIcon,
            selectedIcon: FFillIcon
        },
        {
            label: 'Saturday',
            value: 'saturday',
            unselectedIcon: SOutlineIcon,
            selectedIcon: SFillIcon
        },
    ]

    const selectDay = (day:any) => {
        if (props.disabledDays.indexOf(day.value) === -1) {
            setSelectedWorkingDays((selectedWorkingDays: any) => {
                let ret:any = []
                if(selectedWorkingDays.includes(day.value))
                    ret = selectedWorkingDays.filter((workingDay: string) => workingDay !== day.value)
                else
                    ret = [...selectedWorkingDays, day.value]

                onChange(ret)
                return ret
            })            
        }
    }

    return (
      <Row style={{margin: 10}}>
          {days.map(day => (
              <Center key={day.value}>
                        <DayIcon onClick={() => selectDay(day)} className={props.disabledDays.indexOf(day.value) !== -1 ? 'disabled' : ''}>
                            {selectedWorkingDays.includes(day.value) ? <img src={day.selectedIcon} alt="day" className="day-img" /> : <img src={day.unselectedIcon} alt="day" className="day-img"  />}
                        </DayIcon>
                  <InputLabel>{day.label}</InputLabel>
              </Center>
          ))}
      </Row>
  )
}

const DayIcon = styled.span`
  cursor: pointer;


  &.disabled {
    cursor: not-allowed !important;
  }

  img.day-img {
    height: 25px;
  }
`
const InputLabel = styled.h6`
  float: left;
  margin-left: 5px;
  font-size: 12px;
`

export { SelectDays, sDays };

