import { useAuth } from 'src/hooks/useAuth'
import APIService from 'src/services/APIService'



const RoleService = () => {
    const { currentUser, refreshUser } = useAuth()
    const { get } = APIService()

    const getRoles = async () => {
        let roles: any[] = []
        await get('roles', [])
            .then((resp: any) => {
                roles = resp.data
            })
        return roles
    }

    const getOrgRoles = async () => {
        const org = currentUser.organization
        if (org) {
            let roles: any[] = []

            if (currentUser.org_roles) {
                return currentUser.org_roles
            }
            await get('organizations/' + org.id + '/roles', [])
                .then((resp: any) => {
                    roles = [...resp]
                    refreshUser({...currentUser, org_roles: roles})
                })
            return roles
        }
        return []
    }

    const checkModuleRoles = (module: string, permission: string|string[] = 'view') => {
        const currentUserRole = currentUser.role
        const modulePermissions = currentUserRole?.module_permissions?.find((mp: any) => mp.slug.toLowerCase() === module)
        if (modulePermissions) {
            if (typeof permission === 'string') {
                return modulePermissions[permission]
            }
            let allowed = false
            permission.forEach(perm => {
                // console.log('prem', modulePermissions[perm])
                allowed = allowed || modulePermissions[perm]
            })
            return allowed
        }

        // specials
        if (module === 'admin-management') {
            return (currentUser.organization?.onboarded_at && currentUser.role.slug !== 'member' && currentUser.role.slug !== 'super_admin') as boolean
        }

        if (module === 'super-admin-dashboard') {
                return currentUser?.role?.slug.toLowerCase() === 'super_admin'
        }
        return true
    }

    return {
        getRoles,
        checkModuleRoles,
        getOrgRoles
    }
}


export default RoleService
