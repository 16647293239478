import {SubTitle, Title} from "../CommonStyles";
import Button from "../reusables/Button";
import React, {useState, useEffect} from "react";
import {Col, Row, Space} from "antd";
import moment from "moment";
import momentTimezone from "moment-timezone";
import VisaLogo from 'src/assets/images/VisaLogo.png';
import {AiOutlineCheck} from "react-icons/ai";
import {BiReceipt} from "react-icons/bi";

import MyCalendarIcon from 'src/assets/sidebar-icons/my-calendar.svg';
import UsersIcon from 'src/assets/sidebar-icons/users.svg';
import GroupsIcon from 'src/assets/sidebar-icons/groups.svg';
import ServicesIcon from 'src/assets/sidebar-icons/services.svg';
import BookingFormIcon from 'src/assets/sidebar-icons/booking-form.svg';
import UpgradePlanIcon from 'src/assets/icons/UpgradePlanIcon.svg';

import {StyledSelect} from "src/components/CommonStyles";
import {Form} from "antd";
import {InputLabel} from "src/components/CommonStyles";
import {InputText} from "src/components/reusables/TextInputs";

import Table from "../reusables/Table";
// import ChoosePlan from "src/pages/super-admin/subscriptions/ChoosePlan";
import Modal from "../reusables/Modal";
import styled from "styled-components";
import OrgService from "src/services/OrganizationService";
import ApiService from "src/services/APIService";
import { pick, capitalize } from "lodash";

const Plan = ({onClose, organization = null, setRefresh, admin} : {
    onClose?: () => void
    organization?: any
    setRefresh?: any
    admin?: boolean
}) => {
    const [form] = Form.useForm();
    const { get } = ApiService()
    const { addPlan, getOrg } = OrgService(organization?.id)
    const [showPaymentDetails] = useState<boolean>(false)
    const [showPaymentHistory] = useState<boolean>(false)
    const [showActionButtons] = useState<boolean>(admin ? true : false)
    const [choosePlanVisible, setChoosePlanVisible] = useState(false)
    const [subscription, setSubscription] = useState<any>(null)
    const [addingPlan, setAddingPlan] = useState<boolean>(false)
    
    const upgradePlan = async (values: any) => {
        let input = {
            ...pick(values, ['period', 'remarks']),
            subscription_plan_id: values.subscription_plan
        }

        setAddingPlan(true)
        const plan = await addPlan(input)
        if (plan && plan.organization) {
            setAddingPlan(false)
            setRefresh(true)
        }
    }
    useEffect(() => {
        // Refresh Org by fetching from API for newly Onboarded Organization
        getOrg().then(__org => {
            // update the subscription if organization has no plans
            if (!subscription && __org && __org.plans?.length)
                setSubscription(__org.plans[0])
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!addingPlan) 
            setChoosePlanVisible(false)
    }, [addingPlan])

    useEffect(() => {
        if (organization && organization.plans?.length) 
            setSubscription(organization.plans[0])
    }, [organization])

    const [plans, setPlans] = useState<any>([])
    const fetchPlans = async () => {
        get('subscription-plans', { params: { preload: true }})
            .then((resp:any) => {
                if (resp?.length)
                    setPlans(resp?.map((p:any) => { return { label: p.name, value: p.id }}))
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        if (choosePlanVisible) {
            fetchPlans()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choosePlanVisible])

    const ScheduleColumns = [
        {
            title: 'Date of Payment',
            dataIndex: 'payment_date',
            key: 'payment_date',
            align: 'center'
        },
        {
            title: 'Plan',
            dataIndex: 'plan',
            key: 'plan',
            align: 'center'
        },
        {
            title: 'Payment Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center'
        },
        {
            title: 'View Invoice',
            key: 'action',
            align: 'center',
            render: (rec:any) => (
                <Space size="middle">
                    <BiReceipt/>
                </Space>
            ),
        },
    ]
    const data = [];
    for (let i = 1; i <= 100; i++) {
        data.push({
            key: i,
            payment_date: moment().format('MMM, dd yyyy hh:mm:ss'),
            plan: 'Plan',
            amount: `$10.00`,
        });
    }

    const ChooseSubscription = (
        <Form
            form={form}
            name="plan-form"
            style={{width: '100%', marginTop: 20}}
            initialValues={{}}
            layout={'vertical'}
            onFinish={upgradePlan}
        >
            <InputLabel>Choose Plan</InputLabel>
            <Form.Item
                name="subscription_plan"
                rules={[
                    {required: true, message: 'Plan is required'},
                ]}
                style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
            >
                <StyledSelect
                    placeholder={'Select a Plan'}
                    options={plans}
                    onChange={(e: any) => {}}
                />
            </Form.Item>
            <InputLabel>Period</InputLabel>
            <Form.Item
                name="period"
                rules={[
                    {required: true, message: 'Period is required'},
                ]}
                style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
            >
                <StyledSelect
                    placeholder={'Select Period'}
                    options={[{ label: 'Monthly', value: 'monthly'}, { label: 'Annually', value: 'annually'}]}
                    onChange={(e: any) => {}}
                />
            </Form.Item>
            <InputLabel>Remarks</InputLabel>
            <Form.Item
                name="remarks"
                style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
            >
                <InputText name="remarks" autoComplete='off' placeholder="Remarks" style={{width: '100%'}} maxLength={50}/>
            </Form.Item>
            <Form.Item
                noStyle
            >
                <Row gutter={16}>
                    <Col xs={{span: 12}}>
                        <Button size={'large'} style={{width: '100%', height: 50}} onClick={() => {
                            form.resetFields()
                            setChoosePlanVisible(false)
                        }}>Cancel</Button>
                    </Col>
                    <Col xs={{span: 12}}>
                        <Button type={'primary'} size={'large'} style={{width: '100%', height: 50}} onClick={() => {
                            form.validateFields().then(() => {
                                form.submit()
                            })
                        }} loading={addingPlan}>Save</Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    )

    return (
      <>
          <Modal visible={choosePlanVisible} width={'25vw'} onCancel={() => {setChoosePlanVisible(false)}}>
              { ChooseSubscription }
          </Modal>
          {
            showActionButtons && <>
                <div style={{height: 60, minHeight: 60, overflow: 'hidden'}}>
                    <Row>
                        <Col span={5}>
                            <Title fontWeight={'500'} fontSize={'14px'} style={{textAlign: 'left', marginTop: 6}}>Billing</Title>
                        </Col>
                        <Col flex={"auto"} style={{textAlign: 'right'}}>
                            <Button size={'large'} style={{margin: '0px 5px 5px'}} onClick={() => {
                                if(onClose){
                                    onClose()
                                }
                            }}>
                                Cancel Subscription
                            </Button>
                            <Button type={"primary"} size={'large'} style={{margin: '0px 5px 5px'}} onClick={() => {
                                setChoosePlanVisible(true)
                            }}>
                                Change Plan
                            </Button>
                        </Col>
                    </Row>
                </div>
            </>
          }

          {
            subscription ? 
            <div style={{padding: 0, overflowY: 'auto', overflowX: 'hidden'}}>
                <Row style={{marginBottom: 20}}>
                    <Col span={12}>
                        <SubTitle fontSize={'12px'} style={{textAlign: 'left', marginTop: 6}}>Current Plan</SubTitle>
                        <Title fontWeight={'500'} fontSize={'14px'} style={{textAlign: 'left', marginBottom: 5}}>{subscription?.name}</Title>
                        <Title fontWeight={'500'} fontSize={'12px'} style={{textAlign: 'left', marginBottom: 10}}>{subscription?.description}</Title>
                        <Title fontWeight={'600'} fontSize={'12px'} style={{textAlign: 'left', marginBottom: 0}}>Restrictions</Title>
                        <RestrictionLine>
                            <img src={UsersIcon} alt="users_icon" />
                            {
                                subscription?.max_users === 0
                                    ? 'Unlimited'
                                    : subscription?.max_users + ' max'
                            } users
                        </RestrictionLine>
                        <RestrictionLine>
                            <img src={GroupsIcon} alt="groups_icon" />
                            {
                                subscription?.max_groups === 0
                                    ? 'Unlimited'
                                    : subscription?.max_groups + ' max'
                            } groups
                        </RestrictionLine>
                        <RestrictionLine>
                            <img src={ServicesIcon} alt="services_icon" />
                            {
                                subscription?.max_services === 0
                                    ? 'Unlimited'
                                    : subscription?.max_services + ' max'
                            } services
                        </RestrictionLine>
                        <RestrictionLine>
                            <img src={BookingFormIcon} alt="booking_form_icon" style={{ marginRight: 12, marginLeft: 7 }} />
                            {
                                subscription?.max_booking_forms === 0
                                    ? 'Unlimited'
                                    : subscription?.max_booking_forms + ' max'
                            } booking forms
                        </RestrictionLine>
                        <RestrictionLine>
                            <img src={MyCalendarIcon} alt="my_calendar_icon" />
                            {
                                subscription?.calendars_per_user === 0
                                    ? 'Unlimited calendar connections'
                                    : subscription?.calendars_per_user + ' calendar connections per user'
                            }
                        </RestrictionLine>
                    </Col>
                    <Col span={12}>
                        <SubTitle fontSize={'12px'} style={{textAlign: 'left', marginTop: 6}}>Billing Period</SubTitle>
                        <Title fontWeight={'500'} fontSize={'14px'} style={{textAlign: 'left', marginBottom: 5}}>{ capitalize(subscription?.meta?.pivot_meta?.period || subscription?.billing_period) }</Title>
                        <Title fontWeight={'500'} fontSize={'12px'} style={{textAlign: 'left', marginBottom: 0}}>
                        {
                            momentTimezone(subscription?.meta?.pivot_meta?.upgraded_at).format('DD MMM YYYY')
                        } to {
                            momentTimezone(subscription?.meta?.pivot_meta?.upgraded_at)
                                .add(1, (subscription?.meta?.pivot_meta?.period === 'monthly' ? 'month' : 'year'))
                                .format('DD MMM YYYY')
                        }
                        </Title>
                        <Title fontWeight={'500'} fontSize={'12px'} style={{textAlign: 'left', marginBottom: 30}}>Next payment on {
                            momentTimezone(subscription?.meta?.pivot_meta?.upgraded_at)
                                .add(1, (subscription?.meta?.pivot_meta?.period === 'monthly' ? 'month' : 'year'))
                                .add(1, 'day')
                                .format('DD MMM YYYY')
                        }</Title>

                        {
                        showPaymentDetails && <>
                            <SubTitle fontSize={'12px'} style={{textAlign: 'left', marginTop: 6}}>Payment Details</SubTitle>
                            <Space>
                                <img src={VisaLogo} alt="visa-logo" />
                                <Title fontSize={'16px'} style={{margin: '0px 10px 0px 0px'}}>**** **** **** 4141</Title>
                                <Button type={"primary"} style={{margin: '0px 5px 5px'}} onClick={() => {}}>Change</Button>
                            </Space>
                        </>
                        }
                    </Col>
                </Row>
                <Row style={{marginBottom: 20}}>
                    <Col span={12}>
                        <Title fontWeight={'600'} fontSize={'12px'} style={{textAlign: 'left', marginBottom: 0}}>Features</Title>
                        {
                            subscription && subscription.restrictions?.map((feature: any) => 
                                feature.checked && <Title fontWeight={'500'} fontSize={'12px'} style={{textAlign: 'left', margin: '0px 5px', display: 'flex', alignItems: 'center'}}><AiOutlineCheck style={{color: '#0061FF', marginRight: 10}}/> {feature.label}</Title>)
                        }
                    </Col>
                </Row>
                {
                    showPaymentHistory && <>
                        <Title fontWeight={'500'} fontSize={'14px'} style={{textAlign: 'left', marginTop: 6}}>Payment History</Title>
                        <div style={{marginTop: 15}}>
                            <Table hideOnSinglePage={true} bordered={false} columns={ScheduleColumns} dataSource={data} style={{
                                padding: 0,
                                borderRadius: 5
                            }}/>
                        </div>
                    </>
                }
            </div>
            : admin && <UpgradePlanSection>
                {/* <Button type={"primary"} style={{margin: 'auto'}} loading={addingPlan} onClick={upgradePlan}>Upgrade Plan</Button> */}
                <img src={UpgradePlanIcon} alt="upgrade_icon" onClick={() => setChoosePlanVisible(true)} />
                <Button type={"primary"} style={{margin: 'auto'}} onClick={() => setChoosePlanVisible(true)}>Upgrade Plan</Button>
            </UpgradePlanSection>
          }
      </>
  )
}

const RestrictionLine = styled(Title)`
    text-align: left;
    margin: 5px 5px 8px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;

    > img {
        margin-right: 10px;
        margin-left: 5px;
        margin-top: -2px;
    }
`

const UpgradePlanSection = styled.div`
    margin: auto;
    align-items: center;

    > img {
        width: 100px;
        display: block;
        padding-left: 20px;

        &:hover {
            cursor: pointer;
        }
    }
`

export default Plan;
