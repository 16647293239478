
import moment from 'moment'
import momentTimezone from 'moment-timezone'

export const yyyyMMdd = (str: any) => {
    return moment(str).format('YYYY-MM-DD');
}

export const HHmm = (str: any, tz = 'UTC') => {
    return moment.tz(str, tz).format('hh:mm a');
}

export const DDHHmm = (str: any, tz = 'UTC') => {
    return moment.tz(str, tz).format('YYYY-MM-DD hh:mm a');
}

export const HHMMss = (str: any, tz = 'UTC') => {
    return moment.tz(str, tz).format('HH:mm:ss');
}

export const ndHHMMss = (str: any, tz = 'UTC') => {
    return moment.tz('1970-01-01T' + str + 'Z', tz).toISOString();
}

export const ndHHmmaNoTZ = (str: string) => {
    return moment('1970-01-01T' + str).format('hh:mm a');
}

export const plusTimezoneOffset = (date_string: any, timezone: string) => {
    return date_string+momentTimezone(new Date()).tz(timezone).format('Z')
}