import React, {createContext, useState} from "react";

interface superAdminDataStruct {
    selectedOrganization: any,
    setSelectedOrganization: React.Dispatch<React.SetStateAction<any>>,
    showEditOrg: boolean,
    setShowEditOrg: React.Dispatch<React.SetStateAction<boolean>>,
    canUpdate: boolean,
    setCanUpdate: React.Dispatch<React.SetStateAction<boolean>>,
    selectedIndividual: any,
    setSelectedIndividual: React.Dispatch<React.SetStateAction<any>>,
}
const superAdminData = {
    selectedOrganization: {},
    setSelectedOrganization: (d: any) => {},
    showEditOrg: false,
    setShowEditOrg: (d: boolean) => {},
    canUpdate: true,
    setCanUpdate: (d: boolean) => {},
    selectedIndividual: {},
    setSelectedIndividual: (d: boolean) => {},
} as superAdminDataStruct

const SuperAdminContext = createContext(superAdminData);

const SuperAdminProvider = (props: any) => {
    const [selectedIndividual, setSelectedIndividual] = useState<any>()
    const [selectedOrganization, setSelectedOrganization] = useState<any>()
    const [showEditOrg, setShowEditOrg] = useState<boolean>(false)
    const [canUpdate, setCanUpdate] = useState<boolean>(true)
    return <SuperAdminContext.Provider value={
        {
            selectedOrganization,
            setSelectedOrganization,
            showEditOrg,
            setShowEditOrg,
            canUpdate,
            setCanUpdate,
            selectedIndividual,
            setSelectedIndividual
        }
    }>{props.children}</SuperAdminContext.Provider>;
}

const Consumer = SuperAdminContext.Consumer
export { SuperAdminContext, SuperAdminProvider, Consumer };
