//https://www.globalcallforwarding.com/international-call-prefixes/
export const phoneNumberLengths =  [
    {country: 'Afghanistan', code: '+93', nsn: 9},
    {country: 'Albania', code: '+355', nsn: 9},
    {country: 'Algeria', code: '+213', nsn: 9},
    {country: 'American Samoa', code: '+1684', nsn: 7},
    {country: 'Andorra', code: '+376', nsn: 9},
    {country: 'Angola', code: '+244', nsn: 9},
    {country: 'Anguilla', code: '+1264', nsn: 7},
    {country: 'Antigua and Barbuda', code: '+1268', nsn: 7},
    {country: 'Argentina', code: '+54', nsn: 10},
    {country: 'Armenia', code: '+374', nsn: 8},
    {country: 'Aruba', code: '+297', nsn: 7},
    {country: 'Ascension', code: '+247', nsn: 4},
    {country: 'Australia', code: '+61', nsn: 15},
    {country: 'Australian Capital Territory', code: '+672', nsn: 6},
    {country: 'Austria', code: '+43', nsn: 13},
    {country: 'Azerbaijan', code: '+994', nsn: 9},
    {country: 'Bahamas', code: '+1242', nsn: 7},
    {country: 'Bahrain', code: '+973', nsn: 8},
    {country: 'Bangladesh', code: '+880', nsn: 10},
    {country: 'Barbados', code: '+1246', nsn: 7},
    {country: 'Belarus', code: '+375', nsn: 10},
    {country: 'Belgium', code: '+32', nsn: 9},
    {country: 'Belize', code: '+501', nsn: 7},
    {country: 'Benin', code: '+229', nsn: 8},
    {country: 'Bermuda', code: '+1441', nsn: 7},
    {country: 'Bhutan', code: '+975', nsn: 8},
    {country: 'Bolivia', code: '+591', nsn: 8},
    {country: 'Bosnia and Herzegovina', code: '+387', nsn: 8},
    {country: 'Botswana', code: '+267', nsn: 8},
    {country: 'Brazil', code: '+55', nsn: 10},
    {country: 'British Virgin Islands', code: '+1284', nsn: 7},
    {country: 'Brunei', code: '+673', nsn: 7},
    {country: 'Bulgaria', code: '+359', nsn: 9},
    {country: 'Burkina Faso', code: '+226', nsn: 8},
    {country: 'Burma', code: '+95', nsn: 9},
    {country: 'Burundi', code: '+257', nsn: 8},
    {country: 'Cambodia', code: '+855', nsn: 8},
    {country: 'Cameroon', code: '+237', nsn: 8},
    {country: 'Canada', code: '+1', nsn: 10},
    {country: 'Cape Verde', code: '+238', nsn: 7},
    {country: 'Cayman Islands', code: '+1345', nsn: 7},
    {country: 'Central African Republic', code: '+236', nsn: 8},
    {country: 'Chad', code: '+235', nsn: 7},
    {country: 'Chile', code: '+56', nsn: 9},
    {country: 'China', code: '+86', nsn: 12},
    {country: 'Colombia', code: '+57', nsn: 10},
    {country: 'Comoros', code: '+269', nsn: 7},
    {country: 'Congo, Democratic Republic of the', code: '+243', nsn: 9},
    {country: 'Congo, Republic of the', code: '+242', nsn: 7},
    {country: 'Cook Islands', code: '+682', nsn: 5},
    {country: 'Costa Rica', code: '+506', nsn: 8},
    {country: 'Côte d’Ivoire', code: '+225', nsn: 8},
    {country: 'Croatia', code: '+385', nsn: 12},
    {country: 'Cuba', code: '+53', nsn: 8},
    {country: 'Cyprus', code: '+357', nsn: 11},
    {country: 'Czech Republic', code: '+420', nsn: 12},
    {country: 'Denmark', code: '+45', nsn: 8},
    {country: 'Diego Garcia', code: '+246', nsn: 7},
    {country: 'Djibouti', code: '+253', nsn: 6},
    {country: 'Dominica', code: '+767', nsn: 7},
    {country: 'Ecuador', code: '+593', nsn: 8},
    {country: 'Egypt', code: '+20', nsn: 9},
    {country: 'El Salvador', code: '+503', nsn: 11},
    {country: 'Equatorial Guinea', code: '+240', nsn: 6},
    {country: 'Eritrea', code: '+291', nsn: 7},
    {country: 'Estonia', code: '+372', nsn: 10},
    {country: 'Ethiopia', code: '+251', nsn: 9},
    {country: 'Falkland Islands', code: '+500', nsn: 5},
    {country: 'Faroe Islands', code: '+298', nsn: 6},
    {country: 'Fiji', code: '+679', nsn: 7},
    {country: 'Finland', code: '+358', nsn: 12},
    {country: 'France', code: '+33', nsn: 9},
    {country: 'French Departments and Territories in the Indian Ocean', code: '+262', nsn: 9},
    {country: 'French Guiana', code: '+594', nsn: 9},
    {country: 'French Polynesia', code: '+689', nsn: 6},
    {country: 'Gabon', code: '+241', nsn: 7},
    {country: 'Gambia', code: '+220', nsn: 7},
    {country: 'Georgia', code: '+995', nsn: 8},
    {country: 'Germany', code: '+49', nsn: 13},
    {country: 'Ghana', code: '+233', nsn: 9},
    {country: 'Gibraltar', code: '+350', nsn: 8},
    {country: 'Greece', code: '+30', nsn: 10},
    {country: 'Greenland', code: '+299', nsn: 6},
    {country: 'Grenada', code: '+1473', nsn: 7},
    {country: 'Guadeloupe', code: '+590', nsn: 9},
    {country: 'Guam', code: '+1', nsn: 7},
    {country: 'Guatemala', code: '+502', nsn: 8},
    {country: 'Guinea', code: '+224', nsn: 8},
    {country: 'Guinea-Bissau', code: '+245', nsn: 7},
    {country: 'Guyana', code: '+592', nsn: 7},
    {country: 'Haiti', code: '+509', nsn: 8},
    {country: 'Holy See', code: '+39', nsn: 11},
    {country: 'Honduras', code: '+504', nsn: 8},
    {country: 'Hong Kong', code: '+852', nsn: 9},
    {country: 'Hungary', code: '+36', nsn: 9},
    {country: 'Iceland', code: '+354', nsn: 9},
    {country: 'India', code: '+91', nsn: 10},
    {country: 'Indonesia', code: '+62', nsn: 10},
    {country: 'Iran', code: '+98', nsn: 10},
    {country: 'Iraq', code: '+964', nsn: 10},
    {country: 'Ireland', code: '+353', nsn: 11},
    {country: 'Israel', code: '+972', nsn: 9},
    {country: 'Italy', code: '+39', nsn: 11},
    {country: 'Jamaica', code: '+876', nsn: 7},
    {country: 'Japan', code: '+81', nsn: 10},
    {country: 'Jordan', code: '+962', nsn: 9},
    {country: 'Kazakhstan', code: '+7', nsn: 10},
    {country: 'Kenya', code: '+254', nsn: 10},
    {country: 'Kiribati', code: '+686', nsn: 5},
    {country: 'Korea, North', code: '+850', nsn: 10},
    {country: 'Korea, South', code: '+82', nsn: 11},
    {country: 'Kuwait', code: '+965', nsn: 8},
    {country: 'Kyrgyzstan', code: '+996', nsn: 9},
    {country: 'Laos', code: '+856', nsn: 9},
    {country: 'Latvia', code: '+371', nsn: 8},
    {country: 'Lebanon', code: '+961', nsn: 8},
    {country: 'Lesotho', code: '+266', nsn: 8},
    {country: 'Liberia', code: '+231', nsn: 8},
    {country: 'Libya', code: '+218', nsn: 9},
    {country: 'Liechtenstein', code: '+423', nsn: 9},
    {country: 'Lithuania', code: '+370', nsn: 8},
    {country: 'Luxembourg', code: '+352', nsn: 11},
    {country: 'Macau', code: '+853', nsn: 8},
    {country: 'Macedonia', code: '+389', nsn: 8},
    {country: 'Madagascar', code: '+261', nsn: 10},
    {country: 'Malawi', code: '+265', nsn: 8},
    {country: 'Malaysia', code: '+60', nsn: 9},
    {country: 'Maldives', code: '+960', nsn: 7},
    {country: 'Mali', code: '+223', nsn: 8},
    {country: 'Malta', code: '+356', nsn: 8},
    {country: 'Marshall Islands', code: '+692', nsn: 7},
    {country: 'Martinique', code: '+596', nsn: 9},
    {country: 'Mauritania', code: '+222', nsn: 7},
    {country: 'Mauritius', code: '+230', nsn: 7},
    {country: 'Mexico', code: '+52', nsn: 10},
    {country: 'Micronesia', code: '+691', nsn: 7},
    {country: 'Moldova', code: '+373', nsn: 8},
    {country: 'Monaco', code: '+377', nsn: 9},
    {country: 'Mongolia', code: '+976', nsn: 8},
    {country: 'Montenegro', code: '+382', nsn: 12},
    {country: 'Montserrat', code: '+1664', nsn: 7},
    {country: 'Morocco', code: '+212', nsn: 9},
    {country: 'Mozambique', code: '+258', nsn: 9},
    {country: 'Namibia', code: '+264', nsn: 10},
    {country: 'Nauru', code: '+674', nsn: 7},
    {country: 'Nepal', code: '+977', nsn: 9},
    {country: 'Netherlands', code: '+31', nsn: 9},
    {country: 'Netherlands Antilles', code: '+599', nsn: 8},
    {country: 'New Caledonia', code: '+687', nsn: 6},
    {country: 'New Zealand', code: '+64', nsn: 10},
    {country: 'Nicaragua', code: '+505', nsn: 8},
    {country: 'Niger', code: '+227', nsn: 8},
    {country: 'Nigeria', code: '+234', nsn: 10},
    {country: 'Niue', code: '+683', nsn: 4},
    {country: 'Northern Territory', code: '+1 670', nsn: 7},
    {country: 'Norway', code: '+47', nsn: 8},
    {country: 'Oman', code: '+968', nsn: 8},
    {country: 'Pakistan', code: '+92', nsn: 11},
    {country: 'Palau', code: '+680', nsn: 7},
    {country: 'Panama', code: '+507', nsn: 8},
    {country: 'Papua New Guinea', code: '+675', nsn: 11},
    {country: 'Paraguay', code: '+595', nsn: 9},
    {country: 'Peru', code: '+51', nsn: 11},
    {country: 'Philippines', code: '+63', nsn: 10},
    {country: 'Poland', code: '+48', nsn: 9},
    {country: 'Portugal', code: '+351', nsn: 11},
    {country: 'Puerto Rico', code: '+1', nsn: 7},
    {country: 'Qatar', code: '+974', nsn: 10},
    {country: 'Romania', code: '+40', nsn: 9},
    {country: 'Russia', code: '+7', nsn: 10},
    {country: 'Rwanda', code: '+250', nsn: 9},
    {country: 'Saint Helena', code: '+290', nsn: 4},
    {country: 'Saint Kitts and Nevis', code: '+1869', nsn: 7},
    {country: 'Saint Lucia', code: '+1758', nsn: 7},
    {country: 'Saint Pierre and Miquelon', code: '+508', nsn: 6},
    {country: 'Saint Vincent and the Grenadines', code: '+1784', nsn: 7},
    {country: 'Samoa', code: '+685', nsn: 7},
    {country: 'San Marino', code: '+378', nsn: 10},
    {country: 'Sao Tome and Principe', code: '+239', nsn: 7},
    {country: 'Saudi Arabia', code: '+966', nsn: 9},
    {country: 'Senegal', code: '+221', nsn: 9},
    {country: 'Serbia', code: '+381', nsn: 12},
    {country: 'Seychelles', code: '+248', nsn: 6},
    {country: 'Sierra Leone', code: '+232', nsn: 8},
    {country: 'Singapore', code: '+65', nsn: 12},
    {country: 'Slovakia', code: '+421', nsn: 9},
    {country: 'Slovenia', code: '+386', nsn: 8},
    {country: 'Solomon Islands', code: '+677', nsn: 5},
    {country: 'Somalia', code: '+252', nsn: 8},
    {country: 'South Africa', code: '+27', nsn: 9},
    {country: 'Spain', code: '+34', nsn: 9},
    {country: 'Sri Lanka', code: '+94', nsn: 9},
    {country: 'Sudan', code: '+249', nsn: 9},
    {country: 'Suriname', code: '+597', nsn: 7},
    {country: 'Swaziland', code: '+268', nsn: 8},
    {country: 'Sweden', code: '+46', nsn: 13},
    {country: 'Switzerland', code: '+41', nsn: 12},
    {country: 'Syria', code: '+963', nsn: 10},
    {country: 'Taiwan', code: '+886', nsn: 9},
    {country: 'Tajikistan', code: '+992', nsn: 9},
    {country: 'Tanzania', code: '+255', nsn: 9},
    {country: 'Thailand', code: '+66', nsn: 9},
    {country: 'Timor-Leste', code: '+670', nsn: 7},
    {country: 'Togo', code: '+228', nsn: 7},
    {country: 'Tokelau', code: '+690', nsn: 4},
    {country: 'Tonga', code: '+676', nsn: 7},
    {country: 'Trinidad and Tobago', code: '+868', nsn: 7},
    {country: 'Tristan da Cunha', code: '+290', nsn: 4},
    {country: 'Tunisia', code: '+216', nsn: 8},
    {country: 'Turkey', code: '+90', nsn: 10},
    {country: 'Turkmenistan', code: '+993', nsn: 8},
    {country: 'Turks and Caicos Islands', code: '+1 649', nsn: 7},
    {country: 'Tuvalu', code: '+688', nsn: 6},
    {country: 'Uganda', code: '+256', nsn: 9},
    {country: 'Ukraine', code: '+380', nsn: 9},
    {country: 'United Arab Emirates', code: '+971', nsn: 9},
    {country: 'United Kingdom', code: '+44', nsn: 10},
    {country: 'United States', code: '+1', nsn: 10},
    {country: 'Uruguay', code: '+598', nsn: 11},
    {country: 'Uzbekistan', code: '+998', nsn: 9},
    {country: 'Vanuatu', code: '+678', nsn: 7},
    {country: 'Venezuela', code: '+58', nsn: 10},
    {country: 'Vietnam', code: '+84', nsn: 10},
    {country: 'Virgin Islands', code: '+1340', nsn: 7},
    {country: 'Wallis and Futuna', code: '+681', nsn: 6},
    {country: 'Yemen', code: '+967', nsn: 9},
    {country: 'Zambia', code: '+260', nsn: 9},
    {country: 'Zimbabwe', code: '+263', nsn: 9},
]
