import React, { useState, useEffect } from "react";
import Layout, { Sider, Header, Content, Menu } from "src/components/reusables/Layout";
import type { MenuProps } from 'antd';
import {Avatar, Dropdown, Tooltip} from 'antd';
import Prompt from "src/components/reusables/Prompt";

import {Link, matchPath, useLocation, useNavigate} from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

// import NotificationBellIcon from 'src/assets/icons/Bell.svg';

import Button from "src/components/reusables/Button";
import ProfileDropdown from "src/components/main/ProfileDropdown";
import UsersWhiteIcon from "src/assets/icons/UsersWhiteIcon.svg";
import { privateroutes } from "src/config/privateroutes";
import AppRouter from "src/services/AppRouter";
import styled from "styled-components";

import RoleService from 'src/services/RoleService'

import { useSearchParams } from 'react-router-dom'

import { useAuth } from 'src/hooks/useAuth'
import UserProfile from "src/components/main/UserProfile";
import {useOrg} from "src/hooks/useOrgContext";
import hostname from "src/utils/hostname";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

// add inside privateroutes with parentId, sidebar: true, sidebarVisible: true to its parent by id
const parentRoutes = privateroutes.map(route => {
    if (route.parent) {
        return {
            ...route,
            child: privateroutes.filter(child => child.parentId === route.id)
        }
    }
    return route;
})
.filter(route => route && route.sidebar && route.sidebarVisible && !route.parentId);

const items: MenuItem[] = parentRoutes.map((route: any) => {
    if (route.parent) {
        return getItem(
            route.sidebarLabel,
            route.sidebarLabel.split(' ').join('-').toLowerCase(),
            route.icon ? <img src={route.icon} alt={route.sidebarLabel}/> : null,
            route.child.map((child: any) => {
                const childPath = child.path.split('/')
                return getItem(
                    <Link to={child?.path || ''}>{child.sidebarLabel}</Link>, child?.sidebarVisible ? childPath?.[childPath.length - 1]?.toLowerCase() : childPath?.[1]?.toLowerCase(), child.icon ? <img src={child.icon} alt={child.sidebarLabel}/> : null,
                )
            })
        )
    }
    return getItem(
        <Link to={route?.path || ''}>{route.sidebarLabel}</Link>, route.path.split('/')?.[1].toLowerCase(), route.icon ? <img src={route.icon} alt={route.sidebarLabel}/> : null,
    )
})


const LayoutContainer = () => {
    let location = useLocation()
    const { checkModuleRoles } = RoleService()
    const {currentSelectedUser} = useOrg()
    const navigate = useNavigate()

    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const [searchParams] = useSearchParams(); //, setSearchParams

    const private_routes_sidebar = privateroutes.filter((route) => route.sidebar && !!!route.parent)
    const private_routes_not_sidebar = privateroutes.filter((route) => !!!route.sidebar)
    const private_routes_not_sidebar_path_array = private_routes_not_sidebar.map((route) => route.path)

    const { currentUser, globalPrompt } = useAuth()
    const {openProfile, setOpenProfile} = useOrg()

    useEffect(() => {
        if (searchParams.get('profile') === 'new' || !currentUser?.profile?.first_name || !currentUser?.profile?.last_name) {
            setOpenProfile({visible: true, tab: 'profile'})
            navigate('/?profile=new', { replace: true })
        }

        console.log('the hostname:', hostname())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        document.addEventListener('mouseup', function(e: any) {
            const container = document.getElementById('profileDropdown');
            if (!container?.contains(e.target)) {
                setShowProfileDropdown(false)
            }
        });
    }, [])

    if(private_routes_not_sidebar_path_array.includes(location.pathname)) {
        return <AppRouter routes={privateroutes} />
    }

    const current_route = private_routes_sidebar.find((route) => !!matchPath(route?.path || '', location.pathname))
    const activePath = current_route?.path?.split('/')
    const current_route_active = `${(current_route?.sidebarVisible ? activePath?.[activePath?.length - 1]?.toLowerCase() : activePath?.[1]?.toLowerCase()) || ''}`

    // remap items for roles
    const filteredItems = items.filter((item: any) => {
        if (!!!checkModuleRoles(item.key)) {
            return false
        }
        return true
    })

    const mappedFilteredItems = filteredItems.map((item: any) => {
        if (item.children) {
            const nItem = item.children.filter((child: any) => {
                return checkModuleRoles(child.key)
            })
            item = {...item, children: nItem }
        }
        return item
    })



    return (
        <Layout>
            <Sider style={{height: '100vh'}}>
                <Menu
                    style={{backgroundColor: 'transparent'}}
                    mode="inline"
                    items={mappedFilteredItems}
                    defaultOpenKeys={['admin-management', 'super-admin-dashboard']}
                    selectedKeys={[current_route_active.toLowerCase() || '']}
                    defaultSelectedKeys={[current_route_active.toLowerCase() || '']} />
            </Sider>
            <Layout>
                <Header style={{display: 'flex', alignItems: 'flex-end'}}>
                    <div style={{width: '100%', display: 'flex'}}>
                        <div style={{display: 'flex', alignItems: 'center', width: 'calc(100% - 200px)', position: 'relative' }}>
                            <h1 style={{fontSize: 20, float: 'left'}}>{current_route?.mainLabel || ''} <span>{(current_route?.mainLabel === 'Users' && currentSelectedUser) ? ' | ' + (currentSelectedUser?.profile?.onboarded_at?  currentSelectedUser?.profile?.name : currentSelectedUser?.email) : ''}</span></h1>
                            {
                                currentUser.role.slug !== 'super_admin' && 
                                <h5 style={{position: 'absolute', bottom: 0, height: '40px', textTransform: 'uppercase', color: '#18B0CF' }}>{currentUser?.organization?.name}</h5>
                            }
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', width: 200, justifyContent: 'flex-end'}}>
                            {/* <img style={{marginRight: 20}} src={NotificationBellIcon} alt={'notification-bell'} width={30}/> */}
                            <StyledAvatar size={40} style={{backgroundColor: '#d9d9d9'}}><img src={currentUser?.profile?.avatar?.url || UsersWhiteIcon} width={currentUser?.profile?.avatar?.url ? 40 : 20} height={currentUser?.profile?.avatar?.url ? 40 : 20} alt="user avatar" /></StyledAvatar>
                            <Tooltip title={'Account Settings'} placement={'left'}>
                                <Dropdown
                                    overlay={<ProfileDropdown key={'profileDropdown'} onClose={() => setShowProfileDropdown(false)} setOpenProfile={() => setOpenProfile({visible: true, tab: 'profile'})}/>}
                                    trigger={['click']}
                                    open={showProfileDropdown}>
                                    <Button type={'text'} style={{display: 'flex', alignItems: 'center', padding: 0, margin: 0, marginLeft: 10}}
                                            onClick={() => setShowProfileDropdown((showProfileDropdown) => !showProfileDropdown)}>
                                        <FaChevronDown size={15}/>
                                    </Button>
                                </Dropdown>
                            </Tooltip>
                            {openProfile.visible ? <UserProfile visible={openProfile.visible} tab={openProfile.tab} onClose={() => setOpenProfile({visible: false, tab: 'profile'})}/> : null}
                        </div>
                    </div>
                </Header>
                <Content style={{overflow: 'auto'}}>
                    <AppRouter routes={private_routes_sidebar} />
                </Content>

                { globalPrompt && <Prompt { ...globalPrompt } /> }
            </Layout>

        </Layout>
    )
}

const StyledAvatar = styled(Avatar)`
  .ant-avatar-string{
    display: flex;
    align-items: center;
    height: 100%;
  }
`

export default LayoutContainer;
