
import React, { useState, useEffect } from 'react'
import {Col, Row, Form, 
    // DatePicker
} from 'antd'
import {InputLabel, Title} from 'src/components/CommonStyles'
import {InputText} from "../reusables/TextInputs";
// import styled from 'styled-components'
// import Select from "../reusables/Select";
import Button from 'src/components/reusables/Button'
// import {timeSelections} from "../../constants/datetime";
// import {HHMMss, ndHHMMss} from 'src/helpers/datetime';
import APIService from 'src/services/APIService'
// import  {useOrg} from 'src/hooks/useOrgContext'
// import moment from 'moment'
// import {useAuth} from "../../hooks/useAuth";


const EditSchedNameForm = ({userId, onCancel, onSave, schedule}:
    {onCancel: () => void, onSave: () => void, schedule: any, userId: any}) => {
    const [EditSchedName] = Form.useForm();
    // const [validTimes, setValidTimes] = useState<any[]>([])
    const [schedules, setSchedules] = useState([])

    const { get, put } = APIService()
    // const { currentUser } = useAuth()

    useEffect(() => {
        get('users/' + userId + '/schedules', []).then((schedules: any) => {
            setSchedules(schedules)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateSched = async (values: any) => {
        delete(schedule.dailys)
        delete(schedule.meta)
        delete(schedule.hasGroups)
        put('/schedules/' + schedule.id, {
            ...schedule, name: values.name
        }).then(() => onSave())
    }

    return (
        <Form
            initialValues={schedule}
            form={EditSchedName}
            name="edit-schedule-form"
            style={{width: '100%'}}
            layout={'vertical'}
            onFinish={(values) => {
                console.log(values)
                updateSched(values)
            }}
        >
            <Title>Edit Schedule Name</Title>
            <Row>
                <InputLabel>Schedule Name</InputLabel>
            </Row>
            <Form.Item
                name={'name'}
                rules={[
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        const existingNames = schedules?.map((schedule: any) => ({id: schedule?.id, name: schedule?.name?.toLowerCase().trim()}))
                        const existed = existingNames?.find((existingName: any) => existingName?.id === schedule?.id)
                        if(value && existed && existed?.name.toLowerCase().trim() === value?.toLowerCase().trim()){
                            return Promise.resolve();
                        }
                        if(value){
                            if(existingNames?.length && existingNames?.some((existingName: any) => existingName?.name === value?.toLowerCase().trim())){
                                return Promise.reject(new Error('Schedule name already exist'));
                            }else{
                                return Promise.resolve();
                            }
                        } else{
                            return Promise.reject(new Error('Schedule Name is required'));
                        }
                    },
                }),
            ]}
                style={{marginBottom: '10px', textAlign: 'left'}}
            >
                <InputText name={'name'} autoComplete='off' placeholder="Name"
                            style={{width: '100%'}} maxLength={24}/>
            </Form.Item>
            <Form.Item
                style={{margin: '10px 0'}}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Button
                            size={'large'}
                            style={{width: '100%', margin: 0}}
                            onClick={() => onCancel()}
                        >Cancel</Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            htmlType={'submit'}
                            type={'primary'}
                            size={'large'}
                            style={{width: '100%', margin: 0}}
                        >Save</Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    )
}


export default EditSchedNameForm

// const StyledSelect = styled(Select)<{ isrounded?: boolean}>`
//   &:not(.ant-select-customize-input) {
//     .ant-select-selector {
//       border-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
//       height: 50px;
//     }
//   }

//   .ant-select-selection-item {
//     text-align: left;
//     align-self: center;
//   }

//   .ant-select-selection-placeholder {
//     text-align: left;
//     align-self: center;
//   }
// `
