import React, { ReactNode } from 'react'
import { Button as AntDButton, ButtonProps } from 'antd';
import styled from 'styled-components';

interface IButtonProps {
    children?: ReactNode;
    primary?: boolean;
}

function Button({
    children,
    primary = false,
    ...rest
  }: IButtonProps & ButtonProps) {
  return (
    <StyledButton {...rest} {...primary ? { primary: primary } : {}}>{children || ''}</StyledButton>
  )
}

const StyledButton = styled(AntDButton)<{ primary?: boolean }>`
    margin: 5px;
    &.ant-btn {
        border-radius: 5px;
    }

    &.ant-btn-default {
        background-color: #81818D;
        color: #fff;
        border-color: #81818D;

        &:hover, &:focus {
            background-color: #81818dd8;
        }
    }

    &.ant-btn-primary {
        background-color: ${(props) => props.theme?.button || '#18B0CF'};
        border-color: ${(props) => props.theme?.button || '#18B0CF'};

        &:hover, &:focus {
            background-color: ${(props) => props.theme?.button || '#18B0CF'};
            filter: brightness(1.06);
        }

        &.ant-btn-dangerous {
            border-color: #FF5043;
            background: #FF5043;

            &:focus, &:hover {
                border-color: #ff7875;
                background: #ff7875;
            }
        }
    }

    &.ant-btn-text {
        color: #363b45;

        &:focus, &:hover {
            color: #36354bd8;
        }

        &.ant-btn-dangerous {
            color: #FF5043;

            &:focus, &:hover {
                color: #ff7875;
            }
        }

        &.ant-btn-text[disabled], 
        &.ant-btn-text[disabled]:active, 
        &.ant-btn-text[disabled]:focus, 
        &.ant-btn-text[disabled]:hover {
            opacity: 0.3;
        }

        ${(props) => props?.primary ? `
            color: #18B0CF; 

            &:hover, &:focus {
                color: #18B0CFd8;
            }
        ` : ''} 
    }

`



export default Button
