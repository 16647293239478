import {Layout as AntDLayout, Menu as AntDMenu} from 'antd';
import styled from "styled-components";
import React, {ReactNode} from "react";
import TimeAuthorLogo from 'src/assets/images/TimeAuthorLogo.svg';
import {SubTitle} from "../CommonStyles";
import {Center} from "src/components/CommonStyles";
import { useAuth } from 'src/hooks/useAuth';

const {Header: AntDHeader, Sider: AntDSider, Content: AntDContent, Footer: AntDFooter} = AntDLayout;

export const Sider = ({style, width, children = <></>}:{
    style?: {}
    width?: string
    children: ReactNode
}) => {
    const {currentUser, userIs} = useAuth();
    return (
        <StyledSider {...style} width={width || '270'}>
            <Center style={{margin: '30px 0 20px'}}>
                <img src={userIs('super_admin') ? TimeAuthorLogo : (currentUser?.organization?.portal_logo?.url || TimeAuthorLogo)} alt={currentUser?.organization?.portal_logo?.url ? currentUser?.organization?.name : 'Time Author'} width={'95%'} style={{height: '70px', objectFit: 'contain'}}/>
            </Center>
            {children}
        </StyledSider>
    )
}

const Layout = styled(AntDLayout)`
  height: 100%;
  width: 100%;
`

export const Header = styled(AntDHeader)`
  background-color: white;
  height: 100px;
  padding: 10px 30px;
`

export const StyledSider = styled(AntDSider)`
  background-color: rgba(87, 199, 246, 0.5);
  overflow: auto;

  .ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
    border-right-color: #18B0CF;
  }
`

export const Content = styled(AntDContent)`
  background-color: white;
  padding: 10px 30px;
  height: calc(100% - 100);
`

export const Footer = styled(AntDFooter)`
background-color: white;
`


export const Menu = styled(AntDMenu)`
  .ant-menu > .ant-menu-item,
  .ant-menu > .ant-menu-submenu,
  .ant-menu > .ant-menu-item-active,
  .ant-menu> .ant-menu-submenu-active,
  .ant-menu > .ant-menu-item-open,
  .ant-menu> .ant-menu-submenu-open,
  .ant-menu > .ant-menu-sub.ant-menu-inline {
    background-color: transparent;
  }
  
  .ant-menu-submenu-title:hover,
  .ant-menu-submenu-arrow:hover,
  .ant-menu-item:hover,
  .ant-menu-item a:hover {
    font-weight: bold;
    color: #18B0CF;
  }
  
  .ant-menu > .ant-menu-item-selected,
  .ant-menu-item.ant-menu-item-selected,
  .ant-menu > .ant-menu-submenu-selected {
    color: white;
    font-weight: bold;
    background-color: #18B0CF;
  }

  .ant-menu-sub.ant-menu-inline{
    background-color: transparent;
  }
  
  .ant-menu{
    height: calc(100% - 130px);
  }

  .ant-menu-item-selected, 
  .ant-menu-item-selected a, 
  .ant-menu-item-selected a:hover {
    color: #ffffff;
  }

  .ant-menu-item-selected {
    > img {
      -webkit-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
    }
  }

  .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow, .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon {
    color: #18B0CF;
  }

  .ant-menu-submenu-selected {
    color: #18B0CF;

    .ant-menu-submenu-title .ant-menu-title-content {
      font-weight: bold;
    }
  }
`
export default Layout;
