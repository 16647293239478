import React from 'react'

import { Center, SubTitle, Title } from '../CommonStyles'
import Button from '../reusables/Button'

function FailedModal({ details, title, onClose = () => {}, btnText = 'OK' }: {
    title?: string
    details?: string,
    onClose?: () => void,
    btnText?: string
}) {

  

  return (
    <div>
        {title && <Title style={{ marginBottom: '5px' }}>{title}</Title>}
        <SubTitle style={{ marginBottom: '20px' }}>{details}</SubTitle>
        <Center>
          <span>{/*Session Expired*/}</span>
          <Button
            type="primary"
            onClick={onClose}
            style={{
              minWidth: 70,
            }}
          >
            {btnText}
          </Button>
        </Center>
    </div>
  )
}

export default FailedModal