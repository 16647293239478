import React from 'react'
import { Radio as AntDRadio, RadioGroupProps } from 'antd';
import styled from 'styled-components';

function RadioGroup({ ...rest }: RadioGroupProps) {
    return (
        <StyledRadioGroup {...rest}>
            {rest.children}
        </StyledRadioGroup>
    )
}

const StyledRadioGroup = styled(AntDRadio.Group)`
    margin: 5px;

    .ant-radio-wrapper:hover .ant-radio, 
    .ant-radio-input:focus + .ant-radio-inner, 
    .ant-radio:not(.ant-radio-disabled):hover .ant-radio-inner,
    &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
        border-color: #18B0CF;
    }

    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
        color: #18B0CF;
    }

    .ant-radio-input:focus+.ant-radio-inner,
    .ant-radio-button-wrapper:focus-within,
    &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: 0 0 0 3px rgb(24 176 207 / 12%);
    }
    

    .ant-radio-checked:not(.ant-radio-disabled) {
        &::after {
            border-color: #18B0CF;
        }

        .ant-radio-inner {
            border-color: #18B0CF;

            &::after {
                background-color: #18B0CF;
            }
        }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        border-color: #18B0CF;
        color: #18B0CF;

        &:before {
            background-color: #18B0CF;
        }
    }

    &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background: #18B0CF;
        color: #ffffff;
    }
  
  .ant-radio-disabled .ant-radio-inner {
    background-color: transparent;
  }
  
  .ant-radio-checked.ant-radio-disabled {
    &::after {
      border-color: #18B0CF;
    }

    .ant-radio-inner {
      border-color: #18B0CF;

      &::after {
        background-color: #18B0CF;
      }
    }
  }

  .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
    border-color: #18B0CF;
    color: #18B0CF;

    &:before {
      background-color: #18B0CF;
    }
  }
`



export default RadioGroup
