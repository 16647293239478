export const validateJSON = (str: any) => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}

export const passwordRules =  [
        {
            required: true,
            message: 'Password is required',
        },
        {
            min: 8,
            message: 'Minimum of 8 characters'
        },
        {
            max: 128,
            message: 'Maximum of 8 characters'
        },
        {
            pattern: /(?=.*[a-z])/,
            message: '1 Lowercase Character'
        },
        {
            pattern: /(?=.*[A-Z])/,
            message: '1 Uppercase Character'
        },
        {
            pattern: /(?=.*\d)/,
            message: '1 Numeric Character'
        },
        {
            pattern: /(?=.*?[#?!@$%^&*-])/,
            message: '1 Special Character'
        },
    ]

export const numberOnly = (value:any, prev:any, all:any) => {
                                const reg = /^[0-9]+$/
                                if (reg.test(value) || value === '') {
                                    return value
                                }
                                if(!prev){
                                    return ''
                                }
                                return prev
                            }

export const validateEmail = (value : any) => {
    const validRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    return value.match(validRegex) ? true : false
}
export const isValidUrl = (url : string) => {
    const pattern = new RegExp(
        '^([a-zA-Z]+:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
    );
    return pattern.test(url);
}

export const lettersOnly = (e: any) => {
    let key = e.key;
    let regex = /^[a-zA-Z\s+]*$/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
}

export const alphaNumericOnly = (e: any) => {
    let key = e.key;
    let regex = /^[a-zA-Z0-9\s+]*$/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
}
export const numericOnly = (e: any) => {
    let key = e.key;
    let regex = /^\d*\.?\d*$/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
}
export const removeNonLetters = (e: any) => {
    e.target.value = e.target.value.replace( /[^a-zA-Z0-9 ]/gm, '')
}
