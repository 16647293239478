import React from 'react';

const Login = React.lazy(() => import('src/pages/auth/login/Login'));
const SignUp = React.lazy(() => import("src/pages/auth/signup/SignUp"));
const ForgotPassword = React.lazy(() => import("src/pages/auth/forgot-password/ForgotPassword"));
const ResetPassword = React.lazy(() => import("src/pages/auth/reset-password/ResetPassword"));
const SetupPassword = React.lazy(() => import("src/pages/auth/invite-user/ResetPassword"))
const LinkCalendar = React.lazy(() => import("src/pages/auth/link-calendar/outlook"))
const LinkCalendarIcloud = React.lazy(() => import("src/pages/auth/link-calendar/icloud"))
const LinkCalendarExchange = React.lazy(() => import("src/pages/auth/link-calendar/exchange"))
const Cookies = React.lazy(() => import("src/pages/auth/cookies/Cookies"));
const SendEmailVerification = React.lazy(() => import("../pages/auth/verification/SendEmailVerification"));
const VerifyAccount = React.lazy(() => import("src/pages/auth/verification/VerifyAccount"));
const Comp = React.lazy(() => import('src/pages/components/Components'));
const Providers = React.lazy(() => import('src/pages/oauth/Providers'));
const BookingForm = React.lazy(() => import('src/pages/booking'));
const BookingPage = React.lazy(() => import('src/pages/booking-page'));
const ReactivateOrg = React.lazy(() => import("src/pages/auth/org/reactivate"))

export const publicroutes = [
    {
        path: '/login',
        element: <Login />,
        // children: [
        //     {
        //         path: ':token',
        //         element: <Signup />
        //     }
        // ]
    },
    {
        path: '/components',
        element: <Comp />,
    },
    {
        path: '/signup',
        element: <SignUp />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/reset-password/:resetToken/:email',
        element: <ResetPassword />,
    },
    {
        path: '/cookies',
        element: <Cookies />,
    },
    {
        path: '/send-email-verification',
        element: <SendEmailVerification />,
    },
    {
        path: '/verify-account',
        element: <VerifyAccount />,
    },
    {
        path: '/oauth/:provider',
        element: <Providers />,
    },
    {
        path: '/form',
        element: <BookingForm />,
    },
    {
        path: '/booking/:type/:owner/:form',
        element: <BookingPage />,
    },
    {
        path: '/invite/:resetToken/:email',
        exact: true,
        element: <SetupPassword />,
    },
    {
        path: '/authorize',
        exact: true,
        element: <LinkCalendar />,
    },
	 {
		  path: '/authorize-icloud',
		  exact: true,
		  element: <LinkCalendarIcloud />,
	 },
	{
		path: '/authorize-exchange',
		exact: true,
		element: <LinkCalendarExchange />,
    },
    {
		path: '/activate-org/:id/:tok',
		element: <ReactivateOrg />,
    }
]
