import { useAuth } from 'src/hooks/useAuth'
import APIService from 'src/services/APIService'
import {useOrg} from "../hooks/useOrgContext";



const OrgService = (currOrgId?: number | undefined) => {
    const { currentUser, refreshUser } = useAuth()
    const { get, post, put, remove } = APIService()
    const {setOrganizationServices} = useOrg()

    const getOrganizations = async (page: number = 1, pageSize: number = 10) => {
        let orgs: any[] = []
        await get('organizations?page=' + page + '&per_page=' + pageSize, [])
            .then((resp: any) => {
                orgs = resp
            })
        return orgs
    }

    const getOrg = async () => {
        if (currOrgId) {
            let __org = await get('organizations/' + currOrgId, [])
            console.log('getOrg:', __org)
            refreshUser({ ...currentUser, organization: __org })
            return __org
        }

        // if org already on user context
        if (currentUser.organization) {
            return currentUser.organization
        }

        let org: any

        await get('organizations/my-organizations', [])
            .then((resp: any) => {
                const _org = resp.pop()
                // save organization to user context
                refreshUser({ ...currentUser, organization: _org })
                org = _org
            })
        return org
    }

    const addOrg = async (data: any) => {
        return await post('organizations/add', data)
            .then((resp: any) => {
                return resp
            })
    }

    const setOnboarded = async () => {
        const org = await getOrg()
        await put('organizations/' + org.id + '/set-onboarded' , [])
        .then((resp: any) => {
            let profile = {...currentUser.profile, onboarded_at: true }
            refreshUser({ ...currentUser, organization: resp.org, profile: profile, subscriptions: resp.subscriptions })
        })
    }

    const updateOrg = async (data: any) => {
        let newOrg: any

        await put('organizations/' + data.id , data)
            .then((resp: any) => {
                refreshUser({ ...currentUser, organization: resp })
            newOrg = {...resp}
        })

        return newOrg
    }

    const deleteOrg = async (data: any) => {
        await remove('organizations/' + data.id)
    }

    // todo: add pagination
    const getOrgGroups = async () => {
        let groups: any[] = []
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            await get('organizations/' + org.id + '/groups', []) 
            .then((resp: any) => {
                groups = [...resp]
            })
        }

        return groups
    }

    const getOrgUsers = async (page: number = 1, perPage: number = 10) => {
        let users: any[] = []

        if (currentUser.role.slug === 'super_admin') {
            await get('organizations/' + currentUser.organization.id + '/users', {
                params: {
                    page,
                    per_page: perPage
                }
            })
            .then((resp: any) => {
                // users = [...resp.data]
                users = resp
            })
            return users
        }
        await get('organizations/my-org-users', [])
            .then((resp: any) => {
                users = [...resp]
            })
        return users
    }

    const getOrgServices = async () => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            let services: any[] = []

            await get('organizations/' + org.id + '/services', [])
                .then((resp: any) => {
                    services = [...resp]
                })
            setOrganizationServices(services)
            return services
        }
        return []
    }

    const getOrgServicesPage = async (page: any = 1, perPage: any = 10) => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            let services: any[] = []

            await get('organizations/' + org.id +   '/pservices?page=' + page + '&per_page=' + perPage, [])
                .then((resp: any) => {
                    services = resp
                })
            return services
        }
        return []
    }

    const getOrgRoles = async () => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            let roles: any[] = []

            await get('organizations/' + org.id + '/roles', [])
                .then((resp: any) => {
                    roles = [...resp]
                })
            return roles.sort(function (a, b) {
                if (a.slug > b.slug) return 1;
                if (a.slug < b.slug) return -1;
                return 0;
            })
        }
        return []
    }

    const addOrgUser = async (data: any) => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            let use: any
            await put('organizations/' + org.id + '/add-user', data)
                .then((resp: any) => {
                    use = resp
                })
            return use
        }
    }

    const addGroup = async (data: any) => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            await put('organizations/' + org.id + '/add-group', data)
            .then((resp: any) => {
            })
        }
    }

    const addRole = async (data: any) => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            await put('organizations/' + org.id + '/add-role', data)
            .then((resp: any) => {
            })
        }
    }

    const updateRole = async (data: any) => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            await put('organizations/' + org.id + '/update-role', data)
            .then((resp: any) => {
            })
        }
    }

    const updateGroup = async (gid: number, data: any) => {
        await put('groups/' + gid, data)
            .then((resp: any) => {
            })
    }

    const activateOrg = async (id: number, status: boolean) => {
        await put('organizations/' + id + '/activate', { active: status })
    }


    const removeGroup = async (gid: number) => {
        await remove('groups/' + gid)
    }

    const removeService = async (sid: number) => {
        await remove('services/' + sid)
    }

    const removeRole = async (rid: number) => {
        await remove('roles/' + rid)
    }

    const addService = async (data: any) => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            await put('organizations/' + org.id + '/add-service', data)
            .then((resp: any) => {
            })
        }
    }

    const updateService = async (data: any) => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            await put('organizations/' + org.id + '/update-service', data)
            .then((resp: any) => {
            })
        }
    }

    const searchService = async (data: any) => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            let services: any[] = []
            await post('services/' + org.id + '/search', data)
            .then((resp: any) => {
                services = [...resp]
            })
            return services
        }
        return []
    }

    const resendOrgInvite = async (data: any) => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            return await post('organizations/' + org.id + '/resend-invite', data)
        }

        return null
    }

    const setEmailVerified = async (data: { organization_id: number, verified: boolean }) => {
        return await post('organizations/' + data.organization_id + '/set-email-verified', data)
    }

    const getOrgService = async (sid: number) => {
        const org = currOrgId ? { id: currOrgId} : currentUser.organization
        if (org) {
            let service: any = null
            await get('organizations/' + org.id + '/services/' + sid, [])
                .then((resp: any) => {
                    service = resp
                })
            return service
        }
        return null
    }

    const addPlan = async (input?:  { subscription_plan_id: number; period: 'monthly' | 'annually'; remarks: string | null }) => {
        
        if (currOrgId) {
            let plan: any = null
            await post('organizations/' + currOrgId + '/add-plan/', input)
                .then((resp: any) => {
                    plan = resp
                })
            return plan
        }
        return null
    }


    return {
        getOrg,
        addOrg,
        updateOrg,
        activateOrg,
        getOrgUsers,
        addOrgUser,
        removeGroup,
        addGroup,
        updateGroup,
        getOrgServices,
        getOrgGroups,
        addService,
        removeService,
        updateService,
        getOrgRoles,
        addRole,
        updateRole,
        removeRole,
        searchService,
        setOnboarded,
        getOrganizations,
        deleteOrg,
        resendOrgInvite,
        setEmailVerified,
        getOrgService,
        getOrgServicesPage,
        addPlan
    }
}


export default OrgService
