import { Modal } from 'antd'
import axios from 'axios'
import { validateJSON } from 'src/helpers/validations'
// import { useNavigate } from 'react-router-dom'
import FailedModal from 'src/components/modals/FailedModal'

const hostName = window.location.hostname
const default_api = process.env.REACT_APP_BACKEND_URL || 'https://local.timeauthor.it:3333'
const endPoint = default_api + '/api'

const errorMessage = (error: any) => {
  const onModalClose = () => {
    Modal.destroyAll();
  }

  const ClearStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
    onModalClose()
  }

  const ReLogin = () => {
    ClearStorage()
    window.location.href = '/login'
  }

  switch(error.response?.data?.code) {
    case 'INVALID_CREDENTIALS':
      const response = JSON.parse(error.response?.data?.details)
      if(response.responseText === 'E_INVALID_AUTH_PASSWORD: Password mis-match'){
        return {
          title: 'Login Failed',
          details: 'Invalid password. Please try again.',
          onClose: ClearStorage
        }
      }else if(response.responseText === 'E_INVALID_AUTH_UID: User not found'){
        return {
          title: 'Login Failed',
          details: 'We couldn\'t find the email address you entered. Please try again.',
          onClose: ClearStorage
        }
      }
      return {
        title: 'Login Failed',
        details: 'Invalid email and password. Please try again.',
        onClose: ClearStorage
      }

    case 'INVALID_RECAPTCHA':
      return {
        title: 'Uh oh!',
        details: "You're detected as robot.",
        onClose: ClearStorage
      }

    case 'UNAUTHORIZED_TOKEN':
      return {
        title: 'Re-Login',
        details: error.response?.data?.message || 'Expired Token',
        onClose: ReLogin,
      }

    case 'UNAUTHORIZED_ACCESS':
      return {
        title: 'Uh oh!',
        details: "You are a not authorized for this action.",
        onClose: onModalClose
      }

    default:
      if (error.response?.data?.message === 'Your token has expired') {
        return {
          title: 'Re-Login',
          details: error.response?.data?.message || 'Expired Token',
          onClose: ReLogin,
        }
      }
  }

  return { title: 'Uh oh!', details: 'Something went wrong.', onClose: onModalClose }
}

const handleSuccess = (response: any) => {
  return response.data;
};

const handleError = (error: any) => {
  if (!error.response) {
    const errors = {
      status: 'ZERO_RES',
      message: 'Unable to connect to server.',
      statusText: 'Unable to connect to server.',
      context: 'Please check your internet connection.',
    };
    throw errors;
  }

  if (error.response) {
    switch(error.response.status) {
      case 401:
      case 500:
        Modal.warning({
          centered: true,
          icon: false,
          zIndex: 1000000,
          className: 'custom-modal',
          content: <FailedModal
            { ...errorMessage(error) }
          />,
        })
        break
    }

    return Promise.reject(error.response.data)
  }

  return error.message;
};

const handleHeaders = (config = undefined, query: { params?: any } = {}) => {
  const storedToken = localStorage.getItem('token')
  const token = validateJSON(storedToken) ? JSON.parse(storedToken as string) : storedToken;
  const url = `${default_api}/api`;

  let service = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...config || {}
    },
    ...query
  });

  service.interceptors.response.use(handleSuccess, handleError);
  return service;
};

const ApiService = () => {
  const get = (path: string, params: any, query?: { params: any }) => {
    let service = handleHeaders(undefined, query);
    return service.get(path, params);
  };
  const post = (path: string, body: any, config: any = undefined) => {
    let service = handleHeaders(config);
    return service.post(path, body);
  };
  const put = (path: string, body: any) => {
    let service = handleHeaders();
    return service.put(path, body);
  };
  const remove = (path: string) => {
    let service = handleHeaders();
    return service.delete(path);
  };

  return {
    get,
    post,
    put,
    remove,
    endPoint,
    hostName,
  };
};

export default ApiService;
