

import React, {useState, useEffect} from "react";
import {Col, Form, Row, Space} from "antd";
// import Button from "../../components/reusables/Button";
import {
    // InputLabel, 
    StyledSelect, Title} from "../../components/CommonStyles";
// import {AiOutlineArrowLeft, AiOutlineClose} from "react-icons/ai";
// import {PlusOutlined} from "@ant-design/icons";
import Prompt from "src/components/reusables/Prompt";

import UserService from "src/services/UserService";
import ServiceService from "src/services/ServiceService";
import  {useOrg} from 'src/hooks/useOrgContext'
import APIService from 'src/services/APIService'
import { useAuth } from 'src/hooks/useAuth'
import { map, uniqBy } from "lodash";

function Services ({uid}: {uid: number}) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [services, setServices] = useState<any[]>([])
    const [groupServices, setGroupServices] = useState<any[]>([])
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState<boolean>(false)
    const [selectedService] = useState<number>(0)//, setSelectedService
    const {put} = APIService()
    const [form] = Form.useForm();
    const { currentUser, userIs } = useAuth()
    const {getServices, getUserGroupsServices} = UserService()
    const {removeUserFromService} = ServiceService()
    const {
        saveEvent, 
        // setSaveEvent, 
        selectedGroups
    } = useOrg()

    useEffect(() => {
        getUserGroupsServices(uid).then((rServs) => {
            setGroupServices(rServs)
            getServices(uid).then((resp) => {
                setServices(resp)
                form.setFieldValue('services', resp.map((sd: any) => sd.id))
            })
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let gServices: any[] = []
        selectedGroups.forEach((sg: any) => {
            gServices = [...gServices, ...sg.services]
        })
        setGroupServices(uniqBy(gServices, 'id'))
    }, [selectedGroups])

    useEffect(() => {
        console.log('save event', form.getFieldValue('services'))
        const srvs = form.getFieldValue('services')
        if (srvs) {
            put('users/' + uid + '/services', {service_id: srvs})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveEvent])

    const ConfirmToDeletePrompt = () => {
        return (
            <Prompt
                title="Remove Service"
                message={<>Are you sure you want to remove user from this service? </>}
                visible={confirmDeleteVisible}
                okButton="Delete"
                okFunction={() => {
                    removeUserFromService(uid, selectedService).then(() => {
                        setConfirmDeleteVisible(false)
                        getServices(uid).then((resp) => setServices(resp))
                    })
                }}
                danger={true}
                cancelButton="Cancel"
                cancelFunction={() => {
                    setConfirmDeleteVisible(false)
                }}
            />
        )
    }

    return (
    <>
        <ConfirmToDeletePrompt />
        <Space direction={'horizontal'} align={'center'}>
            <Title fontWeight={'400'} fontSize={'12'} style={{textAlign: 'left', margin: 0, width: 75}}>Services</Title>
            {/* <Button type={'primary'} style={{padding: '0px 5px', height: 'auto'}}><PlusOutlined /></Button> */}
        </Space>
        <Row>
        <Col>
            <Form
                disabled={currentUser?.role.slug === 'member'}
                form={form}
                name="user-form"
                style={{width: '100%', marginBottom: 15}}
                layout={'vertical'}
                onFinish={(values: any) => {
                    console.log(values)
                }}
            >
            <Form.Item name="services" noStyle>
            <StyledSelect
                    mode="multiple"
                    placeholder={'Select Services'}
                    options={groupServices.map((srv) => ({
                        label: srv.name,
                        value: srv.id,
                        disabled: !!!userIs('admin') && !!!map(srv.managers, 'id').includes(currentUser?.id)
                    }))}
                    style={{width: '330px'}}
                    onChange={(values: any) => {
                        console.log(values)
                    }}
                    height={'auto'}
                />
            </Form.Item>
            </Form>

            </Col>
        </Row>
    </>
    )

}


export default Services
