import React, {useEffect, useMemo, useRef, useState} from "react";
import {Col, Form, Radio, Row, Space} from "antd";
import {useAuth} from "src/hooks/useAuth";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FormType} from "src/pages/group";
import OrgService from "src/services/OrganizationService";
import UserService from "src/services/UserService";
import ApiService from "src/services/APIService";
import Options from "src/services/APIOptions";
import Button from "../reusables/Button";
import {Center, InputLabel, SubTitle, Title} from "../CommonStyles";
import Modal from "../reusables/Modal";
import AvailabilityForm from "../Forms/AvailabilityForm";
import Prompt from "../reusables/Prompt";
import CameraIcon from "src/assets/icons/CameraIcon.svg";
import {InputPassword, InputText} from "../reusables/TextInputs";
import Checkbox from "../reusables/Checkbox";
import {alphaNumericOnly, 
    // lettersOnly, 
    numberOnly, passwordRules, removeNonLetters} from "src/helpers/validations";
import Clock from "react-live-clock";
import Switch from "../reusables/Switch";
import Groups from "src/pages/users/Groups";
import Services from "src/pages/users/Services";
import Integrations from "src/pages/users/Integrations"
import Availability from "./Availability";
import CustomAvailabilityForm from "../Forms/CustomAvailabilityForm";
import styled from "styled-components";
import Select from "../reusables/Select";
import  {useOrg} from 'src/hooks/useOrgContext'
import DeleteGroup from "src/assets/images/DeleteGroup.png";
import RadioGroup from "../reusables/RadioGroup";
import TimeAuthorLogoWithName from "src/assets/images/time-Author-Logo-Grapl-Font blue.png";
import phone from "phone";
import {getPhoneNumberLength} from "src/helpers/phoneNumber";
import Plan from "./Plan";

const UserProfile = ({visible, tab, onClose}:{
    visible: boolean
    tab: 'profile' | 'availability' | 'plan'
    onClose: () => void
}) => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const [tabIndex, setTabIndex] = useState(1);
    const [rolesData, setRolesData] = useState<any[]>([])
    const [showCustomAvailabilityForm, setShowCustomAvailabilityForm] = useState<boolean>(false)

    const [showIntegration] = useState<boolean>(false)

    const { currentUser, refreshUser, signOut } = useAuth()
    const [searchParams] = useSearchParams();//, setSearchParams
    const [onPassword, setOnPassword] = useState(false);
    const [activeStatus, setActiveStatus] = useState(true)
    const [showAvailabilityForm, setShowAvailabilityForm] = useState(false)
    const [receiveNotif, setReceiveNotif] = useState<boolean>(false)
    const [newOwner, setNewOwner] = useState<Number>(0)
    const [typedTA, setTypedTA] = useState<string>('')

    const [ownerTimezone, setOwnerTimezone] = useState('')

    const [timezoneList, setTimezoneList]= useState<{ label: string, value: string }[]>([])
    const [formType, setFormType] = useState<FormType>('view')

    const [profilePicture, setProfilePicture] = useState()

    const [usersOptions, setUsersOptions] = useState<any []>([])
    const [deleteAccountSelectedOption, setDeleteAccountSelectedOption] = useState<any>(1)
    const [confirmToDelete, setConfirmToDelete] = useState<boolean>(false)
    const [showDeletePromptSuccess, setShowDeletePromptSuccess] = useState<boolean>(false)
    const [schedules, setSchedules] = useState([])
    const [org, setOrg] = useState<any>()
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)

    const avatarElement = useRef<HTMLInputElement>(null)

    const {getOrgRoles, getOrg, getOrgUsers} = OrgService()
    const {updateUser} = UserService()

    const { post, get } = ApiService()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {saveEvent, setSaveEvent} = useOrg()

    const currentTimezone = Form.useWatch('timezone', form)

    useEffect(() => {
        setTabIndex(tab === 'profile' ? 1 : tab === 'availability' ? 2 : 3)
    }, [tab])

    useEffect(() => {
        if (searchParams.get('profile') === 'new') {
            getOrg().then((org: any) => {
                setOwnerTimezone(org.owner.timezone)
                updateUser(currentUser.id,{timezone: org.owner.timezone})
                setOrg(org)
            })
            setFormType('update')
        }

        getOrgRoles().then((rdata) => {
            setRolesData(rdata)
        })
        setActiveStatus(currentUser.active)

        getOrgUsers().then((users: any) => {
            console.log('users: ', users.map((user: any) => ({label: user.profile.name, value: user.id})))
            const filtered = users.filter((u: any) => u.id !== currentUser.id)
            setUsersOptions(filtered.map((user: any) => ({label: user.is_verified ? user.profile.name : user.email.toLowerCase(), value: user.id})))
        })

        Options.getTimezoneList().then((resp) => setTimezoneList(resp))
        setReceiveNotif(currentUser.profile?.receive_appointment_notifications)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        form.setFieldValue('role_slug', currentUser.role.slug)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolesData])


    useEffect(() => {
        get('users/' + currentUser.id + '/schedules', []).then((schedules: any) => {
            setSchedules(schedules)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAvailabilityForm])

    useEffect(() => {
        if (showDeleteAccountModal) {
            getOrg().then((org: any) => {
                setOrg(org)
                if (org.owner?.id !== currentUser.id || org.onboarded_at === null) {
                    setConfirmToDelete(true)
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDeleteAccountModal])

    const transferAndDelete = async () => {
        return post('organizations/' + org.id + '/change-owner', {
            user_id: newOwner,
            old_id: currentUser.id
        })
    }

    const deactivateAndArchive = async () => {
        return post('organizations/' + org.id + '/deactivate-owner', {
        })
    }

    const deactivateNonOwner = async () => {
        return post('organizations/' + org.id + '/delete-account', {})
    }

    const successAndLogout = async () => {
        setShowDeletePromptSuccess(true)
        setTimeout(signOut, 3000)
    }

    interface DataType {
        key: number;
        day: string;
        available: string;
    }

    const data: DataType[] = [];
    for (let i = 1; i <= 10; i++) {
        data.push({
            key: i,
            day: 'SUN',
            available: '9:00 am - 5:00 pm',
        });
    }

    interface CustomAvailabilityDataType {
        key: number;
        datetime: string;
        notes: string;
        status: string;
    }

    const customAvailabilityData: CustomAvailabilityDataType[] = [];
    for (let i = 1; i <= 10; i++) {
        customAvailabilityData.push({
            key: i,
            datetime: 'Feb 22, 2022 | 8:30 PM-11:00 PM',
            notes: 'Notes',
            status: 'Open'
        });
    }

    const ProfileHeaderButtons = (
        <>
            {(searchParams.get('profile') === 'new' || !currentUser?.profile?.first_name || !currentUser?.profile?.last_name) ? null :
                <Button size={'large'} style={{width: 100, margin: '0px 5px'}} onClick={() => {
                    form.resetFields()
                    form.setFieldValue('role_slug', currentUser.role.slug)
                    setFormType('view')
                    setTabIndex(1)
                    onClose()
                }}>
                    Cancel
                </Button>}
            {formType === 'view' ? <Button onClick={() => {
                    setFormType('update')
                }} type={"primary"} size={'large'} style={{width: 100, margin: '0px 5px'}}>
                    Edit
                </Button> :
                <Button onClick={() => {setSaveEvent(Date.now()); form.submit()}} type={"primary"} size={'large'} style={{width: 100, margin: '0px 5px'}}>
                    Save
                </Button>}
        </>
    )

    const AvailabilityHeaderButtons = (
        <>
            {(searchParams.get('profile') === 'new' || !currentUser?.profile?.first_name || !currentUser?.profile?.last_name) ? null : <Button size={'large'} style={{width: 100, margin: '0px 5px 5px'}} onClick={() => {
                form.resetFields()
                setFormType('view')
                setTabIndex(1)
                onClose()
            }}>
                Cancel
            </Button>}
            <Button type={"primary"} size={'large'} style={{margin: '0px 5px 5px'}} onClick={() => setShowCustomAvailabilityForm(true)}>
                Add Custom Availability
            </Button>
            <Button onClick={() => setShowAvailabilityForm(true)} type={"primary"} size={'large'} style={{margin: '0px 5px 5px'}}>
                Add Schedule
            </Button>
        </>
    )

    const Header = (
        <Row style={{flex: '0 1 auto', marginBottom: 20}}>
            <Col span={12}>
                <Row>
                    <Col>
                        <Button
                            type={"text"}
                            size={'large'}
                            style={{borderColor: tabIndex === 1 ? '#18B0CF' : 'white', borderWidth: '0px 0px 3px 0px', borderStyle: 'solid', paddingBottom: 8, borderRadius: 0, width: 150, margin: 0}}
                            onClick={() => setTabIndex(1)}
                        >
                            <Title style={{marginBottom: 0}} fontSize={'16px'} fontWeight={'500'}>Profile</Title>
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type={"text"}
                            size={'large'}
                            style={{borderColor: tabIndex === 2 ? '#18B0CF' : 'white', borderWidth: '0px 0px 3px 0px', borderStyle: 'solid', paddingBottom: 8, borderRadius: 0, width: 150, margin: 0}}
                            onClick={() => setTabIndex(2)}
                        >
                            <Title style={{marginBottom: 0}} fontSize={'16px'} fontWeight={'500'}>Availability</Title>
                        </Button>
                    </Col>
                    {/*<Col>*/}
                    {/*    <Button*/}
                    {/*        type={"text"}*/}
                    {/*        size={'large'}*/}
                    {/*        style={{borderColor: tabIndex === 3 ? '#18B0CF' : 'white', borderWidth: '0px 0px 3px 0px', borderStyle: 'solid', paddingBottom: 8, borderRadius: 0, width: 150, margin: 0}}*/}
                    {/*        onClick={() => setTabIndex(3)}*/}
                    {/*    >*/}
                    {/*        <Title style={{marginBottom: 0}} fontSize={'16px'} fontWeight={'500'}>Plan</Title>*/}
                    {/*    </Button>*/}
                    {/*</Col>*/}
                </Row>
            </Col>
            <Col span={12} style={{textAlign: 'right'}}>
                {tabIndex === 1 ? ProfileHeaderButtons : tabIndex === 2 ? AvailabilityHeaderButtons : <></>}
            </Col>
        </Row>
    )

    const schedForm = (
        <Modal visible={showAvailabilityForm} width={800} onCancel={() => setShowAvailabilityForm(false)}>
            <AvailabilityForm data={schedules} onCancel={() => setShowAvailabilityForm(false)} onSave={() => {setShowAvailabilityForm(false)}} uid={currentUser.id}/>
        </Modal>
    )

    const [updatedSuccessVisible, setUpdatedSuccessVisible] = useState<boolean>(false)

    const UpdatedSuccessPrompt = useMemo(() => {
        return (
            <Prompt
                success
                title={"Profile Updated"}
                message={`You have successfully updated your profile`}
                visible={updatedSuccessVisible}
                okButton="OK"
                okFunction={() => {
                    setUpdatedSuccessVisible(false)
                    setTimeout(() => {
                        onClose()
                    }, 50)
                }}
            />
        )
    }, [updatedSuccessVisible, onClose])

    const RenderAvatar = ({ picture, width = {}}: {
        picture: any
        width?: any
    }) => {
        return <img src={picture} style={{margin: 0, padding: 0}} alt="user profile" {...{ width }} />
    }

    const onBrowseFile = (e: any) => {
        setProfilePicture(e.target.files[0])
    }

    const uploadFile = (file: any) => {
        let formData = new FormData()
        formData.append("avatar", file);
        // upload
        post('user/upload', formData, { "Content-Type": "multipart/form-data" })
            .then((resp:any) => {
                console.log('avatar', resp)
                if (resp) {
                    // let user = currentUser
                    currentUser.profile.avatar = resp
                    refreshUser(currentUser)
                }
            })
            .catch((err:any)=> console.error(err))
    }

    const UserProfileData = [
        {
            label: 'Name',
            content: `${currentUser?.profile?.first_name || ''} ${currentUser?.profile?.last_name || ''}`,
        },
        {
            label: 'Email Address',
            content: currentUser?.email.toLowerCase() || '',
        },
        {
            label: 'Phone Number',
            content: `${currentUser?.profile?.country_code || ''} ${currentUser?.profile?.phone_number || ''}` || '',
        },
        {
            label: 'Role',
            content: currentUser?.role?.name || '',
        },
        {
            label: 'Timezone',
            content: currentUser?.timezone || '',
        },
        {
            label: 'Active Status',
            content: <Switch checked={currentUser?.active === 1} size={'small'} style={{margin: 0}}/>,
        },
    ]

    const DeleteSuccessPromptModal = (
        <Modal visible={showDeletePromptSuccess} width={530} onCancel={() => {setShowDeletePromptSuccess(false)}}>
            <Center>
                <img src={TimeAuthorLogoWithName} alt="Grapl" width={250} style={{marginBottom: 20}}/>
                <img src={DeleteGroup} alt="Grapl" width={100}/>
                {deleteAccountSelectedOption === 1 ? <Title style={{fontSize: 30, marginTop: 20}}>Deleted Account</Title> :
                    <Title style={{fontSize: 18, marginTop: 20, fontWeight: 800}}>Deactivated account and archived organization</Title>
                }
                <Title style={{fontSize: 16}}>We're sorry to see you go...</Title>
                <SubTitle>Your Time Author account and all its data have been deleted.</SubTitle>
            </Center>
        </Modal>
    )
    const DeleteAccountModal = (
        <Modal visible={showDeleteAccountModal} width={530} onCancel={() => setShowDeleteAccountModal(false)}>
            <Center>
                <img src={DeleteGroup} alt="Grapl" width={100}/>
            </Center>
            {!confirmToDelete ? <RadioGroup
                    defaultValue={deleteAccountSelectedOption}
                    style={{marginLeft: 20}}
                >
                    <Space direction="vertical" style={{textAlign: 'left', marginTop: 20}}>
                        <Radio value={1} onChange={(e) => setDeleteAccountSelectedOption(e.target.value)}>
                        <span
                            style={{fontWeight: 500}}>Transfer organization ownership and delete my account</span><br/>
                            <span style={{fontSize: 12}}>The account will no longer be available and will be permanently deleted. This can’t be undone.</span><br/>
                            <h6 style={{marginTop: 20}}>Choose new primary admin<span style={{color: 'red'}}>*</span></h6>
                            <StyledSelect onChange={(value: any) => setNewOwner(value)} options={usersOptions} placeholder={'Select user'} style={{width: '100%'}}/>
                        </Radio>
                        <Radio value={2} onChange={(e) => setDeleteAccountSelectedOption(e.target.value)}>
                            <span style={{fontWeight: 500}}>Deactivate my account and archived organization</span><br/>
                            <span style={{fontSize: 12}}>Your account will be deactivated for 30 days. During deactivation, you can reactivate your Time Author account anytime. After 30 days, your account and data will be deleted  permanently .</span>
                        </Radio>
                    </Space>
                </RadioGroup>
                :
                <>
                    <SubTitle style={{textAlign: 'left', marginTop: 20}}>
                        {deleteAccountSelectedOption === 1 ? 'The account will no longer be available and will be permanently deleted. This can’t be undone.' :
                            `Your account will be deactivated for 30 days. During deactivation, you can reactivate your Time Author account anytime. After 30 days, your account and data will be deleted  permanently .`
                        }
                    </SubTitle>
                    <h6 style={{textAlign: 'left', marginTop: 20}}>Confirm by typing <span style={{color: '#0D6D81'}}>Time Author</span> below<span style={{color: 'red'}}>*</span></h6>
                    <InputText onChange={(value: any) => {setTypedTA(value.target.value)}} style={{width: '100%'}}/>
                    <h6 style={{textAlign: 'left', color: 'red'}}>You can't undo this action.</h6>
                </>
            }

            <Row gutter={24} style={{marginTop: 15}}>
                <Col span={12}>
                    <Button
                        size={'large'}
                        style={{margin: 0, width: '100%'}}
                        onClick={() => {
                            setShowDeleteAccountModal(false)
                            setDeleteAccountSelectedOption(1)
                            setConfirmToDelete(false)
                        }}
                    >
                        Cancel
                    </Button>
                </Col>
                {confirmToDelete ? <Col span={12}>
                    <Button
                        danger
                        disabled={typedTA !== 'Time Author'}
                        type={'primary'}
                        size={'large'}
                        style={{margin: 0, width: '100%'}}
                        onClick={() => {
                            if (org.owner?.id !== currentUser.id) {
                                return deactivateNonOwner().then(() => {
                                    successAndLogout()
                                })
                            }
                            // account is still on personal level
                            if (!!!org.onboarded_at) {
                                return deactivateNonOwner().then(() => {
                                    successAndLogout()
                                })
                            }

                            if (deleteAccountSelectedOption === 1) {
                                transferAndDelete().then(() => {
                                    successAndLogout()
                                })
                            } else {
                                deactivateAndArchive().then(() => {
                                    successAndLogout()
                                })
                            }
                        }}
                    >
                        Delete
                    </Button>
                </Col>
                    :
                    <Col span={12}>
                    <Button
                    danger
                    disabled={deleteAccountSelectedOption === 1 && newOwner === 0}
                    type={'primary'}
                    size={'large'}
                    style={{margin: 0, width: '100%'}}
                    onClick={() => {
                        setConfirmToDelete(true)
                    }}
                    >
                    Continue
                    </Button>
                    </Col>}
            </Row>
        </Modal>
    )

    const ProfileContentLeft = (
        <>
            <Title fontWeight={'500'} fontSize={'14px'} style={{textAlign: 'left', marginTop: 6}}>Profile Picture</Title>
            <Form
                initialValues={{...currentUser, email: currentUser?.email?.toLowerCase(), country_code: currentUser?.country_code || '+1', timezone: (searchParams.get('profile') === 'new') && (currentUser.timezone === 'UTC') ? ownerTimezone : currentUser.timezone}}
                form={form}
                name="update-profile-form"
                style={{width: '100%'}}
                layout={'vertical'}
                onFinish={(values) => {
                    updateUser(values.id, { ...values, first_name: values.first_name?.replace(/\s+/g, " ").trim(), last_name: values.last_name?.replace(/\s+/g, " ").trim(), active: activeStatus, receive_appointment_notifications: receiveNotif }).then(() => {
                        uploadFile(profilePicture)
                        setUpdatedSuccessVisible(true)
                        navigate('/', {replace: true})
                    })
                }}
            >
                <Form.Item name="id" noStyle><input type="hidden" name="id" value={currentUser.id} /></Form.Item>
                <Form.Item
                    name="picture"
                    rules={[
                        {},
                    ]}
                    style={{marginBottom: '10px', textAlign: 'center'}}
                >
                    <div style={{ display: 'none' }}>
                        <input type="file" ref={avatarElement} onChange={onBrowseFile} />
                    </div>
                    {<Button style={{height: 80, width: 80, borderRadius: 100, alignItems: 'center', margin: currentUser?.profile.avatar?.url ? 0 : 5, padding: currentUser?.profile.avatar?.url ? 0  :'4px 15px'}}
                             type={currentUser?.profile.avatar?.url ? 'text' : 'primary'} onClick={() => avatarElement.current?.click()}>
                        {currentUser?.profile.avatar?.url ? <RenderAvatar
                            picture={currentUser?.profile.avatar?.url} {...currentUser?.profile.avatar?.url && {width: '80px'}} /> : <span style={{
                            backgroundColor: '#363B45',
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                                <RenderAvatar
                                    picture={currentUser?.profile.avatar?.url ? currentUser?.profile.avatar?.url : CameraIcon} {...currentUser?.profile.avatar?.url && {width: '50px'}} />
                            </span>}
                    </Button>}
                </Form.Item>
                {formType === 'view'  ? <div style={{marginTop: 30}}>
                    {UserProfileData.map(({label, content}) => {
                        return (
                            <Row style={{margin: '10px 0px'}}>
                                <Col flex={'150px'} style={{display: 'flex', alignItems: 'center'}}>
                                    <InputLabel>{label}</InputLabel>
                                </Col>
                                <Col flex={'auto'} style={{display: 'flex', alignItems: 'center'}}>
                                    <InputLabel>{content}</InputLabel>
                                </Col>
                            </Row>
                        )
                    })}
                </div> :
                    <>
                        <InputLabel>First Name</InputLabel>
                        <Form.Item
                            initialValue={currentUser.profile.first_name}
                            name="first_name"
                            rules={[
                                { required: true, message: 'First Name is required'},
                            ]}
                            style={{ marginBottom: '10px', display: 'inline-block', width: '100%' }}
                        >
                            <InputText
                                onKeyPress={alphaNumericOnly}
                                onKeyDown={alphaNumericOnly}
                                onInput={removeNonLetters}
                                name="first_name"
                                autoComplete='off'
                                placeholder="First Name"
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                        <InputLabel>Last Name</InputLabel>
                        <Form.Item
                            initialValue={currentUser.profile.last_name}
                            name="last_name"
                            rules={[
                                { required: true, message: 'Last Name is required' },
                            ]}
                            style={{ marginBottom: '10px', display: 'inline-block', width: '100%' }}
                        >
                            <InputText
                                onKeyPress={alphaNumericOnly}
                                onKeyDown={alphaNumericOnly}
                                onInput={removeNonLetters}
                                name="last_name"
                                autoComplete='off'
                                placeholder="Last Name"
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                        <InputLabel>Email Address</InputLabel>
                        <Form.Item
                            name="email"
                            rules={[
                                {required: true, message: 'Email address is required'},
                                {type: 'email', message: 'Please input a valid email address'}
                            ]}
                            style={{marginBottom: '10px', display: 'inline-block', width: '100%' }}
                        >
                            <InputText onInput={(e: any) => e.target.value = e.target.value.toLowerCase()} name="email" autoComplete='off' placeholder="Email address" style={{width: '100%'}} disabled/>
                        </Form.Item>
                        <Row>
                            <Col xs={{span: 10}} style={{alignItems: 'center', display: 'flex'}}>
                                {onPassword ? <InputLabel>New Password</InputLabel> : null}
                            </Col>
                            <Col xs={{span: 14}}>
                                <Checkbox onChange={(value) => setOnPassword(value.target.checked)} style={{float: "right", margin: 0}}>Change Password</Checkbox>
                            </Col>
                        </Row>
                        {onPassword? (<>
                            <Form.Item
                                name="password"
                                rules={passwordRules}
                                style={{marginBottom: '10px', display: 'inline-block', width: '100%' }}
                            >
                                <InputPassword name="password" autoComplete="new-password" placeholder="New Password" style={{width: '100%'}}/>
                            </Form.Item>
                            <InputLabel>Confirm Password</InputLabel>
                            <Form.Item
                                name="confirm_password"
                                rules={[
                                    { required: true, message: 'Password is required' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                                style={{marginBottom: '10px', display: 'inline-block', width: '100%' }}
                            >
                                <InputPassword name="confirm_password" autoComplete="new-password" placeholder="Re-type New Password" style={{width: '100%'}}/>
                            </Form.Item>
                        </>) : null}
                        <Row>
                            <InputLabel>Phone Number</InputLabel>
                        </Row>
                        <Row>
                            <Col xs={{span: 8}}>
                                <Form.Item
                                    name="country_code"
                                    rules={[{ required: true, message: 'Country code is required' }]}
                                    style={{marginBottom: '10px'}}
                                    initialValue={currentUser.profile.country_code}
                                >
                                    <StyledSelect
                                        showSearch
                                        placeholder={'Country Code'}
                                        dropdownMatchSelectWidth={false}
                                        defaultValue={'+1'}
                                        options={Options.getPhoneCountryCodes()}
                                        optionLabelProp={'title'}
                                        filterOption={(input: any, option: any) => {
                                            return option.children.toLowerCase().includes(input.toLowerCase())
                                        }}
                                        onChange={() => {
                                            form.setFieldValue('phone_number', '')
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 15, offset: 1}}>
                                <Form.Item
                                    initialValue={currentUser.profile.phone_number}
                                    name="phone_number"
                                    rules={[
                                        { required: true, message: 'Phone Number is required' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if(value){
                                                    const phoneVal = getFieldValue('country_code').toString() + value.toString()
                                                    if(!phone(phoneVal).isValid){
                                                        return Promise.reject(new Error('Phone number is invalid'));
                                                    }
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject();
                                            },
                                        }),
                                    ]}
                                    style={{marginBottom: '10px'}}
                                    normalize={numberOnly}
                                >
                                    <InputText
                                        autoComplete='off'
                                        placeholder="Phone Number"
                                        onFocus={(e) => {
                                            e.currentTarget.maxLength = getPhoneNumberLength(form.getFieldValue('country_code')) || 13
                                        }}
                                        style={{width: '100%'}}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <InputLabel>Role</InputLabel>
                        <Form.Item
                            name="role_slug"
                            rules={[
                                { required: true, message: 'Role is required' },
                            ]}
                            style={{ marginBottom: '10px', display: 'inline-block', width: '100%' }}
                        >
                            <StyledSelect
                                disabled
                                value={currentUser.role.slug}
                                placeholder={'Select Role'}
                                options={rolesData.map((role: any) => ({
                                    label: role.name,
                                    value: role.slug
                                }))}
                            />
                        </Form.Item>
                        <Row>
                            <Col xs={{span: 12}}>
                                <InputLabel>Timezone</InputLabel>
                            </Col>
                            <Col xs={{span: 12}}>
                                <InputLabel style={{float: "right"}}>Current Time: <Clock format={'hh:mm A'} ticking={true} blinking={true} timezone={currentTimezone} /></InputLabel>
                            </Col>
                        </Row>
                        <Form.Item
                            name="timezone"
                            rules={[
                                { required: true, message: 'Timezone is required' },
                            ]}
                            style={{marginBottom: '10px'}}
                        >
                            <StyledSelect
                                showSearch
                                placeholder={'Display automatically but can be change'}
                                options={timezoneList}
                            />
                        </Form.Item>
                        {currentUser.role.slug === 'admin' ? <Form.Item
                            name="receive_appointment_notifications"
                            style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}
                        >
                            <Checkbox
                                checked={receiveNotif}
                                onChange={(value) => {
                                    setReceiveNotif(value.target.checked)
                                }} style={{margin: '0px 0px 3px 10px'}}>Receive all appointments notifications</Checkbox>
                        </Form.Item> : null}
                        <Form.Item
                            name="active"
                            style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}
                        >
                            <InputLabel>Active Status</InputLabel> <Switch checked={activeStatus} onChange={(value) => {setActiveStatus(value)}} size='small' style={{margin: '0px 0px 3px 10px'}}/>
                        </Form.Item>
                        <Button
                           danger
                           type={'primary'}
                           size={'large'}
                           style={{margin: 0}}
                           onClick={() => {
                               setShowDeleteAccountModal(true)
                           }}
                        >
                           Delete Account
                        </Button>
                    </>}
            </Form>
        </>
    )

    const ProfileContentRight = (
        <>
            {currentUser.organization?.onboarded_at && <Groups uid={currentUser.id}/>}
            {currentUser.organization?.onboarded_at && <Services uid={currentUser.id}/>}
            
            { showIntegration && <Integrations uid={currentUser.id} isViewing={formType === 'view'} /> }

        </>
    )

    const Profile = (
        <div style={{overflowY: 'auto', overflowX: 'hidden'}}>
            <Row style={{flex: '1 1 auto', overflow: 'auto', pointerEvents: formType === 'view'  ? 'none' : 'auto'}} gutter={24}>
                <Col span={12}>
                    {ProfileContentLeft}
                </Col>
                <Col span={12}>
                    {ProfileContentRight}
                </Col>
            </Row>
        </div>
    )

    const Body = (
        tabIndex === 1 ? Profile : tabIndex === 2 ? <Availability uid={currentUser.id} user={currentUser} /> : tabIndex === 3 ? <Plan onClose={onClose}/> : <></>
    )

    const customSchedForm = (
        <Modal visible={showCustomAvailabilityForm} width={500} onCancel={() => setShowCustomAvailabilityForm(false)}>
            <CustomAvailabilityForm onCancel={() => setShowCustomAvailabilityForm(false)} onSave={() => {setShowCustomAvailabilityForm(false)}} user={currentUser}/>
        </Modal>
    )

    return (
        <StyledModal visible={visible} width={1200} bodyStyle={{height: '80vh'}}>
            {showDeleteAccountModal ? DeleteAccountModal : null}
            {showDeletePromptSuccess ? DeleteSuccessPromptModal : null}
            {schedForm}
            {showCustomAvailabilityForm ? customSchedForm : null}
            { updatedSuccessVisible && UpdatedSuccessPrompt }
            <div style={{height: '100%', flexFlow: 'column', display: 'flex' }}>
                {Header}
                {Body}
            </div>
        </StyledModal>
    )
}

const StyledSelect = styled(Select)<{ isrounded?: boolean}>`
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: ${({ isrounded }) => isrounded ? '30px' : '10px'};
      height: 50px;
    }
  }

  .ant-select-selection-item {
    text-align: left;
    align-self: center;
  }

  .ant-select-selection-placeholder {
    text-align: left;
    align-self: center;
  }

  .ant-select-selection-search > input,
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }

`

const StyledModal = styled(Modal)`
  .ant-modal-content {
    height: 100%;

    .ant-modal-body {
      height: 80%;
    }
  }
`

export default UserProfile;
