import React, {useState, useEffect} from "react";
// import styled from 'styled-components'
import {Col, Form, Row, Space, 
    // Select
} from "antd";
// import Button from "../../components/reusables/Button";
import {
    // InputLabel, 
    Title, StyledSelect} from "../../components/CommonStyles";
// import {AiOutlineArrowLeft, AiOutlineClose} from "react-icons/ai";
// import {PlusOutlined} from "@ant-design/icons";
import Prompt from "src/components/reusables/Prompt";

import UserService from "src/services/UserService";
import GroupService from "src/services/GroupService";
import OrgService from "src/services/OrganizationService";
import  {useOrg} from 'src/hooks/useOrgContext'
import APIService from 'src/services/APIService'
import { useAuth } from 'src/hooks/useAuth'


function Groups({uid}: {uid: number}) {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [groups, setGroups] = useState<any[]>([])
    const [orgGroups, setOrgGroups] = useState<any[]>([])
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState<boolean>(false)
    const [selectedGroup] = useState<number>(0)//, setSelectedGroup

    const {
        saveEvent, 
        // setSaveEvent, 
        setSelectedGroups
    } = useOrg()
    const { currentUser } = useAuth()
    const {getGroups} = UserService()
    const {removeUserFromGroup} = GroupService()
    const {getOrgGroups} = OrgService()
    const {put} = APIService()

    const [form] = Form.useForm();

    useEffect(() => {
        getGroups(uid).then((groups) => {
            const groupData = groups.filter((group: any) => !group.personal)
            setGroups(groupData)

            form.setFieldValue('groups', groupData.map((gd: any) => gd.id))
        })

        getOrgGroups().then((resp: any) => {
            setOrgGroups(resp.filter((group: any) => !group.personal))
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log('save event', form.getFieldValue('groups'))
        const grps = form.getFieldValue('groups')

        if (grps) {
            put('users/' + uid + '/groups', {group_id: grps})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveEvent])


    const ConfirmToDeletePrompt = () => {
        return (
            <Prompt
                title="Remove Group"
                message={<>Are you sure you want to remove user from this group? </>}
                visible={confirmDeleteVisible}
                okButton="Delete"
                okFunction={() => {
                    removeUserFromGroup(uid, selectedGroup).then(() => {
                        setConfirmDeleteVisible(false)
                        getGroups(uid).then((resp) => setGroups(resp))
                    })
                }}
                danger={true}
                cancelButton="Cancel"
                cancelFunction={() => {
                    setConfirmDeleteVisible(false)
                }}
            />
        )
    }



    return (
    <>
        <ConfirmToDeletePrompt />
        <Space direction={'horizontal'} align={'center'}>
            <Title fontWeight={'400'} fontSize={'12'} style={{textAlign: 'left', margin: 0, width: 75}}>Group</Title>
            {/* <Button type={'primary'} style={{padding: '0px 5px', height: 'auto'}}><PlusOutlined /></Button> */}
        </Space>
        <Row>
            <Col>
            <Form
                disabled={currentUser?.role.slug === 'member'}
                form={form}
                name="user-form"
                style={{width: '100%', marginBottom: 15}}
                layout={'vertical'}
                onFinish={(values: any) => {
                    console.log(values)
                }}
            >
            <Form.Item name="groups" noStyle>
            <StyledSelect
                    disabled={currentUser.id !== uid && currentUser.role?.slug !== 'admin'}
                    mode="multiple"
                    placeholder={'Select Groups'}
                    options={orgGroups.map((grp) => ({
                        label: grp.name,
                        value: grp.id
                    }))}
                    style={{width: '330px'}}
                    onChange={(values: any) => {
                        console.log(values)
                        setSelectedGroups(orgGroups.filter((og: any) => values.includes(og.id)))
                    }}
                    height={'auto'}
                />
            </Form.Item>
            </Form>
            </Col>

            {/* {groups.map((grp) => (
            <Col>
                <Row style={{margin: '10px 5px 10px 0px', backgroundColor: '#B3EAFB', borderColor: '#9DE5FB', borderWidth: 1, borderStyle: 'solid', padding: '5px 10px', borderRadius: 5, display: 'flex', alignItems: 'center', position: 'relative'}}>
                    <Title fontWeight={'400'} fontSize={'12px'} style={{textAlign: 'left', margin: 0, color: '#81818D'}}>{grp.name}</Title>
                    <AiOutlineClose onClick={() => {
                        setSelectedGroup(grp.id)
                        setConfirmDeleteVisible(true)
                        }
                    } style={{color: '#81818D', marginLeft: 10}} size={15}/>
                </Row>
            </Col>
            ))} */}
        </Row>
    </>
    )
}

export default Groups

