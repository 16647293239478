import { Modal as AntDModal } from 'antd'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

function Modal({ bodyStyle ={}, mask = true, visible, closable = false, width = 400, title = '', maskClosable = true, onCancel = () => {}, children = <></>, className = '' }: {
    visible: boolean,
    closable?: boolean,
    width?: string | number
    title?: ReactNode
    maskClosable?: boolean
    onCancel?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
    children: ReactNode
    bodyStyle?: any
    mask?: boolean
    className?: string
}) {
  return (
    <CustomModal
        centered
        title={title}
        open={visible}
        closable={closable}
        width={width}
        footer={null}
        maskClosable={maskClosable}
        onCancel={onCancel}
        mask={mask}
        bodyStyle={{...bodyStyle}}
        className={className}
    >
        {children}
    </CustomModal>
  )
}

const CustomModal = styled(AntDModal)<{ padding?: string }>`

    .ant-modal-content {
        border-radius: 10px;

        .ant-modal-header {
            border-radius: 10px 10px 0 0;
            border: none;
        }

        .ant-modal-body {
            padding: 30px;
        }

        .ant-modal-footer {
            border: none;
        }
    }

`

export default Modal
