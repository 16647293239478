import React from "react";

import AdminManagementIcon from 'src/assets/sidebar-icons/admin-management.svg';
import OrganizationIcon from 'src/assets/sidebar-icons/organization.svg';
import SubscriptionIcon from 'src/assets/sidebar-icons/subscriptions.svg';
import HistoryLogsIcon from 'src/assets/sidebar-icons/history-logs.svg';
import ArchivesIcon from 'src/assets/sidebar-icons/archives.svg';
import UpdatePlanForm from "./subscriptions/UpdatePlanForm";

const Organization = React.lazy(() => import('src/pages/super-admin/organization'))
const HistoryLogs = React.lazy(() => import('src/pages/super-admin/history-logs/index'))
const ArchivedOrganizations = React.lazy(() => import('src/pages/super-admin/archived-organizations'))
const Subscriptions = React.lazy(() => import('src/pages/super-admin/subscriptions'))

const SuperAdminRoutes = [
 {
  sidebar: true,
  sidebarVisible: true,
  parent: true,
  id: 'super-admin',
  sidebarLabel: 'Super Admin Dashboard',
  icon: AdminManagementIcon,
 },
 {
  path: '/super-admin/organization',
  element: <Organization />,
  sidebar: true,
  sidebarVisible: true,
  parentId: 'super-admin',
  sidebarLabel: 'Organizations',
  mainLabel: 'Organizations',
  icon: OrganizationIcon,
 },
 {
  path: '/super-admin/subscriptions',
  element: <Subscriptions />,
  sidebar: true,
  sidebarVisible: true,
  parentId: 'super-admin',
  sidebarLabel: 'Subscriptions',
  mainLabel: 'Subscriptions',
  icon: SubscriptionIcon,
 },
 {
  path: '/super-admin/subscriptions/:id',
  exact: true,
  element: <UpdatePlanForm />,
  sidebar: true,
  mainLabel: 'Subscriptions',
 },
 {
  path: '/super-admin/activity-logs',
  element: <HistoryLogs />,
  sidebar: true,
  sidebarVisible: true,
  parentId: 'super-admin',
  sidebarLabel: 'History Logs',
  mainLabel: 'History',
  icon: HistoryLogsIcon,
 },
 {
  path: '/super-admin/archived-organizations',
  element: <ArchivedOrganizations />,
  sidebar: true,
  sidebarVisible: true,
  parentId: 'super-admin',
  sidebarLabel: 'Archived Organizations',
  mainLabel: 'Archived Organizations',
  icon: ArchivesIcon,
 },
];

export default SuperAdminRoutes;
