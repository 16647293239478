import { createGlobalStyle } from "styled-components";
import { bookingFormDefaultTheme, IThemeProps } from "../constants/theme-constants";

export type StyleProps = {
    theme: IThemeProps & typeof bookingFormDefaultTheme
}

export const GlobalStyles = createGlobalStyle`

    * {
        -webkit-overflow-scrolling: touch;
        font-family: "Poppins", Arial, Helvetica, sans-serif;
    }

    .grecaptcha-badge { visibility: hidden; }

    html {
        overflow: hidden;
    }

    body {
        overflow: auto;
        background: ${({ theme }: StyleProps) => theme.bgColor};
        color: ${({ theme }) => theme.colorText};
    }

    html, body, #root {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        margin: 0;
        font-size: 12px;
    }

    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var, b, u, i, center,
    dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td {
        margin: 0; padding: 0;  border: 0; outline: 0; font-size: 100%;
        vertical-align: baseline; box-sizing: border-box;
    }

    /* for safari */
    input[type="submit"] {
        -webkit-appearance: none;
    }

    .ant-form-item, h1, h2, h3, h4, h5, h6, input, .ant-input, .ant-select, .ant-select-item-option-selected:not(.ant-select-item-option-disabled), .ant-select-item,
    .ant-typography, .Toastify__toast-theme--light, .ant-modal {
        color: ${({ theme }) => theme.colorText};
    }

    .ant-form-item-explain-error {
        font-size: 12px;
        margin: 5px 5px 0px 5px;
    }


    /* THEME CLASS */

    .custombookingmodal {
        .ant-modal-body {
            background-color: ${({ theme }: StyleProps) => theme.background };
        }
    }

    .svgStrokeColorText {
        > path { stroke: ${({ theme }) => theme.colorText}; }
    }
    .svgFillColorText {
        > path { fill: ${({ theme }) => theme.colorText}; }
    }

    .colorPrimary {
        color: ${({ theme }: StyleProps) => theme.primaryColor};
    }

    .bgPrimary {
        background-color: ${({ theme }: StyleProps) => theme.primaryColor};
    }

    .colorDanger {
        color: #FF3C3C;
    }

    .colorWhite {
        color: #fff;
    }

    .colorText {
        color: ${({ theme }) => theme.colorText};
    }

   
    /* FONT WEIGHT CLASSES */

    .fw-300 {
        font-weight: 300;
    }

    .fw-400 {
        font-weight: 400;
    }

    .fw-500 {
        font-weight: 500;
    }

    .no-highlights {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }


    /* DISPLAY CLASSES */
    .displayFlex {
        display: flex;
    }

    .alignCenter {
        align-items: center;
    }

    .justifyCenter {
        justify-content: center;
    }

    .justifyFlexEnd {
        justify-content: flex-end;
    }

    /* MARGIN CLASSES */
    .mh-5 {
        margin-right: 5px;
        margin-left: 5px;
    }
    
    .mb-10 {
        margin-bottom: 10px;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .mt-15 {
        margin-top: 15px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .mt-30 {
        margin-top: 30px;
    }

    /* PADDING CLASSES */
    .ph-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    /* Custom styles */

    .custom-modal {
        .ant-modal-content {
            background: ${({ theme }: StyleProps) => theme.bgColor};
            border-radius: 10px;
            max-width: 400px;

            .ant-modal-confirm-body > span {
                display: none;
            }

            .ant-modal-confirm-content {
                margin: 0px;
                margin-left: 0px;
            }

            .ant-modal-confirm-btns {
                display: none;
            }
        }
    }
`
