import {Col, Form, Row, Space, 
    // Popover, 
    Tooltip
} from "antd";
// import {StyledTitle} from "src/pages/organization";
// import InfoIcon from 'src/assets/icons/InfoIcon.svg'

import React, {useState, useMemo, useEffect} from "react";
import {InputLabel, StyledSelect, SubTitle, Title} from "src/components/CommonStyles";
import Button from "src/components/reusables/Button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Prompt from "src/components/reusables/Prompt";
import {alphaNumericOnly, numberOnly, numericOnly, removeNonLetters} from "src/helpers/validations";
import Modal from "src/components/reusables/Modal";
import styled from "styled-components";
import RoleService from 'src/services/RoleService'
import Loader from "src/components/reusables/Loader";
import {InputText} from "src/components/reusables/TextInputs";
import Table from "src/components/reusables/Table";
import EyeIconBlack from "src/assets/icons/EyeIconBlack.svg";
import EditIcon from "src/assets/icons/EditIcon.svg";
import DeleteIcon from "src/assets/icons/DeleteIcon.svg";
import Checkbox from "src/components/reusables/Checkbox";
// import moment from "moment-timezone";
import momentTimezone from "moment-timezone";
// import {DDHHmm} from "src/helpers/datetime";
import APIService from "src/services/APIService";
import {FormType} from "src/pages/group";
import { useAuth } from 'src/hooks/useAuth'
import { capitalize } from 'lodash'

const UpdatePlanForm = () => {
    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const { checkModuleRoles } = RoleService()
    const {state} = useLocation();
    //const [form] = Form.useForm();
    const [UpdateProfileForm] = Form.useForm();
    const [priceForm] = Form.useForm();
    const [restrictionForm] = Form.useForm();
    const [updatedSuccessVisible, setUpdatedSuccessVisible] = useState<boolean>(false)
    const [isRecurring, setIsRecurring] = useState(true)
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false)

    const [pagination] = useState({}) // setPagination
    const [loading] = useState(false) // setLoading
    const [addPriceModal, setAddPriceModal] = useState(false)
    const [priceModal, setPriceModal] = useState(false)
    const [selectedPrice, setSelectedPrice] = useState<any>({})
    const [subscription, setSubscription] = useState<any>()
    const [restrictions, setRestrictions] = useState<any []>()
    const [pricings, setPricings] = useState<any []>([])
    const [formType, setFormType] = useState<FormType>('view')

    const {get, post, put, remove} = APIService()
    let {id} = useParams()

    const restrictionOptions = [
        {id: 1, label: 'Connect to Google, Office 365, Outlook, and iCloud calendars', checked: true, onChange: () => {}},
        {id: 2, label: 'Add Time Author to your website', checked: true, onChange: () => {}},
        {id: 3, label: 'Create unlimited Personal Booking Forms', checked: true, onChange: () => {}},
        {id: 4, label: 'Customize your Notifications', checked: true, onChange: () => {}},
        {id: 5, label: 'Automated Email Notifications', checked: true, onChange: () => {}},
        {id: 6, label: 'Automated Text Notifications', checked: true, onChange: () => {}},
        {id: 7, label: 'Add branding to your Booking Forms', checked: true, onChange: () => {}},
        {id: 8, label: 'Create unlimited Booking Forms for your organization', checked: true, onChange: () => {}},
        {id: 9, label: 'Manage Groups and Permissions', checked: true, onChange: () => {}},
        {id: 10, label: 'Manage your offered Services', checked: true, onChange: () => {}},
        {id: 11, label: 'Customize "Contact Us" page in your Services', checked: true, onChange: () => {}},
        {id: 12, label: 'Add branding to your Admin Portal', checked: true, onChange: () => {}},
        {id: 13, label: 'Activity Logs', checked: true, onChange: () => {}},
        {id: 14, label: 'Live Chat Support', checked: true, onChange: () => {}},
        {id: 15, label: 'Onboarding and implementation', checked: true, onChange: () => {}},
        {id: 16, label: 'Reports', checked: true, onChange: () => {}},
    ]

    const getPlan = async () => {
        get('subscription-plans/' + id, []).then((resp: any) => {
            setSubscription(resp)
            setPricings(resp.pricings)
            setSelectedPrice(null)

            UpdateProfileForm.setFieldsValue(resp)
            restrictionForm.setFieldsValue(resp)
            setRestrictions(resp.restrictions?.length ? resp.restrictions : restrictionOptions.map((restOpt) => {
                return {
                    label: restOpt.label,
                    checked: restOpt.checked
                }
            }))
        })
    }

    const updatePlan = async (values: any) => {
        put('subscription-plans/' + id,  {...values, restrictions}).then(() => {
            setUpdatedSuccessVisible(true)
        })
    }

    useEffect(() => {
        getPlan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(subscription)
    }, [subscription])

    const freeTrialDays = [];
    for (let i = 0; i <= 100; i++) {
        freeTrialDays.push({
            label: i.toString(),
            value: i
        });
    }


    const addPrice = async (values: any) => {
        const fData = {
            ...values,
            subscription_plan_id: id,
            recurring: isRecurring,
        }

        post('subscription-plans/add-price', fData).then(() => {
            setAddPriceModal(false)
            getPlan()
        })
        priceForm.resetFields()
    }

    const savePrice = async (values: any) => {
        const fData = {
            ...values,
            subscription_plan_id: id,
            recurring: isRecurring,
        }
        put('subscription-plans/update-price/' + selectedPrice.id, fData).then(() => {
            setPriceModal(false)
            getPlan()
        })
        priceForm.setFieldsValue({})
    }

    const deletePrice = async () => {
        remove('subscription-plans/delete-price/' + selectedPrice.id).then(() => {
            getPlan()
            setConfirmDeleteVisible(false)
        })
    }

    const ConfirmToDeletePrompt = () => {
        return (
            <Prompt
                title="Delete Price"
                message={<>Are you sure you want to delete price? <br/> This can't be undone.</>}
                visible={confirmDeleteVisible}
                okButton="Delete"
                okFunction={() => {
                    deletePrice()
                }}
                danger={true}
                cancelButton="Cancel"
                cancelFunction={() => {
                    setConfirmDeleteVisible(false)
                }}
            />
        )
    }

    const Header = (
        <Row style={{flex: '0 1 auto', marginBottom: 20}}>
            <Col span={24} style={{textAlign: 'right'}}>
                <Button size={'large'} style={{width: 100, margin: '0px 5px'}} onClick={() => navigate('/super-admin/subscriptions')}>
                    Cancel
                </Button>
                {checkModuleRoles('users', ['update', 'create']) &&
                <>
                    {state?.type === 'view' ? <>{
                        id !== "1" && <Button
                        type={"primary"} size={'large'} style={{width: 100, margin: '0px 5px'}} onClick={() => {
                        navigate(`/super-admin/subscriptions/${id}`, {state: {type: 'update'}})
                    }}>
                        Edit
                    </Button>}</> :
                        <Button
                            type={"primary"} size={'large'}
                            style={{width: 100, margin: '0px 5px'}}
                            onClick={() => {
                                UpdateProfileForm.submit()
                            }}>
                            Save
                        </Button>}
                </>
                }
            </Col>
        </Row>
    )

    const UpdatedSuccessPrompt = useMemo(() => {
        return (
            <Prompt
                success
                title={"Plan Updated"}
                message={`You have successfully updated the plan`}
                visible={updatedSuccessVisible}
                okButton="OK"
                okFunction={() => {
                    setUpdatedSuccessVisible(false)
                    navigate('/super-admin/subscriptions')
                }}
            />
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedSuccessVisible])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const columns: any = [
        {
            title: 'Subscription Plan',
            dataIndex: 'subscriptionPlan',
            width: 150,
            sorter: (a: any, b: any) => a.subscriptionPlan - b.subscriptionPlan,
            render: (data: any, row: any) => {
                return (
                    <Tooltip placement="topLeft" title={data}>
                        { data }
                    </Tooltip>
                )},
            responsive: ['md'],
        },
        {
            title: 'Billing Period',
            dataIndex: 'period',
            width: 300,
            align: 'center',
            sorter: (a: any, b: any) => a.period - b.period,
            render: (data: any, row: any) => {
                return (
                    <Tooltip placement="topLeft" title={data}>
                        {data}
                    </Tooltip>
                )},
            responsive: ['md'],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 100,
            align: 'center',
            sorter: (a: any, b: any) => a.status - b.status,
            render: (data: string) => (
                <span style={{color: data === 'Active' ? '#327812' : '#F50D0D'}}>
                    <Tooltip placement="topLeft" title={data}>
                        {data}
                    </Tooltip>
                </span>
            ),
        },
        {
            title: 'Date Created',
            dataIndex: 'created_at',
            width: 200,
            align: 'center',
            sorter: (a: any, b: any) => a.created_at - b.created_at,
            render: (data: string) => (
                <Tooltip placement="topLeft" title={data}>
                    {data}
                </Tooltip>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            sorter: true,
            render: (data: any, row: any) => (
                <div style={{flexFlow: 'column'}}>
                    <Tooltip placement="top" title={'View'}>
                        <Button
                            type={'text'}
                            style={{padding: 0}}
                            onClick={() => {
                            }}>
                            <img src={EyeIconBlack} alt=""/>
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={'Edit'}>
                        <Button
                            type={'text'}
                            style={{padding: 0}}
                            onClick={() => {
                            }}
                        >
                            <img src={EditIcon} alt=""/>
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={'Delete'}>
                        <Button
                            type={'text'} style={{padding: 0}}
                            onClick={() => {
                            }}
                        >
                            <img src={DeleteIcon} alt=""/>
                        </Button>
                    </Tooltip>
                </div>
            )
        },
    ];

    const priceColumns: any = [
        {
            title: 'Price($)',
            dataIndex: 'price',
            width: 150,
            sorter: (a: any, b: any) => a.price - b.price,
            render: (data: any, row: any) => {
                return (
                    <Tooltip placement="topLeft" title={data}>
                        ${ data.toFixed(2) }
                    </Tooltip>
                )},
            responsive: ['md'],
        },
        {
            title: 'Billing Period',
            dataIndex: 'billing_period',
            width: 150,
            align: 'center',
            sorter: (a: any, b: any) => a.billing_period - b.billing_period,
            render: (data: any, row: any) => {
                return (
                    <Tooltip placement="topLeft" title={data}>
                        {capitalize(data)}
                    </Tooltip>
                )},
            responsive: ['md'],
        },
        {
            title: 'Free Trial',
            dataIndex: 'days_free_trial',
            width: 100,
            align: 'center',
            sorter: (a: any, b: any) => a.days_free_trial - b.days_free_trial,
            render: (data: any) => (
                <Tooltip placement="topLeft" title={data}>
                    {data ? `${data} day${(data > 1) ? 's' : null}` : 'Disabled'}
                </Tooltip>
            ),
        },
        {
            title: 'Subscribers',
            dataIndex: 'subscribers',
            width: 100,
            align: 'center',
            sorter: (a: any, b: any) => a.days_free_trial - b.days_free_trial,
            render: (data: string) => (
                <span style={{color: data === 'Active' ? '#327812' : '#F50D0D'}}>
                    <Tooltip placement="topLeft" title={data}>
                        {data || 'None'}
                    </Tooltip>
                </span>
            ),
        },
        {
            title: 'Date Created',
            dataIndex: 'created_at',
            width: 200,
            align: 'center',
            sorter: (a: any, b: any) => a.created_at - b.created_at,
            render: (data: string) => (
                <Tooltip placement="topLeft" title={data}>
                    {momentTimezone(data).tz(currentUser.timezone).format('MMM D, YYYY hh:mmA')}
                </Tooltip>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            width: 200,
            sorter: true,
            render: (data: any, row: any) => (
                <div style={{flexFlow: 'column'}}>
                    <Tooltip placement="top" title={'View'}>
                        <Button
                            type={'text'}
                            style={{padding: 0}}
                            onClick={() => {
                                setSelectedPrice(row)
                                setIsRecurring(row.recurring)
                                setPriceModal(true)
                                setFormType('view')
                            }}>
                            <img src={EyeIconBlack} alt=""/>
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={'Edit'}>
                        <Button
                            type={'text'}
                            style={{padding: 0}}
                            onClick={() => {
                                setSelectedPrice(row)
                                setIsRecurring(row.recurring)
                                setPriceModal(true)
                                setFormType('update')
                            }}
                        >
                            <img src={EditIcon} alt=""/>
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={'Delete'}>
                        <Button
                            type={'text'} style={{padding: 0}}
                            onClick={() => {
                                setSelectedPrice(row)
                                setConfirmDeleteVisible(true)
                            }}
                        >
                            <img src={DeleteIcon} alt=""/>
                        </Button>
                    </Tooltip>
                </div>
            )
        },
    ];

    const tableLocale = {
        emptyText: 'Empty Pricing'
    }

    const AddPriceModal = (
            <Modal visible={addPriceModal} width={500} onCancel={() => setAddPriceModal(false)}>
                <Title style={{margin: '5px'}}>Add Price</Title>
                <Form
                    form={priceForm}
                    name="plan-form"
                    style={{width: '100%', marginTop: 20}}
                    initialValues={{}}
                    layout={'vertical'}
                    onFinish={(values: any) => {
                        addPrice(values)
                    }}
                >
                    <InputLabel>Price</InputLabel>
                    <Form.Item
                        name="price"
                        rules={[
                            {required: true, message: 'Price is required'},
                        ]}
                        style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                    >
                        <InputText onKeyPress={numericOnly} autoComplete='off' placeholder="$0.00" style={{width: '100%'}} maxLength={50}/>
                    </Form.Item>
                    <Row gutter={8} style={{marginBottom: 10}}>
                        <Col span={12}>
                            <Button type={'ghost'} size={'large'} style={{color: isRecurring ? '#18B0CF' : '#000', borderColor: isRecurring ? '#18B0CF' : '#000', width: '100%', margin: 0, float: 'right'}} onClick={() => {
                                setIsRecurring(true)
                            }}>
                                Recurring
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button type={'ghost'} size={'large'} style={{color: !isRecurring ? '#18B0CF' : '#000', borderColor: !isRecurring ? '#18B0CF' : '#000',width: '100%', margin: 0, float: 'right'}} onClick={() => {
                                setIsRecurring(false)
                            }}>
                                One Time
                            </Button>
                        </Col>
                    </Row>
                    {isRecurring ? (
                        <>
                            <InputLabel>Billing Period</InputLabel>
                            <Form.Item
                                name="billing_period"
                                rules={[
                                    {required: true, message: 'Billing Period is required'},
                                ]}
                                style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                            >
                                <StyledSelect
                                    placeholder={'Select a Billing Period'}
                                    options={[
                                        {label: 'Monthly', value: 'monthly'},
                                        {label: 'Annually', value: 'annually'},
                                    ]}
                                    onChange={(e: any) => {}}
                                />
                            </Form.Item>
                        </>
                    ) : null}
                    <InputLabel>Free Trial (Days)</InputLabel>
                    <Form.Item
                        name="days_free_trial"
                        rules={[
                            {required: true, message: 'Free Trial is required'},
                        ]}
                        style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                    >
                        <StyledSelect
                            placeholder={'Select a Free Trial Days'}
                            options={freeTrialDays}
                            onChange={(e: any) => {}}
                        />
                    </Form.Item>
                        <Row gutter={16}>
                            <Col xs={{span: 12}}>
                                <Button size={'large'} style={{width: '100%', height: 50, margin: 0}} onClick={() => {
                                    priceForm.resetFields()
                                    setAddPriceModal(false)
                                }}>Cancel</Button>
                            </Col>
                            <Col xs={{span: 12}}>
                                <Button type={'primary'} size={'large'} style={{width: '100%', height: 50, margin: 0}} onClick={() => {
                                    priceForm.validateFields().then(() => {
                                        priceForm.submit()
                                    })
                                }}>Save</Button>
                            </Col>
                        </Row>
                </Form>
            </Modal>
        )

    const PriceModal = (
        <Modal visible={priceModal} width={500} onCancel={() => setPriceModal(false)}>
            <Title style={{margin: '5px'}}>Price</Title>
            <Form
                form={priceForm}
                name="plan-form"
                style={{width: '100%', marginTop: 20}}
                initialValues={selectedPrice}
                layout={'vertical'}
                onFinish={(values: any) => {
                    savePrice(values)
                }}
            >
                <InputLabel>Price</InputLabel>
                <Form.Item
                    name="price"
                    rules={[
                        {required: true, message: 'Price is required'},
                    ]}
                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                >
                    <InputText
                        disabled={formType === 'view'}
                        onKeyPress={numericOnly}
                        autoComplete='off'
                        placeholder="$0.00"
                        style={{width: '100%'}}
                        maxLength={50}/>
                </Form.Item>
                <Row gutter={8} style={{marginBottom: 10}}>
                    <Col span={12}>
                        <Button disabled={formType === 'view'} type={'ghost'} size={'large'} style={{color: isRecurring ? '#18B0CF' : '#000', borderColor: isRecurring ? '#18B0CF' : '#000', width: '100%', margin: 0, float: 'right'}} onClick={() => {
                            setIsRecurring(true)
                        }}>
                            Recurring
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button disabled={formType === 'view'} type={'ghost'} size={'large'} style={{color: !isRecurring ? '#18B0CF' : '#000', borderColor: !isRecurring ? '#18B0CF' : '#000',width: '100%', margin: 0, float: 'right'}} onClick={() => {
                            setIsRecurring(false)
                        }}>
                            One Time
                        </Button>
                    </Col>
                </Row>
                {isRecurring ? (
                    <>
                        <InputLabel>Billing Period</InputLabel>
                        <Form.Item
                            name="billing_period"
                            rules={[
                                {required: true, message: 'Billing Period is required'},
                            ]}
                            style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                        >
                            <StyledSelect
                                disabled={formType === 'view'}
                                placeholder={'Select a Billing Period'}
                                options={[
                                    {label: 'Monthly', value: 'monthly'},
                                    {label: 'Annually', value: 'annually'},
                                ]}
                                onChange={(e: any) => {}}
                            />
                        </Form.Item>
                    </>
                ) : null}
                <InputLabel>Free Trial (Days)</InputLabel>
                <Form.Item
                    name="days_free_trial"
                    rules={[
                        {required: true, message: 'Free Trial is required'},
                    ]}
                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                >
                    <StyledSelect
                        disabled={formType === 'view'}
                        placeholder={'Select a Free Trial Days'}
                        options={freeTrialDays}
                        onChange={(e: any) => {}}
                    />
                </Form.Item>
                <div style={{height: '10%'}}>
                    <SubTitle style={{textAlign: 'left', marginBottom: 0}}>Date Created: {momentTimezone(selectedPrice?.created_at).tz(currentUser.timezone).format('MMM D, YYYY hh:mm A')}</SubTitle>
                    <SubTitle style={{textAlign: 'left'}}>Date Updated: {momentTimezone(selectedPrice?.updated_at).tz(currentUser.timezone).format('MMM D, YYYY hh:mm A')}</SubTitle>
                </div>
                    <Row gutter={16}>
                        <Col xs={{span: 12}}>
                            <Button size={'large'} style={{width: '100%', height: 50, margin: 0}} onClick={() => {
                                priceForm.resetFields()
                                setPriceModal(false)
                            }}>Cancel</Button>
                        </Col>
                        <Col xs={{span: 12}}>
                          {formType === 'view' ? 
                            <Button type={'primary'} size={'large'} style={{width: '100%', height: 50, margin: 0}} onClick={() => {
                                setFormType('update')
                            }}>Edit</Button> :
                            <Button type={'primary'} size={'large'} style={{width: '100%', height: 50, margin: 0}} onClick={() => {
                                priceForm.validateFields().then(() => {
                                    priceForm.submit()
                                })
                            }}>Save</Button>
                          }

                        </Col>
                    </Row>
            </Form>
        </Modal>
    )

    const Body = (
        <div style={{overflowY: 'auto', overflowX: 'hidden'}}>
            <div style={{ overflowY: 'auto', overflowX: 'hidden', height: "calc(100% - 60px)", pointerEvents: state?.type && state?.type === 'view'  ? 'none' : 'auto'}} key="update-user-profile">
                {state?.type === 'view'  ? <Row>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}} style={{padding: 5}}>
                        <Row>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 8}}>
                                <InputLabel style={{margin: '10px 0px 0px 0px', fontSize: 14}}>Plan Name</InputLabel>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 16}}>
                                <InputLabel style={{margin: 10, fontSize: 14}}>{subscription?.name}</InputLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 8}}>
                                <InputLabel style={{margin: '10px 0px 0px 0px', fontSize: 14}}>Description</InputLabel>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 16}}>
                                <InputLabel style={{margin: 10, fontSize: 14}}>{subscription?.description}</InputLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 8}}>
                                <InputLabel style={{margin: '10px 0px 0px 0px', fontSize: 14}}>Sequence</InputLabel>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 16}}>
                                <InputLabel style={{margin: 10, fontSize: 14}}>{subscription?.sequence}</InputLabel>
                            </Col>
                        </Row>
                    </Col>
                </Row> :
                <Row style={{flex: '1 1 auto'}} gutter={24}>
                    <Col span={24}>
                        <>
                            <Form
                                initialValues={subscription}
                                form={UpdateProfileForm}
                                name="update-profile-form"
                                style={{width: '40%'}}
                                layout={'vertical'}
                                onFinish={(values) => {
                                    let restrictData: any = restrictionForm.getFieldsValue()
                                    console.log('restrictData', restrictData)
                                    // let filteredRestrictions: any = restrictData
                                    // // eslint-disable-next-line no-sequences
                                    // const yuttori: any = ['max_users', 'max_groups', 'max_services', 'max_booking_forms', 'calendars_per_user'].map((rest: any) => {
                                    //     restrictData = {
                                    //         ...restrictData,
                                    //         [rest]: parseInt(restrictData[rest] || 0) 
                                    //     }

                                    //     return true // ['number', 'bigint'].indexOf(typeof restrictData[rest]) === -1 ? 0 : restrictData[rest] }
                                    // })

                                    Object.keys(restrictData).map((key: any) => {
                                        return restrictData = {
                                            ...restrictData,
                                            [key]: parseInt(restrictData[key] || 0) 
                                        }
                                    })
                                    // console.log('values', values)
                                    // console.log('restrictData', restrictData)
                                    updatePlan({...values, ...restrictData})
                                }}
                            >
                                <InputLabel>Plan Name</InputLabel>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {required: true, message: 'Plan Name is required'},
                                    ]}
                                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                                >
                                    <InputText 
                                        onKeyPress={alphaNumericOnly} 
                                        onKeyDown={alphaNumericOnly} 
                                        onInput={removeNonLetters} 
                                        autoComplete='off' 
                                        placeholder="Plan Name" 
                                        style={{width: '100%'}} 
                                        maxLength={50}
                                        disabled={id === "1"}
                                    />
                                </Form.Item>
                                <InputLabel>Description</InputLabel>
                                <Form.Item
                                    name="description"
                                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                                >
                                    <InputText name="description" autoComplete='off' placeholder="Description" style={{width: '100%'}} maxLength={50}/>
                                </Form.Item>
                                {/* <InputLabel>
                                    Sequence
                                    <Popover
                                        content={
                                            <Col style={{width: 300}}>
                                                <StyledTitle style={{textAlign: 'left'}} fontSize={'12px'} fontWeight={'600'}>
                                                    Plan display order
                                                </StyledTitle>
                                                <StyledTitle style={{textAlign: 'left'}} fontSize={'12px'} fontWeight={'500'}>
                                                    Will display in public viewing. 1 is for the Free Plan and use Custom sequence when creating specific plan for the customers
                                                </StyledTitle>
                                            </Col>
                                        }
                                        placement={'right'}
                                        trigger={"hover"}
                                    >
                                        <img style={{margin: 'auto 10px', cursor: 'pointer'}} src={InfoIcon} alt={'info'}/>
                                    </Popover>
                                </InputLabel>
                                <Form.Item
                                    name="sequence"
                                    rules={[
                                        {required: true, message: 'Sequence is required'},
                                    ]}
                                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                                >
                                    <StyledSelect
                                        placeholder={'Select a Sequence'}
                                        options={[1, 2, 3, 4, 5, 6, 7].map(item => (
                                            {
                                                label: ''+item,
                                                value: item
                                            }
                                        ))}
                                        onChange={(e: any) =>{}}
                                    />
                                </Form.Item> */}
                            </Form>
                        </>
                    </Col>
                </Row>}
                {AddPriceModal}
                {PriceModal}
            {
                id !== "1" &&
                <Row style={{flex: '1 1 auto'}} gutter={24}>
                    <Col span={12}>
                        <Title fontWeight={'500'} style={{textAlign: 'left', marginTop: 6}}>Pricing</Title>
                    </Col>
                    <Col span={12}>
                        <Button type={'ghost'} size={'large'} style={{color: '#18B0CF', borderColor: '#18B0CF', width: 150, margin: 0, float: 'right'}} onClick={() => {setAddPriceModal(true)}}>
                            Add Price
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Table hideOnSinglePage showSorterTooltip={false} columns={priceColumns} dataSource={pricings} locale={tableLocale} paginationProps={pagination}/>
                    </Col>
                </Row>
            }
                <Form
                    initialValues={subscription}
                    form={restrictionForm}
                    name="restriction-form"
                    style={{width: '40%', marginTop: 20}}
                    layout={'vertical'}
                    onFinish={(values) => {
                        console.log('restrictData', values)
                    }}
                >
                    <Row style={{flex: '1 1 auto'}} gutter={24}>
                        <Col span={24}>
                            <Title fontWeight={'500'} style={{textAlign: 'left', marginTop: 6, marginBottom: 0 }}>Restrictions</Title>
                            <InputLabel style={{fontWeight: '400', marginBottom: 15}}>Set "0" for Unlimited value</InputLabel>
                        </Col>
                        <Col span={24}>
                            {state?.type === 'view' ? <>
                                <Row>
                                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 8}}>
                                        <InputLabel style={{margin: '10px 0px 0px 0px', fontSize: 14}}>Max Users</InputLabel>
                                    </Col>
                                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 16}}>
                                        <InputLabel style={{margin: 10, fontSize: 14}}>{subscription?.max_users || 'unlimited'}</InputLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 8}}>
                                        <InputLabel style={{margin: '10px 0px 0px 0px', fontSize: 14}}>Max Groups</InputLabel>
                                    </Col>
                                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 16}}>
                                        <InputLabel style={{margin: 10, fontSize: 14}}>{subscription?.max_groups || 'unlimited'}</InputLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 8}}>
                                        <InputLabel style={{margin: '10px 0px 0px 0px', fontSize: 14}}>Max Services</InputLabel>
                                    </Col>
                                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 16}}>
                                        <InputLabel style={{margin: 10, fontSize: 14}}>{subscription?.max_services || 'unlimited'}</InputLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 8}}>
                                        <InputLabel style={{margin: '10px 0px 0px 0px', fontSize: 14}}>Max Booking Forms</InputLabel>
                                    </Col>
                                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 16}}>
                                        <InputLabel style={{margin: 10, fontSize: 14}}>{subscription?.max_booking_forms || 'unlimited'}</InputLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 16}}>
                                        <InputLabel style={{margin: '10px 0px 0px 0px', fontWeight: 'bold'}}>Calendar Connections per person</InputLabel>
                                    </Col>
                                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 8}}>
                                        <InputLabel style={{margin: 10, float: 'right'}}>{subscription?.calendars_per_user || 'unlimited'}</InputLabel>
                                    </Col>
                                </Row>
                                </> : <>
                                <InputLabel>Users</InputLabel>
                                <Form.Item
                                    name="max_users"
                                    rules={[
                                    ]}
                                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                                    normalize={numberOnly}
                                >
                                    <InputText defaultValue={1} autoComplete='off' placeholder="Max users"
                                               style={{width: '100%'}} maxLength={50}/>
                                </Form.Item>
                                <InputLabel>Groups</InputLabel>
                                <Form.Item
                                    name="max_groups"
                                    rules={[
                                    ]}
                                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                                    normalize={numberOnly}
                                >
                                    <InputText defaultValue={1} autoComplete='off' placeholder="Max groups"
                                               style={{width: '100%'}} maxLength={50}/>
                                </Form.Item>
                                <InputLabel>Services</InputLabel>
                                <Form.Item
                                    name="max_services"
                                    rules={[
                                    ]}
                                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                                    normalize={numberOnly}
                                >
                                    <InputText defaultValue={1} autoComplete='off' placeholder="Max services"
                                               style={{width: '100%'}} maxLength={50}/>
                                </Form.Item>
                                <InputLabel>Booking Forms</InputLabel>
                                <Form.Item
                                    name="max_booking_forms"
                                    rules={[
                                    ]}
                                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                                    normalize={numberOnly}
                                >
                                    <InputText defaultValue={1} autoComplete='off' placeholder="Max booking forms"
                                               style={{width: '100%'}} maxLength={50}/>
                                </Form.Item>
                                <InputLabel>Calendar connections per person</InputLabel>
                                <Form.Item
                                    name="calendars_per_user"
                                    rules={[
                                        // {required: true, message: 'Number of Calendar connections is required'},
                                    ]}
                                    style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                                    normalize={numberOnly}
                                >
                                    <InputText defaultValue={1} autoComplete='off'
                                               placeholder="Calendar connections per person" style={{width: '100%'}}
                                               maxLength={50}/>
                                </Form.Item>
                            </>}

                            <Form.Item
                                style={{marginBottom: '10px', display: 'inline-block', width: '100%'}}
                            >
                                <Space direction={'vertical'} style={{margin: '10px 0', width: '100%'}}>
                                    {restrictions && restrictions.map(({label, checked}) => {
                                        return (
                                            <Row justify={'space-between'} align={'middle'}>
                                                <Col>
                                                    <Title fontSize={'14px'} style={{marginBottom: 0}}>{label}</Title>
                                                </Col>
                                                <Col>
                                                    <StyledCheckBox style={{float: 'right'}} defaultChecked={checked}
                                                                    onChange={(value) => {
                                                                        // console.log(value.target.checked)
                                                                        setRestrictions(
                                                                            restrictions.map((rest) => {
                                                                                if (rest.label === label) {
                                                                                    rest.checked = value.target.checked
                                                                                }
                                                                                return rest
                                                                            })
                                                                        )
                                                                    }}/>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )

    return (
        <>
            <Col span={24} style={{height: '100%'}}>
                {loading ? <Loader dataLoader fixed/> : null}
                <ConfirmToDeletePrompt />
                { updatedSuccessVisible && UpdatedSuccessPrompt }
                {Header}
                <div style={{ overflowY: 'auto', overflowX: 'hidden', height: "calc(100% - 130px)"}}>
                    {Body}
                </div>
                {<div style={{height: '50px'}}>
                    <SubTitle style={{textAlign: 'left', margin: '10px 0px 0px 0px'}}>Date Created: {momentTimezone(subscription?.created_at).tz(currentUser.timezone).format('MMM D, YYYY hh:mmA')}</SubTitle>
                    <SubTitle style={{textAlign: 'left'}}>Date Updated: {momentTimezone(subscription?.updated_at).tz(currentUser.timezone).format('MMM D, YYYY hh:mmA')}</SubTitle>
                </div>}
            </Col>
        </>
    )
}

const StyledCheckBox = styled(Checkbox)<{fontSize?: string, color?: string, fontWeight?: string}>`
  border-radius: 50%;
  border-width: 2px;
  
  &.ant-checkbox-wrapper .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #327812;
    border-radius: 50%;
    border-width: 2px;
    width: 20px;
    height: 20px;
    color: #18B0CF;
  }

  .ant-checkbox-checked:not(.ant-checkbox-disabled) {
    &::after {
      border-color: #327812;
      border-radius: 50%;
      border-width: 2px;
      color: #327812;
    }

    .ant-checkbox-inner {
      background-color: #ffffff;
      border-color: #327812;
      border-width: 2px;
      border-radius: 50%;
      color: #327812;
    }
  }

  &.ant-checkbox-wrapper .ant-checkbox-inner {
    &::after{
      border-color: #327812;
    }
  }
`

export default UpdatePlanForm;
