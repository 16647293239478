import React, {useState} from "react";
import { Col, Divider, Form, Row } from "antd";
import {Title} from "../CommonStyles";
import {Center} from "src/components/CommonStyles";
import Button from "../reusables/Button";
import UsersWhiteIcon from "src/assets/icons/UsersWhiteIcon.svg";
import UsersIcon from "src/assets/sidebar-icons/users.svg";
import BillingIcon from "src/assets/icons/BillingIcon.svg";
import HelpCenterIcon from "src/assets/icons/HelpCenterIcon.svg";
import LogoutIcon from "src/assets/icons/LogoutIcon.svg";
import {FiX} from 'react-icons/fi'

import { useAuth } from 'src/hooks/useAuth'


const ProfileDropdown = ({onClose, setOpenProfile}: {
    onClose: () => void,
    setOpenProfile: () => void
}) => {
    const { currentUser, signOut } = useAuth()

    return (
        <div style={{backgroundColor: "white", padding: '15px 30px', width: 300, borderRadius: 10, boxShadow: `0px 1px 8px rgba(0, 0, 0, 0.4)`}}>
            <Row style={{width: '100%', display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <Col>
                    <Title style={{float: 'left', margin: 0}} fontWeight={'400'}>Profile</Title>
                </Col>
                <Col flex={'auto'}>
                    <FiX size={20} style={{float: 'right'}} onClick={onClose}/>
                </Col>
            </Row>
            <Center style={{width: '100%'}}>
                <Center style={{width: '90%'}}>
                    {currentUser.profile.avatar?.url ? <img src={currentUser.profile.avatar?.url} width={80}
                                                            height={80} alt="user" style={{height: 80, width: 80, borderRadius: 100, alignItems: 'center'}}/> : <Button style={{height: 80, width: 80, borderRadius: 100, alignItems: 'center'}}
                             type={'primary'}>
                                        <span style={{
                                            backgroundColor: '#363B45',
                                            width: 50,
                                            height: 50,
                                            borderRadius: 50,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <img src={currentUser.profile.avatar?.url || UsersWhiteIcon} width={25}
                                                 height={25} alt="user"/>
                                        </span>
                    </Button>}
                    <Title style={{marginBottom: 5}} fontWeight={'500'} fontSize={'14px'}>
                        { currentUser.profile.first_name } {currentUser.profile.last_name }
                    </Title>
                    <Title fontWeight={'400'} fontSize={'12px'}>
                        { currentUser?.email?.toLowerCase() }
                    </Title>
                    <Divider style={{margin: 5}}/>
                    <Button type={'text'} style={{width: '100%', textAlign: 'left', alignItems: 'center', display: 'flex'}}
                            onClick={(e) => {
                                e.preventDefault();
                                setOpenProfile()
                                onClose()
                            }}
                    >
                        <img src={UsersIcon} alt={'users'} style={{marginRight: 15}}/> <Title style={{margin: 0}} fontWeight={'500'}>Profile</Title>
                    </Button>
                    {/* HIDE FOR NOW - https://app.clickup.com/t/3xxz1ha */}
                    {/* <Button type={'text'} style={{width: '100%', textAlign: 'left', alignItems: 'center', display: 'flex'}}>
                        <img src={BillingIcon} alt={'users'} style={{marginRight: 15, width: 20}}/> <Title style={{margin: 0}} fontWeight={'500'}>Billing</Title>
                    </Button>
                    <Button type={'text'} style={{width: '100%', textAlign: 'left', alignItems: 'center', display: 'flex'}}>
                        <img src={HelpCenterIcon} alt={'Help Center'} style={{marginRight: 15, width: 20}}/> <Title style={{margin: 0}} fontWeight={'500'}>Help center</Title>
                    </Button> */}
                    <Divider style={{margin: 5}}/>
                    <Button onClick={() => signOut()} type={'text'} style={{width: '100%', textAlign: 'left', alignItems: 'center', display: 'flex'}}>
                        <img src={LogoutIcon} alt={'users'} style={{marginRight: 15, width: 20}}/> <Title style={{margin: 0, color: '#FF5555'}} fontWeight={'500'}>Log Out</Title>
                    </Button>
                </Center>
                <Title style={{color: 'rgba(0,0,0,0.5)', margin: 0, textAlign: 'right', width: '100%'}} fontWeight={'500'} fontSize={'10px'}>v {process.env.REACT_APP_VERSION}</Title>
            </Center>
        </div>
    )
}

export default ProfileDropdown;
