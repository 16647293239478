import React from "react"
import { GlobalModalProps } from "./global-modal"

interface IDefaultValue {
    isAuthenticated: boolean,
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
    isCookied: boolean,
    setIsCookied: React.Dispatch<React.SetStateAction<boolean>>,
    currentUser: any,
    setCurrentUser: React.Dispatch<React.SetStateAction<object>>,
    globalPrompt: GlobalModalProps | null, 
    setGlobalPrompt: React.Dispatch<React.SetStateAction<any>>,
    signUp: Function,
    signIn: Function,
    signOut: Function,
    refreshUser: Function,
    userIs: Function,
    isOwner: Function,
    checkRestriction: Function,
    checkExtraRestriction: Function,
}

export const defaultValue = {
    isAuthenticated: false,
    setIsAuthenticated: () => {},
    isCookied: false,
    setIsCookied: () => {},
    currentUser: null,
    setCurrentUser: () => {},
    globalPrompt: null, 
    setGlobalPrompt: () => {},
    signUp: () => {},
    signIn: () => {},
    signOut: () => {},
    refreshUser: () => {},
    userIs: () => {},
    isOwner: () => {},
    checkRestriction: () => {},
    checkExtraRestriction: () => {}
} as IDefaultValue