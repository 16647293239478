import { useState } from 'react';

import { THEMES } from '../constants/theme-constants';

const useTheme = () => {
    const [theme, setTheme] = useState(THEMES.LIGHT);

    const themeToggler: any = () => {
        theme === THEMES.LIGHT ? setTheme(THEMES.DARK) : setTheme(THEMES.LIGHT);
    };
    
    return [theme, themeToggler];
};

export default useTheme;