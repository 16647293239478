import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {  Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext } from 'src/context/AuthContext';


const AppRouter = ({ routes }: any) => {
  const { isAuthenticated } = useContext(AuthContext);

  const DefaultPage = () => {
    return isAuthenticated ? (
      <Navigate to={'/'} />
    ) : (
      <Navigate to="/login" />
    );
  };

  return (
    <Routes>
      {
        routes.map((route: any, index: number) => {
            // Children mapping
            if(route?.children?.length) {
                return <Route key={`route-${index}`} index={route.index} path={route.path} element={route.element}>
                    {
                        route.children.map((child: any, cIndex: number) => {
                            return <Route key={`route-c-${cIndex}`} index={child.index} path={child.path} element={child.element} />
                        })
                    }
                </Route>
            } else {
                return <Route key={`route-${index}`} index={route.index} path={route.path} element={route.element} />
            }
        })
    }
      <Route path="*" element={<DefaultPage />} />
    </Routes>
  );
};

AppRouter.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default AppRouter;
