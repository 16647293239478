import React, { createContext, useState } from 'react'

import { defaultValue } from 'src/constants/auth-constants'
import { GlobalModalProps } from 'src/constants/global-modal'
import APIService from 'src/services/APIService'

const AuthContext = createContext(defaultValue);
const Provider = AuthContext.Provider;
const AuthConsumer = AuthContext.Consumer
const { post } = APIService();

const Auth = (props: any) => {

  const auth = localStorage.token
    ? !!JSON.parse(localStorage.token)
    : false;

  const user = (localStorage.user !== undefined)
    ? JSON.parse(localStorage.user)
    : null;

  const [isAuthenticated, setIsAuthenticated] = useState(auth)
  const [isCookied, setIsCookied] = useState(false)
  const [currentUser, setCurrentUser] = useState(user)
  const [globalPrompt, setGlobalPrompt] = useState<GlobalModalProps | null>(null)

  function refreshUser(user: any) {
    localStorage.setItem('user', JSON.stringify(user))
    console.log({ user })
    setCurrentUser(user)
  }

  function checkRestriction(key: string, data: any) {

    if (userIs('super_admin')) return true;

    const owner = currentUser?.organization?.owner
    const plan = currentUser?.organization?.plans?.length ? currentUser?.organization?.plans[0] : null

    const subscription = plan || owner?.subscriptions[0]
    
    switch (key) {
      case 'users':
        return subscription?.max_users ? subscription?.max_users > data : true
      case 'groups':
        return subscription?.max_groups ? subscription?.max_groups > data : true
      case 'services':
        return subscription?.max_services ? subscription?.max_services > data : true
      case 'booking_forms':
        return subscription?.max_booking_forms ? subscription?.max_booking_forms > data : true
      case 'calendars':
        const first = subscription?.calendars_per_user ? subscription?.calendars_per_user > data : true
        return { limit: !first, restriction: checkExtraRestriction('calendars') }
      default:
        return false
    }
  }

  function checkExtraRestriction(key: string) {
    if (userIs('super_admin')) return true;
    let checked = true
    const owner = currentUser?.organization?.owner
    const plan = currentUser?.organization?.plans?.length ? currentUser?.organization?.plans[0] : null
    console.log('checkExtraRestriction:', owner, plan, currentUser)
    const subscription = plan || owner?.subscriptions[0]
    if (subscription.restrictions?.length) {
      subscription.restrictions?.forEach((rest: any) => {
        if (rest.label.includes(key)) {
          checked = key === 'calendars' ? rest : rest.checked
          return false
        }
      })
    }
    return checked
  }

  function userIs(type: string) {
    return currentUser?.role?.slug === type
  }

  function isOwner(id?: number) {
    if (currentUser.organization) {
      if (id) {
        return currentUser.organization.owner?.id === id
      }
      return currentUser.organization.owner?.id === currentUser.id
    }
    return false
  }

  async function signIn(body: any) {
    return await post('/auth/signin', body)
      .then((res: any) => {
        const user = {...res.user, organization: res.user.organizations[0]}
        localStorage.setItem('user', JSON.stringify(user))
        setCurrentUser(user)

        if (!!res.user?.is_verified) {
          // TODO: have the expiry
          localStorage.setItem('token',JSON.stringify(res.token));
          setIsAuthenticated(true);
        }

        const favicons: any = getFaviconEl();
        for (const element of favicons) {
          element.href = user?.organization?.browser_icon?.url || '/icons/favicon.ico'
        }
        const tabtitle = document.getElementById("tabtitle");
        if(tabtitle) {
          tabtitle.innerHTML = user?.organization?.browser_meta_name || 'Time Author'
        }

        return user
      })
      .catch((err: any) => {
        console.log('error:', err)
        throw err.data;
      });
  }

  async function signUp(body: any) {
    return await post('/signup', body)
      .then((res: any) => {
        if(res.exist) {
          return res
        }
        localStorage.setItem('user', JSON.stringify(res.user))
        setCurrentUser(res.user)

        return res.user
      })
      .catch((err: any) => {
        console.log('error:', err)
        throw err.data;
      });
  }

  const getFaviconEl = () => {
    return document.getElementsByClassName("favicon");
  }

  function signOut() {
    localStorage.clear()
    setIsAuthenticated(false)
    setIsCookied(true)
    
    const favicons: any = getFaviconEl();
    for (const element of favicons) {
      element.href = '/icons/favicon.ico'
    }
    const tabtitle = document.getElementById("tabtitle");
    if(tabtitle) {
      tabtitle.innerHTML = 'Time Author'
    }
  }

  return <Provider value={{
    isAuthenticated,
    setIsAuthenticated,
    isCookied,
    setIsCookied,
    currentUser,
    setCurrentUser,
    signUp,
    signIn,
    signOut,
    refreshUser,
    userIs,
    isOwner,
    checkRestriction,
    checkExtraRestriction,
    globalPrompt, 
    setGlobalPrompt,
  }}>{props.children}</Provider>;
};

const AuthProvider = Auth;
export { AuthContext, AuthProvider, AuthConsumer };
