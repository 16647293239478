export const THEMES = {
    LIGHT: "light",
    DARK: "dark"
}


export const lightTheme = {
    bgColor: '#FFFFFF',
    primaryColor: '#18B0CF',
    colorText: '#363B45',

}

export const darkTheme = {
    bgColor: '#222222',
    primaryColor: '#18B0CF',
    colorText: '#ffffff',
}

export const bookingFormDefaultTheme = {
    text: '#000000', 
    button: 'rgb(24, 176, 207)', 
    background: 'rgba(87,199,246,0.5)'
}

export type IThemeProps = typeof lightTheme | typeof darkTheme