import React from "react";

import DashboardIcon from 'src/assets/sidebar-icons/dashboard.svg';
import MyCalendarIcon from 'src/assets/sidebar-icons/my-calendar.svg';
import MyAppointmentsIcon from 'src/assets/sidebar-icons/my-appointments.svg';
import AdminManagementIcon from 'src/assets/sidebar-icons/admin-management.svg';
import OrganizationIcon from 'src/assets/sidebar-icons/organization.svg';
import UsersIcon from 'src/assets/sidebar-icons/users.svg';
import GroupsIcon from 'src/assets/sidebar-icons/groups.svg';
import ServicesIcon from 'src/assets/sidebar-icons/services.svg';
import NotificationsTemplateIcon from 'src/assets/sidebar-icons/notification-template.svg';
import BookingFormIcon from 'src/assets/sidebar-icons/booking-form.svg';
import RolesIcon from 'src/assets/sidebar-icons/roles.svg';
import HistoryLogsIcon from 'src/assets/sidebar-icons/history-logs.svg';

import SuperAdminRoutes from 'src/pages/super-admin/routes';

const OnBoarding = React.lazy(() => import('src/containers/on-boarding/OnBoarding'))
const Dashboard = React.lazy(() => import('src/containers/dashboard/Dashboard'))
const Notifications = React.lazy(() => import('src/pages/notifications'))
const Users = React.lazy(() => import('src/pages/users'))
const UsersUpdate = React.lazy(() => import('src/pages/users/UpdateUserProfile'))
const Group = React.lazy(() => import('src/pages/group'))
const Organization = React.lazy(() => import('src/pages/organization/index'));
const Services = React.lazy(() => import('src/pages/services/index'));
const Roles = React.lazy(() => import('src/pages/roles/index'));
const Appointments = React.lazy(() => import('src/pages/appointments'));
const Reschedule = React.lazy(() => import('src/pages/reschedule-page'));
const Booking = React.lazy(() => import('src/pages/booking'))
const BookingPage = React.lazy(() => import('src/pages/booking-page'));
const LinkCalendar = React.lazy(() => import("src/pages/auth/link-calendar/outlook"))
const LinkCalendarIcloud = React.lazy(() => import("src/pages/auth/link-calendar/icloud"))
const LinkCalendarExchange = React.lazy(() => import("src/pages/auth/link-calendar/exchange"))
const LinkCalendarOffice365 = React.lazy(() => import("src/pages/auth/link-calendar/office365"))
const HistoryLogs = React.lazy(() => import('src/pages/super-admin/history-logs/index'))

const Providers = React.lazy(() => import('src/pages/oauth/Providers'));

/**
// Note
// Path: timeauthor-react/src/config/privateroutes.tsx
// Sample data structure for the sidebar menu
const sample = [{
    path: '/dashboard', // path is required, not required for parent
    exact: true,   // exact path
    component: Dashboard, // component to be rendered, not required for parent
    icon: DashboardIcon, // icon for the sidebar menu
    sidebar: true, // sidebar: true means it will be considered as route for sidebar
    sidebarVisible: true, // if false, it will not show in the sidebar
    parent: false, // if true, it will be considered as parent
    id: 1, // unique id for the parent route, used to identify the child routes
    parentId: 1, // id of the parent route, required for child routes
    sidebarLabel: 'Dashboard', // sidebar label
    mainLabel: 'Dashboard', // label to be shown in the main content
}]
*/


export const privateroutes = [
    {
        index: true,
        path: '/on-boarding',
        element: <OnBoarding />
    },
    {
        exact: true,
        path: '/oauth/:provider',
        element: <Providers iModules={'calendar'} />,
        sidebar: true,
        sidebarVisible: false,
    },
    {
        path: '/',
        element: <Dashboard />,
        exact: true,
        sidebar: true,
        sidebarVisible: true,
        icon: DashboardIcon,
        sidebarLabel: 'Dashboard',
        mainLabel: 'Dashboard',
    },
    {
        path: '/my-appointments',
        element: <Appointments />,
        sidebar: true,
        sidebarVisible: true,
        icon: MyCalendarIcon,
        sidebarLabel: 'My Appointments',
        mainLabel: 'My Appointments',
    },
    {
        sidebar: true,
        sidebarVisible: true,
        parent: true,
        id: 'admin-management',
        icon: AdminManagementIcon,
        sidebarLabel: 'Admin Management',
        mainLabel: 'Admin Management',
    },
    {
        path: '/organization',
        element: <Organization />,
        sidebar: true,
        sidebarVisible: true,
        parentId: 'admin-management',
        icon: OrganizationIcon,
        sidebarLabel: 'Organization',
        mainLabel: 'Organization',
    },
    {
        path: '/users',
        element: <Users />,
        sidebar: true,
        sidebarVisible: true,
        parentId: 'admin-management',
        icon: UsersIcon,
        sidebarLabel: 'Users',
        mainLabel: 'Users',
    },
    {
        path: '/users/:id',
        exact: true,
        element: <UsersUpdate />,
        sidebar: true,
        mainLabel: 'Users',
    },
    {
        path: '/group',
        element: <Group />,
        sidebar: true,
        sidebarVisible: true,
        parentId: 'admin-management',
        icon: GroupsIcon,
        sidebarLabel: 'Groups',
        mainLabel: 'Groups',
    },
    {
        path: '/services',
        element: <Services />,
        sidebar: true,
        sidebarVisible: true,
        parentId: 'admin-management',
        icon: ServicesIcon,
        sidebarLabel: 'Services',
        mainLabel: 'Services',
    },
    {
        path: '/services/:id/:edit',
        exact: true,
        element: <Services />,
        sidebar: true,
        mainLabel: 'Services',
    },
    {
        path: '/notifications',
        element: <Notifications />,
        sidebar: true,
        sidebarVisible: true,
        parentId: 'admin-management',
        icon: NotificationsTemplateIcon,
        sidebarLabel: 'Notification Templates',
        mainLabel: 'Notification Templates',
    },
    {
        path: '/booking-form',
        element: <Booking />,
        sidebar: true,
        sidebarVisible: true,
        parentId: 'admin-management',
        id: 'booking-form',
        icon: BookingFormIcon,
        sidebarLabel: 'Booking Forms',
        mainLabel: 'Booking Forms',
        children: [
            // {
            //     path: ':id',
            //     exact: true,
            //     parentId: 'booking-form',
            // },
            {
                path: ':id/page',
                exact: true,
                parentId: 'booking-form',
                element: <BookingPage />
            }
        ]
    },
    {
        path: '/booking-form/:id',
        exact: true,
        element: <Booking />,
        sidebar: true,
        mainLabel: 'Booking Forms',
    },
    {
        path: '/roles',
        element: <Roles />,
        sidebar: true,
        sidebarVisible: true,
        parentId: 'admin-management',
        icon: RolesIcon,
        sidebarLabel: 'Roles',
        mainLabel: 'Roles',
    },
    {
        path: '/appointments',
        element: <Appointments />,
        sidebar: true,
        sidebarVisible: true,
        parentId: 'admin-management',
        icon: MyAppointmentsIcon,
        sidebarLabel: 'Appointments',
        mainLabel: 'Appointments',
    },
    {
        path: '/history-logs',
        element: <HistoryLogs />,
        sidebar: true,
        sidebarVisible: true,
        parentId: 'admin-management',
        icon: HistoryLogsIcon,
        sidebarLabel: 'History Logs',
        mainLabel: 'History Logs',
    },
    {
        index: true,
        path: '/reschedule',
        element: <Reschedule />,
    },
    {
        path: '/authorize',
        exact: true,
        element: <LinkCalendar />,
    },
	 {
		  path: '/authorize-icloud',
		  exact: true,
		  element: <LinkCalendarIcloud />,
	 },
	 {
	 	 path: '/authorize-exchange',
	 	 exact: true,
	 	 element: <LinkCalendarExchange />,
	 },
     {
        path: '/authorize-office365',
        exact: true,
        element: <LinkCalendarOffice365 />,
     },

    ...SuperAdminRoutes,
    // { path: '*', element: <Roles />, sidebar: true },
];



// staging.com/[room-code]/[syncs-code]
// staging.com/[room-code]/[syncs-code]/#[location-hash]  //optional
