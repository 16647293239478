import React from 'react';
import {InputText} from "./TextInputs";

interface NumericInputProps {
    style: React.CSSProperties;
    value: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    placeholder?: string;
    readOnly?: boolean;
    wholeNumber?: boolean;
}

const InputNumber = (props: NumericInputProps) => {
    const { value, onChange } = props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        const reg = props.wholeNumber ? /^\d*?$/ : /^\d*(\.\d*)?$/;
        if(isNaN(Number(inputValue))){
            onChange(value || '');
        }
        if (reg.test(inputValue) || inputValue === '') {
            onChange(inputValue);
        }
    };

    const handleBlur = () => {
        let valueTemp = value;
        if (value.charAt(value.length - 1) === '.') {
            valueTemp = value.slice(0, -1);
        }
        onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    };

    return (
        <InputText
            {...props}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={16}
        />
    );
};

export default InputNumber;
