import { useAuth } from 'src/hooks/useAuth'
import APIService from 'src/services/APIService'



const UserService = () => {
    const { currentUser, refreshUser } = useAuth()
    const { get, put, remove } = APIService()

    const removeUser = async (uid: number) => {
        await remove('users/' + uid)
            .then((resp: any) => {
            })
    }

    const getUser = async (uid: number) => {
        let user: any
        await get('users/' + uid, [])
            .then((resp: any) => {
                user = resp
            })
        return user
    }

    const getUsers = async (page: number = 1, pageSize: number = 10, search: string = '') => {
        let user: any
        await get('users-all', {
            params : {
                page,
                per_page: pageSize,
                s: search !== '' ? search : null
            }
        })
            .then((resp: any) => {
                user = resp
            })
        return user
    }

    const updateUser = async (uid: number, data: any) => {
        await put('users/' + uid, data)
            .then((userData: any) => {
                if (uid === currentUser.id) {
                    get('organizations/my-organizations', [])
                        .then((resp: any) => {
                            const _org = resp.pop()
                            refreshUser({ ...currentUser, ...userData, organization: _org })
                        }).catch(() => {
                        refreshUser({ ...currentUser, ...userData})
                    })
                }
            })
    }

    const getGroups = async (uid: number) => {
        let groups: any[] = []
        await get('users/' + uid + '/groups', [])
            .then((resp: any) => {
                groups = resp
            })
        return groups
    }

    const getServices = async (uid: number) => {
        let services: any[] = []
        await get('users/' + uid + '/services', [])
            .then((resp: any) => {
                services = resp
            })
        return services
    }

    const getUserGroupsServices = async (uid: number) => {
        let services: any[] = []
        await get('users/' + uid + '/group-services', [])
            .then((resp: any) => {
                services = resp
            })
        return services
    }

    return {
        removeUser,
        getUser,
        getUsers,
        updateUser,
        getGroups,
        getServices,
        getUserGroupsServices
    }
}


export default UserService
