import React from 'react'
import { Checkbox as AntDCheckbox, CheckboxProps } from 'antd'
import styled from 'styled-components'

function Checkbox({ children, ...rest }: CheckboxProps) {
    return (
        <StyledCheckbox {...rest}>{children}</StyledCheckbox>
    )
}

const StyledCheckbox = styled(AntDCheckbox)`
    margin: 5px;

    &.ant-checkbox-wrapper:hover .ant-checkbox-inner, 
    .ant-checkbox-input:focus + .ant-checkbox-inner, 
    .ant-checkbox:hover .ant-checkbox-inner {
        border-color: #18B0CF;
    }

    .ant-checkbox-checked:not(.ant-checkbox-disabled) {
        &::after {
            border-color: #18B0CF;
        }
        
        .ant-checkbox-inner {
            background-color: #18B0CF;
            border-color: #18B0CF;
        }
    }
`

export default Checkbox
