import React, { ReactNode } from 'react'
import styled from 'styled-components'
import SuccessPromptIcon from '../../assets/icons/SuccessPromptIcon.svg'
import Button from './Button'
import Modal from './Modal'

function Prompt({
    visible,
    closable = false,
    width = 400,
    title = '',
    message = '',
    maskClosable = true,
    onCancel = () => {},
    success,
    customIcon,
    customIconAlt,
    customIconStyle,
    danger= false,
    okButton = '',
    okFunction = () => {},
    cancelButton = '',
    cancelFunction = () => {},
    children= <></>,
    className = ''
}: {
    visible: boolean,
    closable?: boolean,
    width?: string | number
    title?: ReactNode,
    message?: ReactNode
    maskClosable?: boolean
    onCancel?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
    success?: boolean
    customIcon?: string | undefined
    customIconAlt?: string
    customIconStyle?: object | undefined
    danger?: boolean
    okButton?: ReactNode
    okFunction?: React.MouseEventHandler<HTMLElement> | undefined
    cancelButton?: ReactNode
    cancelFunction?: React.MouseEventHandler<HTMLElement> | undefined
    children?: ReactNode,
    className?: string
}) {
  return (
    <Modal
        visible={visible}
        maskClosable={maskClosable}
        closable={closable}
        width={width}
        onCancel={onCancel}
        className={className}
    >
        <PromptWrapper>
            <ImageWrapper>
                {
                    customIcon ? <img src={customIcon} style={{...customIconStyle}} alt={customIconAlt || 'Icon'}/> : success ? <img src={SuccessPromptIcon} alt="Success"/> : ''
                }
            </ImageWrapper>
            {
                title ? <Title>{title}</Title> : ''
            }
            {
                message ? <Message>{message}</Message> : ''
            }
            {
                okButton || cancelButton ?
                <ButtonWrapper bothButtonVisible={!!(okButton && cancelButton)}>
                    {cancelButton ? <Button type={'default'} block={!!!okButton} size="large" onClick={cancelFunction} style={{ height: 50, fontWeight: '500'}} >{cancelButton}</Button> : ''}
                    {okButton ? <Button type={'primary'} block={!!!cancelButton} size="large" onClick={okFunction} danger={danger} style={{ height: 50, fontWeight: '500'}} >{okButton}</Button> : ''}
                </ButtonWrapper> : ''
            }
            {children}
        </PromptWrapper>
    </Modal>
  )
}

const PromptWrapper = styled.div`
    text-align: center;
    padding: 0 10px;
`

const ImageWrapper = styled.div`
    img {
        height: 100px;
    }
`

const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    margin: 10px 0;
`

const Message = styled.div``

const ButtonWrapper = styled.div<{bothButtonVisible?: boolean}>`
    margin: 20px 0 0;

    ${({ bothButtonVisible }) => bothButtonVisible ? `
        button {
            width: calc(50% - 10px);
        }
    ` : `
        button {
            width: 90%;
        }
    `}
`

export default Prompt
